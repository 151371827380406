import React, { useContext, useState, useEffect } from 'react'
import Prelayout from '../../components/Prelayout'
import LoaderButton from '../../components/LoaderButton'
import { graphql, withApollo, compose } from 'react-apollo'
import { Wrapper, Header, FormWrapper, FlexCol, ContainerButton } from './style'
import logo from '../../assets/header/nova-logo-blanco.svg'
import bg from '../../assets/home/faded_bg.jpg'
import bgMobile from '../../assets/home/faded_bg_mobile.jpg'
import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
import { Button } from '../../components/Buttons'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import { RESET_MUTATION } from './query'
//import { LOGIN_MUTATION } from '../Prehome/query'
import queryString from 'query-string'
//import AccountData from '../Prehome/data'

function ResetPage({ mutate, history, match }: any) {
  const { secondary, btnPrimary } = useContext(ThemeContext)
  const { forceShowNotification } = useContext(CartContext)
  const token = queryString.parse(history.location.search).remember_token
    ? queryString.parse(history.location.search).remember_token
    : ''
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCopy, setPasswordCopy] = useState('')
  const [error, setError] = useState({
    email: false,
    password: false,
    passwordCopy: false
  })
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const validate = () => {
    if (
      !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw]{2,4})(\]?)$/.test(
        email
      )
    ) {
      setError((prevState: any) => ({
        ...prevState,
        email: true
      }))
      return { error: true, message: 'Ingresa el correo de tu cuenta' }
    }
    if (password.length < 8) {
      setError((prevState: any) => ({
        ...prevState,
        password: true
      }))
      return { error: true, message: 'Debe contener al menos 8 caracteres' }
    }
    if (passwordCopy !== password) {
      setError((prevState: any) => ({
        ...prevState,
        passwordCopy: true
      }))
      return { error: true, message: 'Las contraseñas no coinciden' }
    }

    return { error: false, message: '' }
  }

  const handleClic = async () => {
    const validation = validate()
    if (validation.error) {
      setMessage(validation.message)
    } else {
      setLoading(true)
      mutate({
        mutation: RESET_MUTATION,
        variables: {
          input: {
            email: email,
            password: password,
            token: token
          }
        }
      })
        .then((resetResult: any) => {
          if (
            resetResult &&
            resetResult.data &&
            resetResult.data.reset
          ) {
            if (forceShowNotification) {
              let result = forceShowNotification({
                type: 'info',
                message: 'Contraseña actualizada.'
              })
              if (result) {
                setTimeout(() => {
                  history.push('/')
                }, 1000)
              }
            }
          }
        })
        .catch(() => {
          setLoading(false)
          if (forceShowNotification) {
            forceShowNotification({
              type: 'fail',
              message: 'Algo salió mal, inténtalo más tarde'
            })
          }
        })
    }
  }
  const handleChange = (e: any) => {
    const { id, value } = e.target
    switch (id) {
      case 'email':
        setEmail(value)
        setError((prevState: any) => ({
          ...prevState,
          email: false
        }))
        break
      case 'password':
        setPassword(value)
        setError((prevState: any) => ({
          ...prevState,
          password: false
        }))
        break
      case 'passwordCopy':
        setPasswordCopy(value)
        setError((prevState: any) => ({
          ...prevState,
          passwordCopy: false
        }))
        break

      default:
        break
    }
  }

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleClic()
    }
  }
  useEffect(() => {
    if (
      !queryString.parse(history.location.search).remember_token ||
      queryString.parse(history.location.search).remember_token === ''
    ) {
      history.push('/')
    }
  }, [])
  return (
    <Prelayout hiddenToast={true}>
      <Wrapper>
        <Header>
          <img src={bg} className="background desktop" />
          <img src={bgMobile} className="background mobile" />
          <img src={logo} className="logo" />
        </Header>
        <FormWrapper>
          <h1>¿Olvidaste tu contraseña?</h1>
          <p>Ingrese sus datos para restablecer su contraseña.</p>
          <FlexCol>
            <Input
              id="email"
              label="Correo electrónico"
              type="email"
              required
              value={email}
              onChange={(e) => handleChange(e)}
            />
            {error.email ? <Error>{message}</Error> : <Spacer />}
          </FlexCol>
          <FlexCol>
            <Input
              id="password"
              label="Nueva contraseña"
              type="password"
              required
              value={password}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e: any) => {
                handleEnter(e)
              }}
            />
            {error.password ? <Error>{message}</Error> : <Spacer />}
          </FlexCol>
          <FlexCol>
            <Input
              id="passwordCopy"
              label="Confirmar contraseña"
              type="password"
              required
              value={passwordCopy}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e: any) => {
                handleEnter(e)
              }}
            />
            {error.passwordCopy ? <Error>{message}</Error> : <Spacer />}
          </FlexCol>

          <ContainerButton>
            <Button
              backgroundColor={
                loading ? secondary : btnPrimary ? btnPrimary : '#000000'
              }
              color={'#FFFFFF'}
              padding={loading ? '0.1rem 0.5rem' : '0.65rem 0.5rem 0.65rem'}
              onClick={handleClic}
              disabled={loading}
            >
              {loading ? <LoaderButton /> : 'Aceptar'}
            </Button>
          </ContainerButton>
        </FormWrapper>
      </Wrapper>
    </Prelayout>
  )
}

const RememberWithApollo = compose(graphql(RESET_MUTATION))(
  withApollo(ResetPage)
)

export default RememberWithApollo
