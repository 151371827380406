import React, { useContext } from 'react'
import { ApolloConsumer } from 'react-apollo'
import * as Grid from '../Grid'
import Variation from '../../model/Variation'
import CartContext from '../../context/CartContext'
import formatNumber from '../../helpers/formatNumber'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router-dom'
import {
  IconContainer,
  HeaderCard,
  Points,
  Icon,
  Title,
  CustomRow,
  CustomColumn,
  CartContainer,
  MobileHeaderCard,
  HalfColumn,
  Line
} from './style'

function Header({ history }: any) {
  /* const storageAccount = JSON.parse(
    window.localStorage.getItem('account') || '{}'
  ) */
  const { items, onSetLoaded, account } = useContext(CartContext)
  //const [account] = useState(storageAccount)
  let totalItems = 0
  items
    ? items.map((item: Variation) => {
        const { quantity } = item
        totalItems = totalItems + quantity
      })
    : (totalItems = 0)
  const redirect = totalItems == 0 ? '/empty-cart' : '/cart'
  const total = account.balances
    ? account.balances.reduce(
        (accumulator: number, item: any, index: number) => {
          return accumulator + (item.total ? item.total : 0)
        },
        0
      )
    : 0
  const logout = () => {
    window.localStorage.removeItem('token')
    history.push('/')
  }
  return (
    <ApolloConsumer>
      {(client) => (
        <Grid.ContainerFluid>
          <CustomRow>
            <HalfColumn md={4} lg={5} xl={6}>
              <Title>
                <h1>Bienvenido a nuestro Plan de Recompensas</h1>
              </Title>
              <MobileHeaderCard>
                <CartContainer
                  to={redirect}
                  onClick={() => {
                    onSetLoaded && onSetLoaded(false)
                  }}
                  className={totalItems > 0 ? 'active' : ''}
                >
                  <Icon>
                    <i className="flex-icon icon-carrito"></i>
                    <span className="mobile">{totalItems}</span>
                  </Icon>
                  <span>{totalItems}</span>
                </CartContainer>
                {/* <IconContainer
                  className={
                    account.notifications && account.notifications.length > 0
                      ? 'active'
                      : ''
                  }
                >
                  <Icon>
                    <i className="flex-icon icon-notificacion"></i>
                    <span className="mobile">6</span>
                  </Icon>
                  <span>
                    {account.notifications && account.notifications.length}
                  </span>
                </IconContainer> */}
              </MobileHeaderCard>
            </HalfColumn>
            <Line></Line>
            <HalfColumn className={'mobile'}>
              <MobileHeaderCard>
                <Points>
                  <h2>{formatNumber(total)}</h2>
                  <p>
                    Puntos <br /> acumulados
                  </p>
                </Points>
              </MobileHeaderCard>
              <MobileHeaderCard onClick={() => history.push('/profile')}>
                <IconContainer>
                  {account.files && account.files.length > 0 ? (
                    <Icon className="blue">
                      <img src={account.files[0].url}></img>
                    </Icon>
                  ) : (
                    <Icon className="blue">{`${account.firstName.substring(
                      0,
                      1
                    )}${account.lastName.substring(0, 1)}`}</Icon>
                  )}
                </IconContainer>
                <span className="name">Hola {account.firstName}</span>
              </MobileHeaderCard>
            </HalfColumn>
            <Grid.Column md={8} lg={7} xl={6}>
              <HeaderCard>
                <CustomRow>
                  <Grid.Column sm={4}>
                    <Points>
                      <h2>{formatNumber(total)}</h2>
                      <p>
                        Puntos <br /> acumulados
                      </p>
                    </Points>
                  </Grid.Column>
                  <CustomColumn sm={4}>
                    <div
                      data-for="carrito-tip"
                      data-tip="Carrito de compras"
                      data-iscapture="true"
                    >
                      <CartContainer
                        to={redirect}
                        onClick={() => {
                          onSetLoaded && onSetLoaded(false)
                        }}
                        className={totalItems > 0 ? 'active' : ''}
                      >
                        <Icon>
                          <i className="flex-icon icon-carrito"></i>
                        </Icon>
                        <span>{totalItems}</span>
                      </CartContainer>
                    </div>
                  </CustomColumn>
                  {/*                   <CustomColumn sm={3}>
                    <a
                      data-for="notification-tip"
                      data-tip="Notificaciones"
                      data-iscapture="true"
                    >
                      <IconContainer
                        className={
                          account.notifications &&
                          account.notifications.length > 0
                            ? 'active'
                            : ''
                        }
                      >
                        <Icon>
                          <i className="flex-icon icon-notificacion"></i>
                        </Icon>
                        <span>
                          {account.notifications &&
                            account.notifications.length}
                        </span>
                      </IconContainer>
                    </a>
                  </CustomColumn> */}
                  <CustomColumn sm={4}>
                    <div
                      data-for="notification-tip"
                      data-tip
                      data-iscapture="false"
                    >
                      <IconContainer>
                        {account.files && account.files.length > 0 ? (
                          <Icon className="blue">
                            <img src={account.files[0].url}></img>
                          </Icon>
                        ) : (
                          <Icon className="blue">{`${account.firstName.substring(
                            0,
                            1
                          )}${account.lastName.substring(0, 1)}`}</Icon>
                        )}
                        <span className="name">Hola {account.firstName}</span>
                      </IconContainer>
                    </div>
                  </CustomColumn>
                </CustomRow>
                <ReactTooltip
                  id="carrito-tip"
                  place={'bottom'}
                  type={'warning'}
                  effect={'solid'}
                  multiline={true}
                />
                <ReactTooltip
                  id="notification-tip"
                  place={'bottom'}
                  event={'click'}
                  type={'info'}
                  effect={'solid'}
                  multiline={true}
                  clickable={true}
                >
                  <div onClick={() => logout()} className="logout">
                    Cerrar sesión
                  </div>
                </ReactTooltip>
              </HeaderCard>
            </Grid.Column>
          </CustomRow>
        </Grid.ContainerFluid>
      )}
    </ApolloConsumer>
  )
}

export default withRouter(Header)
