import styled from 'styled-components'

interface InputProps {
  backgroundColor?: string
  error?: boolean
  borderColor?: string
}

export const StyledInput = styled.input`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 100%;
  height: 40px;
  margin-bottom: 4px;
  padding: 10px 13px 11px 13px;
  outline: none;

  ${({ backgroundColor, borderColor, error }: InputProps) => {
    return ` 
      background-color: ${backgroundColor ? backgroundColor : '#ffffff'} ;
      border: solid 1px ${error ? 'red' : '#e9eff4'};
      &:focus{
        border: solid 1px  ${error ? 'red' : borderColor};
      }
    `
  }}
`

export const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  width: 100%;
  padding: 12px 13px 11px 13px;
  outline: none;
  ${({ backgroundColor, borderColor, error }: InputProps) => {
    return ` 
    background-color: ${backgroundColor ? backgroundColor : '#ffffff'} ;
    border: solid 1px ${error ? 'red' : '#d9d9d9'};
    &:focus{
      border: solid 1px ${error ? 'red' : borderColor};
      box-shadow: none;
    }
  `
  }}
`

export const StyledError = styled.small`
  font-size: 12px;
  color: #ff2d2d;
  height: 17px;
  display: block;
  margin: 3px 0 0 3px;
`
