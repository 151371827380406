import styled from 'styled-components'
import sizes from '../../styles/media'
import { Container, Row } from '../../components/Grid'

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`

export const ContainerForm = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  @media ${sizes.md} {
    padding: 2rem;
  }

  @media ${sizes.xl} {
    padding: 2rem 12rem;
  }
  margin-bottom: 2rem;
  h2 {
    margin-bottom: 5px;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  label {
    color: #0066cc;
  }
  &.hidden-input {
    display: none;
  }
`

export const Hr = styled.hr`
  border: none;
  border-bottom: solid 1px #dadada;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  line-height: 2;
`
export const ContainerButton = styled.div`
  margin-top: 1rem;
`

export const StyledRow = styled(Row)`
  flex-direction: row-reverse;
`
export const TermsContainer = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  color: #0066cc;
  label {
    margin-left: 10px;
  }
  a {
    color: #0066cc;
    text-decoration: none;
  }
`

export const WrapContaninerChecks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonAccept = styled.button`
  transition: all .5s ease-in-out 0s;
  border: none;
  border-radius: 0 0 0 14px;
  display: inline-block;
  background: #CDCDCD;
  color: #FFFFFF;
  width: 100%;
  padding: 19px 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: not-allowed;

  @media (min-width: 375px) {
    width: 295px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const Checkboxs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  text-align: start;
  margin: 0 auto;
  margin-bottom: 21px;
`

export const CheckboxsGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  span {
    color: #0F265C;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
  }

  @media (min-width: 480px) {
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`
