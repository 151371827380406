import styled from 'styled-components'
import Modal from 'react-modal'
import sizes from '../../styles/media'
import { ContainerFluid, Column } from '../../components/Grid'
import Select from 'react-select'

interface ModalProps {
  backgroundcolor?: string
  padding: string
}

export const ModalWrapper = styled(Modal)`
  position: relative;
  top: 32%;
  left: 50%;
  right: auto;
  bottom: auto;
  border-radius: 10px;
  background: #f4f8f9;
  outline: none;
  max-width: 550px;
  width: 100%;
  text-align: left;
  z-index: 100;
  height: 60vh;
  transform: translate(-50%, -50%);

  @media ${sizes.sm} {
    height: auto;
    top: 50%;
  }
  &.short {
    max-width: 410px;
  }
`

export const CartButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  background-color: #0066cc;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  i {
    font-size: 2rem;
  }
  margin-top: 10px;

  @media ${sizes.md} {
    margin-top: 0;
  }
`

export const ModalDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;
  border-radius: 10px;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0;
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: #ffffff;
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }
`

export const FeatureErrors = styled.span`
  color: red;
  font-size: 11px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.md} {
    margin-top: 0;
    width: 100%;
  }
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: left;
  h1 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #014178;
    margin-top: 0;
  }
`

export const TimesBtn = styled.div`
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c5c5c5;
  &:hover {
    cursor: pointer;
  }
`

export const StyledContainerFluid = styled(ContainerFluid)`
  background-color: white;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  padding: 1rem 1.5rem;
  @media ${sizes.sm} {
    padding: 2rem;
  }
  @media ${sizes.md} {
    padding: 3rem;
  }
`
export const StyledColumnCustom = styled(Column)`
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
`

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const ItemContainer = styled.div`
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  i {
    margin-right: 4px;
  }
  @media ${sizes.md} {
    padding: 0 10px 0 0;
    text-align: left;
  }
`
export const ProductDataWrapper = styled.div`
  margin-top: 30px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .supplier-logo {
    width: auto;
    height: 70px;
    border: solid #cacaca 1px;
    margin-left: 15px;
  }

  @media ${sizes.md} {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: flex-start;
    .supplier-logo {
      width: 95px;
      height: auto;
    }
  }
`

export const Name = styled.h2`
  font-family: 'Prelo';
  font-size: 1.2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;

  @media ${sizes.md} {
    font-size: 1.3rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media ${sizes.md} {
    flex-direction: row;
  }
`
export const StyledSelect = styled(Select)`
  width: 100%;
`
export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
    backgroundColor: state.isSelected ? 'rgba(0, 122, 255, 0.2)' : '#ffffff',
    padding: '10px 20px',
    fontSize: '12px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 122, 255, 0.2)'
    },
    img: {
      width: '18px',
      marginRight: '0.5rem'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    height: '40px',
    'min-height': '40px',
    border: 'solid 1px #e9eff4'
    // border: "solid 1px #d9d9d9"
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '0 10px 12px 0 rgba(0, 0, 0, 0.15)'
  }),
  singleValue: (provided: any, state: any) => {
    const color = '#000000'
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition, color }
  }
  //dropdownIndicator: () => ({}),
  //indicatorSeparator: () => ({})
}

export const FeaturesWrapper = styled.div`
  display: none;
  @media ${sizes.md} {
    display: block;
  }
  width: 100%;
  p {
    color: #0066cc;
    font-size: 11px;
    margin: 0.6rem 0 0.1rem;
  }
`

export const MobileFeaturesWrapper = styled.div`
  display: inline-block;

  @media ${sizes.md} {
    display: none;
  }
  width: 100%;
  p {
    color: #0066cc;
    font-size: 11px;
    margin: 0.6rem 0 0.1rem;
  }
`
