import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import Icon from '../Icon'

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #f4f8f9;
  border: 1px solid;
  border-color: #f4f8f9;
  margin: 1rem 5%;
  width: 90%;
  flex: 1;
  @media ${sizes.sm} {
    margin: 1rem 0;
    width: 100%;
    justify-content: flex-start;
  }
  &:hover {
    box-shadow: 4px 5px 14px 0 #e1e1e1;
    cursor: pointer;
  }
  .content {
    padding: 1.2rem 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${sizes.lg} {
      height: 100%;
    }
  }
`

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 0;
  border-radius: 10px 10px 0 0;
  @media ${sizes.md} {
    height: 180px;
    max-height: 180px;
    min-height: 180px;
  }
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const ProductInfoContainer = styled.div`
  display: block;
  margin-bottom: 0.7rem;
  max-height: 8rem;
  overflow: hidden;
`

export const ProductPriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CartButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  min-width: 58px;
  min-height: 58px;
  max-width: 58px;
  max-height: 58px;
  background-color: #0066cc;
  border-radius: 50%;
  color: white;
  i {
    font-size: 2rem;
  }
`

interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const ButtonContainer = styled.div`
  display: none;
  @media ${sizes.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    letter-spacing: 1px;
  }
`
export const ButtonCartContainer = styled.div`
  @media ${sizes.sm} {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .add-cart-btn {
    padding: 0;

    &:hover {
      background: #112340;
    }
  }
`

export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding: 0 15px;
      font-weight: bold;
      `
  }};
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      color: ${props.fontcolor};
      border: solid 1px ${props.backgroundcolor};
      outline: none;
      cursor: pointer;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
      height: 100%;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 100%;

  @media ${sizes.sm} {
    width: 50%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.sm} {
    flex-direction: row;
  }
`

export const AddCartButtonContainer = styled.div`
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  @media ${sizes.sm} {
    display: flex;
    margin: unset;
    width: 50%;
  }
`

export const StyledIcon = styled(Icon)`
  position: relative;
  right: 0;
`

export const PriceInfo = styled.p`
  margin: 0 0 10px 0;
  color: #808083;
  font-size: 0.7rem;
  @media only screen and (max-width: 320px) {
    font-size: 0.5rem;
  }
  @media ${sizes.sm} {
    font-size: 0.8rem;
  }
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
`

export const ItemContainer = styled.div`
  margin-bottom: 0.5rem;
  i {
    margin-right: 4px;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;

  @media ${sizes.sm} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: space-between;
  }
`

export const Price = styled.p`
  font-size: 14px;
  margin: 0;
  color: #4e8ee5;
  line-height: 1.2;
  @media only screen and (max-width: 320px) {
    font-size: 11px;
  }
  @media ${sizes.md} {
    font-size: 18px;
  }
`

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const CustomTitle = styled.p`
  font-weight: normal;
  word-break: break-word;
  margin: 0;
  color: #0066cc;
`

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`

export const RegularPrice = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;
  span {
    color: #0066cc;
    font-size: 14px;
  }
`

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  width: 100%;
  height: 100%;
`

export const LinkResponsive = styled(Link)`
  color: black;
`

export const PriceContainer = styled.div`
  display: flex-end;
`

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
`
