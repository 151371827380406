import React from 'react'
import Layout from '../Layout'

type Props = {
  children: React.ReactNode
  showRightSection?: boolean
  placeToPaySection?: boolean
}

function Checkout({ children }: Props) {
  return <Layout currentPage={'catalog'}>{children}</Layout>
}

export default Checkout
