import gql from 'graphql-tag'

const RESET_MUTATION = gql`
  mutation Reset($input: ResetInput!) {
    reset(input: $input) {
      email
      password
    }
  }
`
export { RESET_MUTATION }
