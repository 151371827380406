import React, { useState } from 'react'
import { components } from 'react-select'
import Loader from '../../components/loader'
import { Query, QueryResult } from 'react-apollo'
import { GET_CATEGORIES_QUERY } from './query'
import Category from '../../model/Category'
import { Input } from '../../components/FormElements'
import Checkbox from 'react-checkbox-component'

import {
  Wrapper,
  StyledSelect,
  customStyles,
  StyledCheckbox,
  StyledColumn,
  StyledFinder
} from './style'

interface Filter {
  s: String
}

interface Options {
  limit: Number
  sortBy: String
}
interface DataProducts {
  categories: {
    nodes: Category[]
  }
}

type OptionProps = {
  value: string
  label: string
  icon: string
}
type SortOptionProps = {
  value: string
  label: string
}

interface Variables {
  filters: Filter
  options: Options
}
type Props = {
  handleCategories: (props: OptionProps) => void
  handleSort: (props: SortOptionProps) => void
  selectedCategory: OptionProps
  selectedSort: SortOptionProps
  search: string
  setSearch: any
  setPage: any
  checked: boolean
  setCheck: any
}

function Filter({
  handleCategories,
  handleSort,
  selectedCategory,
  selectedSort,
  search,
  setSearch,
  setPage,
  checked,
  setCheck
}: Props) {
  const categoriesVariables = {
    filters: { s: '' },
    options: {
      limit: 100,
      sortBy: 'priority'
    }
  }
  const [searching, setSearching] = useState(search)
  //const [checked, setChecked] = useState(false)
  return (
    <Query<DataProducts, Variables>
      query={GET_CATEGORIES_QUERY}
      variables={categoriesVariables}
    >
      {({ loading, error, data }: QueryResult<DataProducts, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p>Error</p>
        }

        if (!data) {
          return <p>No Data</p>
        }
        const categories = data.categories.nodes

        const categoriesOption: any = []
        categoriesOption.push({
          label: 'Todo',
          value: '',
          icon: ''
        })

        //sort options
        const sortOption = [
          { label: 'Ordenar', value: 'priority' },
          { label: 'De mayor a menor', value: '-min_point' },
          { label: 'De menor a mayor', value: 'min_point' }
        ]

        categories.map((category: any) => {
          categoriesOption.push({
            label: category.name,
            value: category.id,
            icon: ''
          })
        })
        const { Option } = components
        const IconOption = (props: any) => (
          <Option {...props}>
            {props.data.icon && (
              <img src={props.data.icon} alt={props.data.label} />
            )}
            {props.data.label}
          </Option>
        )
        const handleEnter = (e: any) => {
          if (searching.length === 0) {
            setSearch(searching)
            setPage({ limit: 8, offset: 0 })
          }
          if (e.key === 'Enter') {
            setSearch(searching)
            setPage({ limit: 8, offset: 0 })
          }
        }
        return (
          <Wrapper>
            <StyledColumn lg={3}>
              <StyledCheckbox>
                <Checkbox
                  size="big"
                  isChecked={checked}
                  onChange={() => setCheck(!checked)}
                  color={checked ? '#0066cc' : '#e9eff4'}
                />
                <p>Mostrar productos disponibles con mis puntos</p>
              </StyledCheckbox>
            </StyledColumn>
            <StyledColumn lg={3}>
              <StyledFinder>
                <Input
                  onChange={(e: any) => {
                    setSearching(e.target.value)
                  }}
                  /* onBlur={(e: any) => {
                    setSearch(searching)
                  }} */
                  onKeyUp={(e: any) => {
                    handleEnter(e)
                  }}
                  id="finder"
                  type="text"
                  label="Buscador"
                  placeholder="Buscar"
                  value={searching}
                />
              </StyledFinder>
            </StyledColumn>
            <StyledColumn lg={3}>
              <StyledSelect
                styles={customStyles}
                id="categories"
                options={categoriesOption}
                components={{ Option: IconOption }}
                onChange={async (optionSelected: any) => {
                  handleCategories(optionSelected)
                }}
                noOptionsMessage={() => 'No existen categorías'}
                value={selectedCategory}
                autoFocus={false}
              />
            </StyledColumn>

            <StyledColumn lg={3}>
              <StyledSelect
                styles={customStyles}
                id="sort"
                options={sortOption}
                onChange={async (optionSelected: any) => {
                  handleSort(optionSelected)
                }}
                value={selectedSort}
                autoFocus={false}
              />
            </StyledColumn>
          </Wrapper>
        )
      }}
    </Query>
  )
}

export default Filter
