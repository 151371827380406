import gql from 'graphql-tag'

const GET_TOKEN_BY_ID = gql`
  query TokenById($identification: String!) {
    tokenById(identification: $identification) {
      token
      registered
    }
  }
`

const SEND_PQR_MUTATION = gql`
  mutation sendPqrs($input: PqrsInput) {
    sendPqrs(input: $input) {
      status {
        name
      }
    }
  }
`

export { GET_TOKEN_BY_ID, SEND_PQR_MUTATION }
