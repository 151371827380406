import styled from "styled-components";
import { ToastContainer as TC } from "react-toastify";
import sizes from "../../styles/media";
const className = "dark-toast";
const toastClassName = "toast-container";
const ToastIconContainerClass = "toast-icon-container";
interface Props {
  type?: string;
  color?: string;
}

export const ToastContainer = styled(TC).attrs<Props>({
  className,
  toastClassName
})`
  .${toastClassName} {
    height: 60px;
    border-radius: 0.7em;
    padding: 0px;
    font-size: 1.2em;

    &.Toastify__toast {
      min-height: 60px;
      margin: 0.5rem auto;
      width: 85%;
      @media ${sizes.md} {
        width: 100%;
      }
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 0;
`;

export const ToastContainerBox = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  text-align: center;
  max-height: 60px;
  border: 1.5px solid ${props => props.color};
  border-radius: 0.7em;
  background-color: ${props => props.color};

  .${ToastIconContainerClass} {
    background: ${props => props.color};
    color: ${props => props.color};
    height: 100%;
    width: 72px;
    margin: 0;
    padding: 0px;
  }
`;
export const ToastIconContainer = styled.div.attrs({
  className: ToastIconContainerClass
})``;

export const ToastText = styled.p`
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
  font-size: 14px;
  margin: auto;
  padding: 0px;
  width: 100%;
  color: #fff;
  @media ${sizes.xs} {
  }
  @media ${sizes.md} {
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  border-left: 1px solid #fff;
  margin: 5px;
  padding-left: 10px;
`;
