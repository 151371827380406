import styled from 'styled-components'
import bg from '../../assets/home/prehome-bg.jpg'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
`
