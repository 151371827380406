export function Validate(
  value: string,
  type?: string,
  len?: number,
  hide?: string,
  required = true
) {
  if (value.length > (len ? len : 60)) {
    return {
      error: true,
      message: 'Ha excedido el número de caracteres.',
      hide: false,
      value: value,
      fillColor: '#FFF'
    }
  }

  if (value.length == 0 && required) {
    return {
      error: true,
      message: 'Campo obligatorio, no puede estar vacío.',
      hide: false,
      value: value,
      fillColor: '#FFF'
    }
  }

  if (type == 'RUC') {
    const is_numeric = validateOnlyNumeric(value)
    if (is_numeric.error) {
      return is_numeric
    }

    if (value.length != 13) {
      return {
        error: true,
        message: 'Dígitos requeridos (13).',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
  }

  if (type == 'CI') {
    /* const is_numeric = validateOnlyNumeric(value)
    if (is_numeric.error) {
      return is_numeric
    } */

    if (value.length < 10) {
      return {
        error: true,
        message: 'Dígitos requeridos(10)',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
    if (value.length === 10) {
      if (!validateCI(value)) {
        return {
          error: true,
          message: 'Documento inválido',
          hide: false,
          value: value,
          fillColor: '#FFF'
        }
      }
    } else {
      if (!validateCI(value.substring(1))) {
        return {
          error: true,
          message: 'Documento inválido',
          hide: false,
          value: value,
          fillColor: '#FFF'
        }
      }
    }
  }

  if (type === 'email') {
    if (
      !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw]{2,4})(\]?)$/.test(
        value
      )
    ) {
      return {
        error: true,
        message: 'Email inválido',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
  }

  if (type === 'characters') {
    if (!/^[ñÑáéíóúÁÉÍa-zA-Z ]*$/g.test(value)) {
      return {
        error: true,
        message: 'Solo se permiten letras en este campo',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
  }

  if (type === 'numeric') {
    return validateOnlyNumeric(value)
  }

  if (type == 'phone') {
    const validateString = validateOnlyNumeric(value)
    if (validateString.error) return validateString

    if (value.length > 0 && value.length != 10) {
      return {
        error: true,
        message: 'Dígitos requeridos (10).',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
  }

  if (type == 'mobile') {
    const validateString = validateOnlyNumeric(value)
    if (validateString.error) return validateString

    if (value.length > 0 && value.length != 10) {
      return {
        error: true,
        message: 'Dígitos requeridos (10).',
        hide: false,
        value: value,
        fillColor: '#FFF'
      }
    }
  }
  return {
    error: false,
    message: '',
    value: value,
    hide: false,
    fillColor: '#FFF'
  }
}
function validateCI(value: string) {
  let numImpar = 0
  let numPar = 0
  let lastnumber = ''
  let cad = ''
  let digitType = value.substring(2, 3)

  lastnumber = value.substring(value.length - 1)
  cad = value.substring(0, value.length - 1)
  cad
    .toString()
    .split('')
    .map((i: any, index) =>
      index % 2
        ? (numPar += parseInt(i))
        : i * 2 < 9
        ? (numImpar += parseInt(i) * 2)
        : (numImpar += parseInt(i) * 2 - 9)
    )
  let sum = numPar + numImpar
  let residue = sum % 10
  if (
    parseInt(digitType) >= 6 ||
    (residue !== parseInt(lastnumber) && 10 - residue !== parseInt(lastnumber))
  ) {
    return false
  } else {
    return true
  }
}
function validateOnlyNumeric(value: string) {
  if (/[^0-9]/g.test(value)) {
    return {
      error: true,
      message: 'Solo se permiten números en este campo',
      hide: false,
      value: value,
      fillColor: '#FFF'
    }
  }

  return {
    error: false,
    message: '',
    value: value,
    hide: false,
    fillColor: '#FFF'
  }
}
