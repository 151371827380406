import React, { useContext, useEffect } from 'react'
import ThemeContext from '../../../../context/ThemeContext'
import CartContext from '../../../../context/CartContext'

import {
  FlexRow,
  SummaryContainer,
  ProductContainer,
  ImageContainer,
  Img,
  DescriptionContainer,
  ProductName,
  DetailPayment,
  SpanPayment,
  TotalContainer
} from './style'
import formatNumber from '../../../../helpers/formatNumber'
import noImage from '../../../../assets/cart/noImage84x87.png'

function ProductSummary() {
  const { primary, secondary, third } = useContext(ThemeContext)
  const {
    items,
    loaded,
    onSetLoaded,
    updateItems,
    forceShowNotification,
    onRemoveFromCart
  } = useContext(CartContext)

  useEffect(() => {
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })

  let iva: number = 0,
    subtotal: number = 0,
    total: number = 0
  const calculatePayment = () => {
    items.forEach((item: any) => {
      total += item.price * item.quantity
    })

    subtotal = total / 1.12
    iva = total - subtotal
  }
  calculatePayment()

  return loaded ? (
    <>
      {loaded ? (
        <>
          <SummaryContainer backgroundcolor={secondary}>
            {items.map((item: any, index: number) => {
              if (item.quantity > 0) {
                return (
                  <ProductContainer key={item.id}>
                    <ImageContainer>
                      <Img
                        src={
                          item.assets && item.assets.length > 0
                            ? item.assets[0].url
                            : item.product && item.product.assets
                            ? item.product.assets[0].url
                            : noImage
                        }
                      />
                    </ImageContainer>
                    <DescriptionContainer>
                      <ProductName>
                        <span>{item.product ? item.product.name : ''}</span>
                      </ProductName>
                      <span>
                        <b>Cant: </b>
                        {item.quantity}
                      </span>
                      <span>
                        <b>$ {formatNumber(item.price)}</b>
                      </span>
                    </DescriptionContainer>
                  </ProductContainer>
                )
              } else {
                if (forceShowNotification && onRemoveFromCart) {
                  const result = forceShowNotification({
                    type: 'fail',
                    message: item.product.name + ' se ha quedado sin stock'
                  })
                  if (result) { onRemoveFromCart(index) }
                }
                return <div key={index} />
              }
            })}
          </SummaryContainer>
          <DetailPayment backgroundcolor={secondary}>
            <FlexRow>
              <span>SUBTOTAL:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(subtotal)}
              </SpanPayment>
            </FlexRow>
            <FlexRow>
              <span>IVA:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(iva)}
              </SpanPayment>
            </FlexRow>
          </DetailPayment>
          <TotalContainer backgroundcolor={third}>
            <SpanPayment fontcolor={primary}>TOTAL</SpanPayment>
            <SpanPayment fontcolor={primary}>
              $ {formatNumber(total)}
            </SpanPayment>
          </TotalContainer>
        </>
      ) : null}
    </>
  ) : null
}

export default ProductSummary
