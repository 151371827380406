import React, { useState } from 'react'
import { ModalWrapper, ModalDataContainer, Title, TimesBtn } from './style'
import DocumentForm from '../DocumentForm'

interface ModalContainerProps {
  modalOpen: boolean
  closeModal?: any
  handleShowForm: any
  openContact?: any
}

const RegisterModal = ({
  modalOpen,
  closeModal,
  handleShowForm,
  openContact
}: ModalContainerProps) => {
  const [subtitle, setSubtitle] = useState(true)
  const handleClic = () => {
    setSubtitle(false)
  }
  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={modalOpen}
      onRequestClose={() => closeModal && closeModal(false)}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#ffffff'}
          className={'header'}
        >
          <Title>
            <h1>Proceso de registro</h1>
            {subtitle && (
              <p>
                Te invitamos a ser parte de Puntos y Premios. Si no te has
                registrado aún ingresa tu número de cédula.
              </p>
            )}
          </Title>
          <TimesBtn onClick={() => closeModal && closeModal(false)}>
            <i className="icon-cerrar" />
          </TimesBtn>
        </ModalDataContainer>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#f4f8f9'}
        >
          <DocumentForm
            handleShowForm={handleShowForm}
            handleClic={handleClic}
            closeModal={closeModal}
            openContact={openContact}
          />
        </ModalDataContainer>
      </>
    </ModalWrapper>
  )
}

export default RegisterModal
