import styled from 'styled-components'
import Carousel from 'react-elastic-carousel'
import sizes from '../../styles/media'
import Slider from 'react-slick'

type ArrowContainerProps = {
  onClick: any
  position: string
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
  position: absolute;
  z-index: 2;
  top: 50%;
  ${(props) => props.position}: 0.5rem;
  cursor: pointer;
  @media ${sizes.md} {
    display: none;
  }
`

export const ImgLink = styled.a`
  cursor: pointer;
  display: none;
  @media ${sizes.md} {
    display: block;
  }
`

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  margin-bottom: 1rem;
  display: none;
  li {
    flex: auto;
    margin-right: 1rem;
    border: solid 1px #d9d9d9;
    max-width: 20%;
    &:last-child {
      margin: 0;
    }
  }
`

export const StyledPaginationImg = styled.img`
  max-width: 100%;
`

export const StyledCarousel = styled(Carousel)`
  .rec-carousel {
    background: #dce0e6;
    border: solid 1px #d9d9d9;
    position: relative;

    .rec-slider {
      position: relative;
    }

    .rec-item-wrapper {
      align-items: center;
    }
  }
`

export const SliderContainer = styled.div`
  background: rgb(220, 224, 230);
  max-width: 100%;
  display: flex;
  flex: 1;
  margin: 0;
  margin-bottom: 30px;
  padding: 15px;

  @media ${sizes.sm} {
    margin-bottom: unset;
    padding: unset;
  }

  .img-product {
    margin: auto;
    max-height: 300px;
    width: 100%;

    @media ${sizes.sm} {
      /* margin: 6rem auto; */
      max-height: unset;
    }
  }

  & img {
    margin: auto;
    border: solid 1px #d9d9d9;
    box-shadow: 0px 0px 10px #d9d9d9;
    max-width: 300px;
    @media ${sizes.lg} {
      /* margin: 6rem auto; */
      max-width: unset;
    }
  }
`

export const StyledSlickCarousel = styled(Slider)`
  .slider-image {
    width: 100%;
    margin: auto;
    max-width: 500px;
    border: none;
    border-radius: 10px;
  }

  .thumb-image {
    border: solid #979797 1px;
    width: 100%;
    margin: auto;
    max-height: 100px;
    filter: grayscale(100%);
  }

  .slick-thumb li.slick-active img {
    filter: grayscale(0);
  }

  .slick-dots {
    position: relative;
    bottom: -20px;
  }

  .slick-dots li {
    width: 20%;
    margin: 0 7px;
    height: unset;
  }

  .slick-dots li:first-child {
    margin-left: 0;
  }

  .slick-dots li:last-child {
    margin-right: 0;
  }

  @media ${sizes.md} {
    .slick-dots li {
      margin: 0 12px;
      width: 18%;
    }
  }

  @media ${sizes.lg} {
    .slick-dots li {
      width: 20%;
    }
  }

  @media ${sizes.xl} {
    .slick-dots li {
      width: 21%;
    }
  }
`
