import React from 'react'

import { LoaderContainer, Gif } from './style'
import loaderGif from '../../assets/loader/loading.gif'

function LoaderButton() {
  return (
    <LoaderContainer>
      <Gif src={loaderGif} />
    </LoaderContainer>
  )
}

export default LoaderButton
