import React from 'react'
import Toast from '../../components/Toast'
import { Wrapper } from './style'

type Props = {
  children: React.ReactNode
  backgroundColor?: string
  isFixed?: boolean
  showBanner?: boolean
  showCart?: boolean
  loginOpen?: boolean
  registerOpen?: boolean
  contactOpen?: boolean
  hiddenToast?: boolean
}

function Prelayout({
  children,
  backgroundColor,
  isFixed,
  showBanner,
  showCart = false,
  loginOpen = false,
  registerOpen = false,
  contactOpen = false,
  hiddenToast = false
}: Props) {
  /* var token = window.localStorage.getItem('token') || ''
  if (!token) {
    token =
      Math.random().toString(36).substring(2, 15).toUpperCase() +
      Math.round(+new Date() / 1000)
    window.localStorage.setItem('token', token)
  } */

  return (
    <Wrapper>
      <Toast
        loginCollapsed={loginOpen}
        registerModal={registerOpen}
        contactModal={contactOpen}
        hiddenToast={hiddenToast}
      />
      {children}
    </Wrapper>
  )
}

export default Prelayout
