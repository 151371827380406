import React, { useContext } from 'react'
import { DateDeliveyContainer, DateInfo, DateText } from './style'
import CartContext from '../../../../context/CartContext'

function DateDelivery() {
  const { deliverInfo } = useContext(CartContext)
  const deliverInfoLS = window.localStorage.getItem('deliverInfo')

  let message = deliverInfo !== '' ? deliverInfo : deliverInfoLS
  message = '<b>Tiempo de entrega:</b> ' + message
  return (
    <DateDeliveyContainer>
      <DateInfo>
        <DateText
          dangerouslySetInnerHTML={{
            __html: message
          }}></DateText>
      </DateInfo>
    </DateDeliveyContainer>
  )
}

export default DateDelivery
