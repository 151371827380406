import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select'
import { ValueType } from 'react-select/lib/types'
import gql from 'graphql-tag'
import {
  Query,
  QueryResult,
  graphql,
  ApolloConsumer,
  MutateProps,
  compose,
  withApollo
} from 'react-apollo'
import { Row, Column } from '../../components/Grid'

import { Validate } from '../../helpers/inputValidations'
import { Input, Error, Textarea } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
import { Button } from '../../components/Buttons'
import Loader from '../../components/loader'

import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'

import {
  FlexCol,
  TextAreaElements,
  Element,
  CountContainer,
  ContainerButton,
  StyledRow
} from './style'

import {
  customStyle,
  errorStyle,
  identificationTypeOptions
} from '../../helpers/selectProps'

const SEND_PQR_MUTATION = gql`
  mutation sendPqrs($input: PqrsInput) {
    sendPqrs(input: $input) {
      status {
        name
      }
    }
  }
`
interface IProps extends MutateProps<any> {
  closeModal?: any
}
interface IOption {
  value: string
  label: string
}
function ContactForm({ mutate, closeModal }: IProps) {
  const { forceShowNotification } = useContext(CartContext)
  const { primary, secondary } = useContext(ThemeContext)

  const [types, setTypes] = useState([])
  const [subtypes, setSubtypes] = useState([] as any[])

  const [firstNameState, setFirstName] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const [lastNameState, setLastName] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const [businessNameState, setBusinessName] = useState({
    error: false,
    message: '',
    hide: true,
    value: ''
  })

  const [identificationState, setIdentificationState] = useState({
    disable: true,
    length: 0,
    type: '',
    value: '',
    style: customStyle,
    error: false
  })

  const [idNumberState, setIdNumber] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const [emailState, setEmail] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const [subjectState, setSubjectState] = useState({
    label: '',
    value: '',
    style: customStyle,
    error: false
  })

  const [subSubjectState, setSubSubjectState] = useState({
    label: '',
    value: '',
    style: customStyle,
    error: false
  })

  const [phoneState, setPhone] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const [descriptionState, setDescription] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const filters = { s: '' }
  const options = { limit: 100 }

  const GET_PQRSTYPES = gql`
    query pqrsType($filters: PqrsTypeFilters, $options: PqrsTypeOptions) {
      pqrsTypes(filters: $filters, options: $options) {
        pqrs_types {
          id
          label
          subTypes {
            id
            label
          }
        }
      }
    }
  `

  // Validate input data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value)
    switch (id) {
      case 'identificationType':
      case 'subjectState':
        dataValidated = Validate(value)
        break
      case 'subSubject':
        dataValidated = Validate(value)
        break
      case 'identificationNumber':
        dataValidated = Validate(
          value,
          identificationState.value,
          identificationState.length
        )
        setIdNumber(dataValidated)
        break
      case 'firstName':
        dataValidated = Validate(value, 'characters')
        setFirstName(dataValidated)
        break
      case 'businessName':
        dataValidated = Validate(value)
        setBusinessName(dataValidated)
        break
      case 'lastName':
        dataValidated = Validate(value, 'characters')
        setLastName(dataValidated)
        break
      case 'phone':
        dataValidated = Validate(value, 'numeric', 10)
        setPhone(dataValidated)
        break
      case 'email':
        dataValidated = Validate(value, 'email', 60)
        setEmail(dataValidated)
        break
      case 'description':
        dataValidated = Validate(value, 'description', 500)
        setDescription(dataValidated)
        break
    }
    if (dataValidated.error) return true
    else return false
  }

  // compare data from local storage and set like default option
  let selected: ValueType<{ value: string; label: string }> = {
    value: '',
    label: 'Seleccione una opción'
  }

  const getSelected: ValueType<{ value: string; label: string }> = selected
  useEffect(() => {
    if (identificationState.value != '' && idNumberState.value != '') {
      handleChange(getSelected, true)
    }
  }, [identificationState.value])

  const handleChange = (selectedOption: any, isEffect: boolean) => {
    if (isEffect && idNumberState.value != '') {
      validateData(idNumberState.value, 'identificationNumber')
    }
    switch (selectedOption.value) {
      case 'CI':
        setIdentificationState({
          disable: false,
          length: 10,
          type: 'Cédula de Identidad',
          value: 'CI',
          error: false,
          style: customStyle
        })
        setBusinessName({
          error: false,
          message: '',
          hide: true,
          value: businessNameState.value
        })
        setFirstName({
          error: false,
          message: '',
          hide: false,
          value: firstNameState.value
        })
        setLastName({
          error: false,
          message: '',
          hide: false,
          value: lastNameState.value
        })
        break
      case 'PPN':
        setIdentificationState({
          disable: false,
          length: 20,
          type: 'Pasaporte',
          value: 'PPN',
          error: false,
          style: customStyle
        })
        setBusinessName({
          error: false,
          message: '',
          hide: true,
          value: businessNameState.value
        })
        setFirstName({
          error: false,
          message: '',
          hide: false,
          value: firstNameState.value
        })
        setLastName({
          error: false,
          message: '',
          hide: false,
          value: lastNameState.value
        })
        break
      case 'RUC':
        setIdentificationState({
          disable: false,
          length: 13,
          type: 'RUC',
          value: 'RUC',
          error: false,
          style: customStyle
        })
        setBusinessName({
          error: false,
          message: '',
          hide: false,
          value: businessNameState.value
        })
        setFirstName({
          error: false,
          message: '',
          hide: true,
          value: firstNameState.value
        })
        setLastName({
          error: false,
          message: '',
          hide: true,
          value: lastNameState.value
        })
        break
    }
  }

  const clearFileds = () => {
    setIdentificationState({
      disable: true,
      length: 0,
      type: '',
      value: '',
      style: customStyle,
      error: false
    })

    setIdNumber({
      message: '',
      hide: true,
      value: '',
      error: false
    })
    setBusinessName({
      message: '',
      hide: true,
      value: '',
      error: false
    })
    setFirstName({
      message: '',
      hide: false,
      value: '',
      error: false
    })
    setLastName({
      message: '',
      hide: false,
      value: '',
      error: false
    })
    setEmail({
      message: '',
      hide: false,
      value: '',
      error: false
    })
    setPhone({
      message: '',
      hide: false,
      value: '',
      error: false
    })
    setSubjectState({
      label: '',
      value: '',
      style: customStyle,
      error: false
    })
    setSubSubjectState({
      label: '',
      value: '',
      style: customStyle,
      error: false
    })
    setDescription({
      message: '',
      hide: false,
      value: '',
      error: false
    })
  }
  const handleChangeSubSubject = (selectedOption: any) => {
    setSubSubjectState({
      label: selectedOption.label,
      value: selectedOption.value,
      style: customStyle,
      error: false
    })
  }
  const handleChangeSubject = (selectedOption: any) => {
    setSubjectState({
      label: selectedOption.label,
      value: selectedOption.value,
      style: customStyle,
      error: false
    })
    let response: IOption[] = [{ value: '', label: 'Seleccione un opción' }]
    types.forEach((element: any) => {
      if (element.id === selectedOption.value) {
        response = element.subTypes.map((item: any) => {
          return { value: item.id, label: item.label }
        })
      }
    })
    setSubtypes(response)
  }

  const handleSendContact = async (
    firstName: string,
    lastName: string,
    businessName: string,
    typeIdentificaction: string,
    identification: string,
    email: string,
    pqrsTypeId: string,
    phone: string,
    description: string
  ) => {
    const status = await mutate({
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          businessName: businessName,
          typeIdentificaction: typeIdentificaction,
          identification: identification,
          email: email,
          pqrsTypeId: pqrsTypeId,
          phone: phone,
          description: description
        }
      }
    })

    if (status) {
      closeModal && closeModal(false)
      forceShowNotification &&
        forceShowNotification({
          type: 'ok',
          message: 'Tu petición ha sido registrada'
        })
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Error al registrar la petición'
        })
    }
  }
  const [countDes, setCountDes] = useState(0)

  const handleValidatePQR = () => {
    if (identificationState.value.length <= 0) {
      setIdentificationState({
        ...identificationState,
        error: true,
        style: errorStyle
      })
    }

    if (subjectState.value.length <= 0) {
      setSubjectState({
        ...subjectState,
        error: true,
        style: errorStyle
      })
    }

    if (subSubjectState.value.length <= 0) {
      setSubSubjectState({
        ...subSubjectState,
        error: true,
        style: errorStyle
      })
    }

    if (identificationState.type != 'RUC') {
      validateData(firstNameState.value, 'firstName')
      validateData(lastNameState.value, 'lastName')
    } else {
      validateData(businessNameState.value, 'businessName')
    }

    validateData(idNumberState.value, 'identificationNumber')
    validateData(emailState.value, 'email')
    validateData(subjectState.value, 'subject')
    validateData(subSubjectState.value, 'subSubject')
    validateData(phoneState.value, 'phone')
    validateData(descriptionState.value, 'description')

    if (
      identificationState.type == 'RUC' &&
      (!businessNameState.value ||
        !identificationState.type ||
        !idNumberState.value ||
        !emailState.value ||
        !subjectState.value ||
        !phoneState.value ||
        !descriptionState.value ||
        businessNameState.error ||
        identificationState.error ||
        idNumberState.error ||
        emailState.error ||
        subjectState.error ||
        phoneState.error ||
        descriptionState.error)
    ) {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Los campos con (*) son requeridos'
        })
      return
    } else if (
      identificationState.type != 'RUC' &&
      (!firstNameState.value ||
        !lastNameState.value ||
        !identificationState.type ||
        !idNumberState.value ||
        !emailState.value ||
        !subjectState.value ||
        !phoneState.value ||
        !descriptionState.value ||
        firstNameState.error ||
        lastNameState.error ||
        identificationState.error ||
        idNumberState.error ||
        emailState.error ||
        subjectState.error ||
        phoneState.error ||
        descriptionState.error)
    ) {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Todos los campos son requeridos'
        })
      return
    }

    handleSendContact(
      firstNameState.value,
      lastNameState.value,
      businessNameState.value,
      identificationState.value,
      idNumberState.value,
      emailState.value,
      subSubjectState.value,
      phoneState.value,
      descriptionState.value
    )

    clearFileds()
  }

  type typePqrs = {
    id: string
    label: string
  }

  return (
    <Query
      variables={{ filters, options }}
      query={GET_PQRSTYPES}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<any>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <div>error</div>
        }

        if (!data) {
          return <div>No hay data</div>
        }
        setTypes(
          data.pqrsTypes && data.pqrsTypes.pqrs_types
            ? data.pqrsTypes.pqrs_types
            : []
        )
        const pqrs_type = data.pqrsTypes
          ? data.pqrsTypes.pqrs_types.map((type: typePqrs) => {
              return { value: type.id, label: type.label }
            })
          : [{ value: '', label: '' }]

        return (
          <Row>
            <Column md={12}>
              <Row className="contact-form">
                <Column lg={6} md={6} sm={12}>
                  <FlexCol
                    className={firstNameState.hide ? 'hidden-input' : ''}
                  >
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'firstName')
                      }}
                      id="firstName"
                      type="text"
                      label="Nombre"
                      required
                      error={firstNameState.error}
                      value={firstNameState.value}
                    />
                    {firstNameState.error ? (
                      <Error>{firstNameState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                  <FlexCol
                    className={businessNameState.hide ? 'hidden-input' : ''}
                  >
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'businessName')
                      }}
                      id="businessName"
                      type="text"
                      label="Razón Social"
                      required
                      error={businessNameState.error}
                      value={businessNameState.value}
                    />
                    {businessNameState.error ? (
                      <Error>{businessNameState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
                <Column
                  lg={6}
                  md={6}
                  sm={12}
                  className={lastNameState.hide ? 'hidden-input' : ''}
                >
                  <FlexCol>
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'lastName')
                      }}
                      id="lastName"
                      type="text"
                      label="Apellido"
                      required
                      error={lastNameState.error}
                      value={lastNameState.value}
                    />
                    {lastNameState.error ? (
                      <Error>{lastNameState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
              </Row>

              <Row className="contact-form">
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <span>Tipo de Identificación</span>
                    <Select
                      options={identificationTypeOptions}
                      onChange={(e: any) => handleChange(e, true)}
                      value={
                        identificationState && identificationState.value == ''
                          ? getSelected
                          : {
                              label: identificationState.type,
                              value: identificationState.value
                            }
                      }
                      defaultValue={getSelected}
                      styles={identificationState.style}
                    />
                    {identificationState.error ? (
                      <Error>Campo Requerido</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'identificationNumber')
                      }}
                      id="identificationNumber"
                      label="Identificación"
                      type="text"
                      required
                      disabled={identificationState.disable}
                      error={idNumberState.error}
                      value={idNumberState.value}
                    />
                    {idNumberState.error ? (
                      <Error>{idNumberState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
              </Row>
              <Row className="contact-form">
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'phone')
                      }}
                      id="phone"
                      type="text"
                      label="Teléfono"
                      required
                      error={phoneState.error}
                      value={phoneState.value}
                    />
                    {phoneState.error ? (
                      <Error>{phoneState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <Input
                      onChange={(e: any) => {
                        validateData(e.target.value, 'email')
                      }}
                      id="email"
                      type="text"
                      label="Email"
                      maxLength={60}
                      required
                      error={emailState.error}
                      value={emailState.value}
                    />
                    {emailState.error ? (
                      <Error>{emailState.message}</Error>
                    ) : (
                      <Spacer />
                    )}
                  </FlexCol>
                </Column>
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <ApolloConsumer>
                      {(client) => (
                        <>
                          <span>Tipo</span>
                          <Select
                            options={pqrs_type}
                            onChange={handleChangeSubject}
                            value={
                              subjectState && subjectState.value == ''
                                ? getSelected
                                : {
                                    label: subjectState.label,
                                    value: subjectState.value
                                  }
                            }
                            defaultValue={getSelected}
                            styles={subjectState.style}
                            id="subject"
                          />
                          <>
                            {subjectState.error ? (
                              <Error>
                                Campo obligatorio, no puede estar vacio
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                          </>
                        </>
                      )}
                    </ApolloConsumer>
                  </FlexCol>
                </Column>
                <Column lg={6} md={6} sm={12}>
                  <FlexCol>
                    <ApolloConsumer>
                      {(client) => (
                        <>
                          <span>Subtipo</span>
                          <Select
                            options={subtypes}
                            onChange={handleChangeSubSubject}
                            value={
                              subSubjectState && subSubjectState.value == ''
                                ? getSelected
                                : {
                                    label: subSubjectState.label,
                                    value: subSubjectState.value
                                  }
                            }
                            defaultValue={getSelected}
                            styles={subSubjectState.style}
                            id="subsubject"
                          />
                          <>
                            {subSubjectState.error ? (
                              <Error>
                                Campo obligatorio, no puede estar vacio
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                          </>
                        </>
                      )}
                    </ApolloConsumer>
                  </FlexCol>
                </Column>
              </Row>
              <Row className="contact-form">
                <Column lg={12} md={12} sm={12}>
                  <FlexCol>
                    <Textarea
                      onChange={(e: any) => {
                        validateData(e.target.value, 'description')
                      }}
                      onKeyUp={(e: any) => {
                        setCountDes(descriptionState.value.length)
                      }}
                      rows={3}
                      maxLength={500}
                      borderColor="#2684FF"
                      label="Mensaje"
                      required
                      id="description"
                      error={descriptionState.error}
                      value={descriptionState.value}
                    />
                    <TextAreaElements>
                      <Element>
                        {descriptionState.error ? (
                          <Error>{descriptionState.message}</Error>
                        ) : (
                          <Spacer />
                        )}
                      </Element>
                      <CountContainer>{countDes}/500</CountContainer>
                    </TextAreaElements>
                  </FlexCol>
                </Column>
              </Row>
              <StyledRow>
                <Column lg={4} md={6} sm={12}>
                  <ContainerButton>
                    <Button
                      backgroundColor={primary}
                      color={secondary}
                      onClick={handleValidatePQR}
                    >
                      Enviar
                    </Button>
                  </ContainerButton>
                </Column>
              </StyledRow>
            </Column>
          </Row>
        )
      }}
    </Query>
  )
}

const sendPqrWithMutation = compose(graphql(SEND_PQR_MUTATION))(
  withApollo(ContactForm)
)
export default sendPqrWithMutation
