import React, { useContext, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '../../components/Buttons'

import Checkout from '../../components/Checkout'
import Loader from '../../components/loader'
import * as Grid from '../../components/Grid'
import formatNumber from '../../helpers/formatNumber'
import InputRange from 'react-input-range'
import bpLogo from '../../assets/header/bp-logo.png'
import novaLogo from '../../assets/header/nova-logo.svg'
import { Link } from 'react-router-dom'

import {
  ContentWrapper,
  Points,
  AmountsContainer,
  TotalContainer,
  ButtonContainer,
  SpanPayment,
  ProductListContainer,
  TitleContainer,
  MobileIconContainer,
  IconContainer,
  IconWrapper,
  Title,
  StyledRow,
  RangeWrapper
  //RangeNovaWrapper
} from './style'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import { StringifyOptions } from 'querystring'
interface Points {
  label: string
  code: StringifyOptions
  color: string
  logo?: string
  max: number
  used: number
  total: number
  secondColor?: string
}
function Selection({ history, mutate }: any) {
  const { items, loaded, account, forceShowNotification, onSetLoaded } =
    useContext(CartContext)

  const [totalPoints, setTotalPoints] = useState<Points[]>([])
  const [disabled, setDisabled] = useState(false)
  //const [novaPoints, setNovaPoints] = useState(0)

  let total: number = 0
  const calculatePayment = () => {
    items.forEach((item) => {
      total += Math.round(item.pointCost * item.quantity)
    })
  }
  const calculatePoints = () => {
    let initialPoints: any = []
    if (account.balances) {
      account.balances.map((item: any) => {
        initialPoints.push({
          label: item.currency ? item.currency.name : '',
          code: item.currency ? item.currency.id : '',
          color: item.currency.name === 'Pichincha' ? '#ffe200' : '#0066cc',
          logo: item.currency.name === 'Pichincha' ? bpLogo : novaLogo,
          max: 0,
          used: 0,
          total: item.total,
          secondColor:
            item.currency.name === 'Pichincha' ? '#014178' : '#ffffff'
        })
      })
    }

    initialPoints = initialPoints.sort(function (a: Points, b: Points) {
      if (a.total > b.total) {
        return 1
      }
      if (a.total < b.total) {
        return -1
      }
      return 0
    })

    initialPoints[0].used =
      initialPoints[0].total < Math.floor(total / initialPoints.length)
        ? initialPoints[0].total
        : Math.floor(total / initialPoints.length)
    let rest = Math.floor(total - initialPoints[0].used)

    initialPoints = initialPoints.map((item: Points, index: number) => {
      item.max = item.total >= total ? total : item.total
      if (index > 0) {
        item.used =
          item.max > Math.floor(rest / (initialPoints.length - 1))
            ? Math.floor(rest / (initialPoints.length - 1))
            : item.max
      }
      return item
    })

    setTotalPoints(initialPoints)

    /* let bp
    let nova
    if (account.novaPoints < account.pichinchaPoints) {
      nova =
        account.novaPoints < total / 2
          ? account.novaPoints
          : (total / 2).toFixed(0)
      bp = (total - nova).toFixed(0)
    } else {
      bp =
        account.pichinchaPoints < total / 2
          ? account.pichinchaPoints
          : (total / 2).toFixed(0)
      nova = (total - bp).toFixed(0)
    }
    setBpPoints(bp)
    setNovaPoints(nova) */
  }

  calculatePayment()
  const handleNova = (value: number, index: number) => {
    //const currentPoints = totalPoints[index].used;
    let current = totalPoints
    current[index].used = value
    const rest = total && total > 0 ? parseInt(total.toFixed(0)) - value : 0

    current = current.map((item: Points, subindex: number) => {
      if (index !== subindex) {
        item.used =
          Math.floor(rest / (current.length - 1)) <= item.max
            ? Math.floor(rest / (current.length - 1))
            : item.max
      }
      return item
    })
    setTotalPoints(current)
  }

  const goToShipping = async () => {
    if (
      totalPoints.reduce((accumulator: number, item: any) => {
        return accumulator + (item.used ? item.used : 0)
      }, 0) === total &&
      account.balances &&
      account.balances.reduce((accumulator: number, item: any) => {
        return accumulator + (item.total ? item.total : 0)
      }, 0)
    ) {
      const currency = totalPoints
        .filter((item: Points) => {
          return item.used > 0
        })
        .map((item: Points) => {
          return { code: item.code, amount: item.used }
        })
      window.localStorage.setItem('currency', JSON.stringify(currency))
      history.push('/shipping')
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Puntos insuficientes'
        })
    }
  }

  /* const handleBp = (value: number) => {
    setBpPoints(value)
    const rest =
      total && total > 0 && total.toFixed(0)
        ? parseInt(total.toFixed(0)) - value
        : 0
    setNovaPoints(rest <= novaMax ? rest : novaMax)
  } */

  useEffect(() => {
    onSetLoaded && onSetLoaded(true)
    calculatePoints()
  }, [])

  useEffect(() => {
    if (
      totalPoints.reduce((accumulator: number, item: any) => {
        return accumulator + (item.used ? item.used : 0)
      }, 0) === total &&
      account.balances &&
      account.balances.reduce((accumulator: number, item: any) => {
        return accumulator + (item.total ? item.total : 0)
      }, 0)
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [totalPoints])

  const { primary } = useContext(ThemeContext)

  return loaded ? (
    <Checkout>
      <ProductListContainer>
        <TitleContainer>
          <MobileIconContainer className={'back'}>
            <Link to="/cart">
              <IconWrapper>
                <i className={'flex-icon icon-atras'}></i>
              </IconWrapper>
              <p>Volver</p>
            </Link>
          </MobileIconContainer>
          <MobileIconContainer>
            <Link to="/catalog">
              <IconWrapper>
                <i className={'flex-icon icon-catalogo'}></i>
              </IconWrapper>
              <p>Catálogo</p>
            </Link>
          </MobileIconContainer>
          <Grid.Column md={2} className="desktop-wrapper">
            <Link to="/cart">
              <IconContainer className={'back'}>
                <IconWrapper>
                  <i className={'flex-icon icon-atras'}></i>
                </IconWrapper>
                <p>Volver</p>
              </IconContainer>
            </Link>
          </Grid.Column>
          <Grid.Column md={8}>
            <Title>
              <h2>
                Selección <br />
                de puntos
              </h2>
            </Title>
          </Grid.Column>
          <Grid.Column md={2} className="desktop-wrapper">
            <Link to="/catalog">
              <IconContainer>
                <IconWrapper>
                  <i className={'flex-icon icon-catalogo'}></i>
                </IconWrapper>
                <p>Ir al catálogo de premios</p>
              </IconContainer>
            </Link>
          </Grid.Column>
        </TitleContainer>
        <ContentWrapper>
          <Points>
            {formatNumber(total)} Puntos <span>TOTAL</span>
          </Points>
          <p>​Seleccione los puntos que deseas canjear</p>
          {/* <RangeWrapper>
            <img src={bpLogo} alt="PICHINCHA" className={'range-logo'} />
            <InputRange
              maxValue={bpMax}
              minValue={0}
              value={bpPoints}
              onChange={(value: any) => handleBp(value as number)}
            />
            <img src={bpLogo} alt="PICHINCHA" className={'range-logo mobile'} />
          </RangeWrapper>
          <RangeNovaWrapper>
            <img src={novaLogo} alt="NOVA" className={'range-logo'} />
            <InputRange
              maxValue={novaMax}
              minValue={0}
              value={novaPoints}
              onChange={(value: any) => handleNova(value as number)}
            />
            <img src={novaLogo} alt="NOVA" className={'range-logo mobile'} />
          </RangeNovaWrapper> */}
          {totalPoints.map((item: Points, index: number) => {
            return (
              <RangeWrapper
                key={index}
                color={item.color}
                secondColor={item.secondColor}
              >
                <img
                  src={item.logo}
                  alt={item.label}
                  className={'range-logo'}
                />
                <InputRange
                  formatLabel={(value: any) => formatNumber(value)}
                  maxValue={item.max}
                  minValue={0}
                  value={item.used}
                  onChange={(value: any) => handleNova(value as number, index)}
                />
                <img
                  src={item.logo}
                  alt={item.label}
                  className={'range-logo mobile'}
                />
              </RangeWrapper>
            )
          })}
        </ContentWrapper>

        <StyledRow>
          <AmountsContainer>
            <TotalContainer>
              <SpanPayment>{formatNumber(total)} Puntos</SpanPayment>
              <SpanPayment className="total">TOTAL</SpanPayment>
            </TotalContainer>
            <ButtonContainer>
              <Button
                backgroundColor={'#ffd000'}
                color={primary}
                onClick={() => {
                  goToShipping()
                }}
                id={'MKTPL_SHIP_NEXT'}
                disabled={disabled}
              >
                CANJEAR
              </Button>
            </ButtonContainer>
          </AmountsContainer>
        </StyledRow>
      </ProductListContainer>
    </Checkout>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  )
}

const AddTracklWithMutation = withRouter(Selection)

export default AddTracklWithMutation
