import gql from 'graphql-tag'

const GET_TRANSACTIONS_QUERY = gql`
  query getTransactionsQuery(
    $filters: TransactionsFilters!
    $options: TransactionsOptions!
  ) {
    transactions(filters: $filters, options: $options) {
      nodes {
        id
        description
        amount
        totalBalance
        createdAt
        status
        transactionType {
          id
          name
          type
          description
        }
        details {
          purchases {
            variation {
              product {
                name
              }
            }
          }
          promoName
        }
        copaymentCurrency {
          currency_name
          amount
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export { GET_TRANSACTIONS_QUERY }
