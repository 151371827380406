import styled from 'styled-components'
import sizes from '../../styles/media'
import { Column } from '../Grid'

export const StyledColumn = styled(Column)`
  display: flex;
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &.red {
    color: #ff0000;
  }
  &.green {
    color: #014178;
  }
  &.right {
    justify-content: flex-end;
  }
`

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${sizes.sm} {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: none;
  @media ${sizes.sm} {
    display: flex;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f8f9;
  padding: 1rem;
  border-radius: 4px;
  &.opened {
    background-color: #ffffff;
  }
`
export const Title = styled.div`
  font-weight: normal;
  letter-spacing: 0.15px;
  color: #0066cc;
  font-size: 1.13rem;
`

export const Content = styled.div`
  font-weight: normal;
  letter-spacing: 0.15px;
  color: #0066cc;
  margin: 1.5rem 0 0;
`

export const ItemWrapper = styled.div`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  background-color: #ffffff;
  margin: 0.5rem 0;
`
export const Tr = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  @media ${sizes.sm} {
    padding: 0 1rem;
    border-bottom: 1px solid #eeeeee;
    align-items: center;
  }
`

export const Th = styled.div`
  font-weight: normal;
  letter-spacing: 0.15px;
  color: #0066cc;
  font-size: 1.13rem;
  width: 50%;
  min-width: 50%;
  @media ${sizes.sm} {
    padding: 1rem 0;
    width: auto;
    min-width: unset;
  }
`

export const Td = styled.div`
  letter-spacing: 0.13px;
  color: #6c6c6c;
  width: 100%;
  &.red {
    color: #ff0000;
  }
  &.green {
    color: #014178;
  }
`
export const Tfooter = styled.div`
  display: flex;
  width: 100%;
  background-color: #f4f8f9;
  border-radius: 0 0 4px 4px;
  p {
    margin: 0 0 1rem;
  }
`
export const Table = styled.div`
  border-radius: 4px;
  border: solid 1px #e9eff4;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  color: #6c6c6c;
  width: 100%;
  .detail {
    background-color: #f4f8f9;
    display: flex;
    padding: 1rem;
    p {
      margin: 0 2rem;
    }
  }
`
export const Detalle = styled.div`
  background-color: #f4f8f9;
  display: flex;
  flex-direction: column;
  @media ${sizes.sm} {
    padding: 0 1rem 0 1rem;
  }
  p {
    margin: 0 2rem;
  }
  .detalle {
    margin: 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${sizes.sm} {
      margin: 0.5rem 0 0;
      flex-direction: row;
    }
    &.bordered {
      border-bottom: 1px solid white;
    }
  }

  ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 1rem;
    @media ${sizes.sm} {
      margin-bottom: 0;
    }
  }
`
export const Circle = styled.div`
  width: 58px;
  height: 58px;
  background-color: #f4f8f9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.opened {
    background-color: #0066cc;
  }
`

export const Sort = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 0.9rem;
  }
`

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
