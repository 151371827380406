import gql from 'graphql-tag'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $filters: ProductsFilters!
    $options: ProductsOptions!
  ) {
    products(filters: $filters, options: $options) {
      nodes {
        id
        name
        slug
        description
        summary
        assets {
          url
          order
        }
        categories {
          slug
          name
          id
        }
        brand {
          name
        }
        variations {
          id
          price
          regularPrice
          reference
          status
          inventory {
            localStock
            storageStock
          }
          assets {
            url
            order
          }
        }
        minPrice
        minPoint
        maxRegularPrice
        status
        supplier {
          business_name
          assets {
            url
            order
          }
          metaData {
            entrega
            monto_minimo
          }
        }
        features {
          id
          name
          options {
            id
            name
          }
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

const GET_CITIES = gql`
  query getCities($filters: CategoriesFilters!, $options: CategoriesOptions!) {
    categories(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

export { GET_PRODUCTS_QUERY, GET_CITIES }
