import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  backgroundcolor?: string
  color: string
  icon?: string
  sizeicon?: string
  bordercolor?: string
  hoverColor?: string
  padding?: string
}

export const NavButtonContainer = styled(Link)<Props>`
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '1rem 0rem 1rem')};
  font-weight: bold;
  line-height: 1.2;
  border-radius: 2px;
  font-size: 14px;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border: ${(props) =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${(props) => props.color};
    color: ${(props) => props.backgroundcolor};
    border: 1px solid ${(props) => props.backgroundcolor};
  }
`

export const ButtonContainer = styled.button<Props>`
  font-family: 'PreloSlab';
  text-transform: capitalize;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) =>
    props.padding ? props.padding : '0.8rem 0.5rem 0.8rem'};
  font-weight: 600;
  line-height: normal;
  outline: none;
  border-radius: 2px;
  font-size: 14px;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border: ${(props) =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? props.backgroundcolor : props.color};
    color: ${(props) =>
      props.disabled
        ? props.color
        : props.hoverColor
        ? props.hoverColor
        : props.backgroundcolor};
    border: 1px solid ${(props) => props.backgroundcolor};
  }
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`

export const Title = styled.span`
  font-weight: bold;
`

export const StyledNavIconButton = styled(Link)<Props>`
  border-radius: 50%;
  color: #ffffff;
  padding: auto;
  border: none;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 2;

  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;

  background-color: ${(props) => props.color};

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${(props) => props.color};
  }

  ${(props) => props.disabled && 'opacity: 0.4; cursor: not-allowed;'};

  @media ${sizes.md} {
    width: 45px;
    height: 45px;
    font-size: ${(props) => (props.sizeicon ? props.sizeicon : '26px')};
  }
`

export const StyledIconButton = styled.button<Props>`
  border-radius: 50%;
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : props.color};
  color: ${(props) => props.color};
  padding: auto;
  border: none;
  font-size: ${(props) => (props.sizeicon ? props.sizeicon : '16px')};
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  width: 44px;
  height: 44px;
`

export const Label = styled.span`
  display: block;
  padding: 0 10px;
`
