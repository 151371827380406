import styled from 'styled-components'
import sizes from '../../styles/media'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background-color: #f4f8f9;
  display: block;
  @media ${sizes.md} {
    display: block;
  }
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem auto 2rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #007aff;
  font-size: 14px;
  padding: 3rem 1rem;
  text-align: center;
  border-radius: 4px;
  @media ${sizes.sm} {
    width: 75%;
    padding: 3rem 4rem;
    font-size: 16px;
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .background {
    width: 100%;
    &.desktop {
      display: none;
      @media ${sizes.sm} {
        display: block;
      }
    }
    &.mobile {
      display: block;
      @media ${sizes.sm} {
        display: none;
      }
    }
  }
  .logo {
    position: absolute;
    width: 115px;
    @media ${sizes.sm} {
      width: 200px;
    }
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 460px;
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  label {
    color: #0066cc;
  }
`

export const ContainerButton = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  @media ${sizes.sm} {
    margin: 0 0.5rem;
    max-width: 250px;
    .lonely {
      margin: 0 auto;
    }
  }
`

export const WrapperButtons = styled.div`
  margin: 0.5rem auto 0;
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  @media ${sizes.sm} {
    flex-direction: row;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 10% auto 2rem;
  justify-content: center;
  align-items: center;
  @media ${sizes.sm} {
    width: 50%;
  }
  h1 {
    font-family: PreloSlab;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #014178;
    width: 80%;
    text-transform: uppercase;
    @media ${sizes.sm} {
      text-transform: inherit;
      width: 100%;
      font-size: 40px;
    }
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
    width: 100%;
    margin: 1rem auto 3.5rem;
    @media ${sizes.sm} {
      width: 80%;
      max-width: 394px;
      font-size: 16px;
    }
  }
`
