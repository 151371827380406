import React from 'react'

import { Route, Switch } from 'react-router'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import Error from '../views/Error'

import cartFallback from '../helpers/cartFallback'
import shippingFallback from '../helpers/shippingFallback'
import paymentFallback from '../helpers/paymentFallback'
import prehomeFallBack from '../helpers/prehomeFallback'

import AuthViewHandler from '../middleware/auth'
import TycLopdViewHandler from '../middleware/autorizeTycAndOrLopd'

import Home from '../views/Home'
import Catalog from '../views/Catalog'
import Prehome from '../views/Prehome'
import Remember from '../views/Remember'
import Reset from '../views/Reset'
import Cart from '../views/Cart'
import Selection from '../views/Selection'
import Shipping from '../views/Shipping'
import Payment from '../views/Payment'
import Contact from '../views/Contact'
import Profile from '../views/Profile'
import EmptyCart from '../views/EmptyCart'
import Points from '../views/Points'

const CartFallBack = cartFallback(Cart, () => <Redirect to="/home" />)

const ShippingFallBack = shippingFallback(Shipping, () => (
  <Redirect to="/home" />
))

const PrehomeFallBack = prehomeFallBack(Prehome, () => <Redirect to="/home" />)

const PaymentFallBack = paymentFallback(Payment, () => (
  <Redirect to="/billing" />
))

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={PrehomeFallBack} />
        <Route exact path="/remember" component={Remember} />
        <Route exact path="/reset" component={Reset} />
        <AuthViewHandler>
          <Route exact path="/home" component={Home} />
          <TycLopdViewHandler>
            <Route exact path="/catalog" component={Catalog} />
            <Route exact path="/cart" component={CartFallBack} />
            <Route exact path="/shipping" component={ShippingFallBack} />
            <Route exact path="/billing" component={Selection} />
            <Route exact path="/payment" component={PaymentFallBack} />
            <Route exact path="/empty-cart" component={EmptyCart} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/points" component={Points} />
          </TycLopdViewHandler>
        </AuthViewHandler>
        <Route render={(props) => <Error {...props} errorCode={404} />} />
        <Route
          exact
          path="/oops"
          render={(props) => <Error {...props} errorCode={500} />}
        />
      </Switch>
    </Router>
  )
}

export default Routes
