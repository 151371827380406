import React, { useRef, useState } from 'react'
import Layout from '../../components/Layout'
import { Container, Column, Row } from '../../components/Grid'
import Catalog from '../../components/Catalog'
import { Banner, Text, StyledColumn } from './style'
import bannerDesktop from '../../assets/banners/banner-desktop.svg'

type OptionProps = {
  value: string
  label: string
  icon: string
}

function Home() {
  const [currentCategory] = useState({} as OptionProps)

  const CatalogRef = useRef<null | HTMLDivElement>(null)

  return (
    <Layout showBanner={true} showCart={true} currentPage={'home'}>
      <Container id="MKTPL_HOME">
        <Row>
          <div style={{ width: '100%' }}>
            <Column>
              <Banner>
                <Row>
                  <StyledColumn md={6}>
                    <Text>
                      <h3>Acumula puntos y canjéalos</h3>
                      <p>
                        por los productos o experiencias que siempre soñaste.
                      </p>
                    </Text>
                  </StyledColumn>
                  <Column md={6}>
                    <img src={bannerDesktop} />
                  </Column>
                </Row>
              </Banner>
            </Column>
          </div>
        </Row>
        <Row>
          <div style={{ width: '100%' }} ref={CatalogRef}>
            <Column md={12}>
              <Catalog
                currentCategory={currentCategory}
                limit={8}
                sort={'-priority'}
              />
            </Column>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default Home
