import React, { useState, useContext } from 'react'
import { graphql, compose, withApollo, MutateProps } from 'react-apollo'
import { Row, Column } from '../../components/Grid'

import { RouteComponentProps, withRouter } from 'react-router'

import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'

import CartContext from '../../context/CartContext'

import { FlexCol, WrapContaninerChecks, Checkboxs, CheckboxsGroup, ButtonAccept } from './style'

import { REGISTER_MEMBER } from './query'

interface IProps extends RouteComponentProps<any>, MutateProps<any> {
  closeModal?: any
  token: string
  client: any
}
function RegisterForm({ token, mutate, history, client, closeModal }: IProps) {
  const { forceShowNotification } = useContext(CartContext)
  const [email, setEmail] = useState('')
  const [reEmail, setReEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordCopy, setPasswordCopy] = useState('')
  const [phone, setPhone] = useState('')
  const [terms, setTerms] = useState(false)
  const [lopd, setLopd] = useState(false)
  const [error, setError] = useState({
    email: false,
    password: false,
    passwordCopy: false,
    phone: false,
    reEmail: false
  })
  const [message, setMessage] = useState('')

  // Validate input data
  const validate = () => {
    if (
      !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw]{2,4})(\]?)$/.test(
        email
      )
    ) {
      setError((prevState: any) => ({
        ...prevState,
        email: true
      }))
      return { error: true, message: 'Ingresa un correo válido' }
    }
    if (reEmail !== email) {
      setError((prevState: any) => ({
        ...prevState,
        reEmail: true
      }))
      return { error: true, message: 'Los correos no coinciden' }
    }
    if (password.length < 8) {
      setError((prevState: any) => ({
        ...prevState,
        password: true
      }))
      return { error: true, message: 'Debe contener al menos 8 caracteres' }
    }
    if (passwordCopy !== password) {
      setError((prevState: any) => ({
        ...prevState,
        passwordCopy: true
      }))
      return { error: true, message: 'Las contraseñas no coinciden' }
    }

    if (phone.length !== 10) {
      setError((prevState: any) => ({
        ...prevState,
        phone: true
      }))
      return { error: true, message: 'Ingresa un celular válido' }
    }

    return { error: false, message: '' }
  }
  const clearFields = () => {
    setPhone('')
    setEmail('')
    setReEmail('')
    setPassword('')
    setPasswordCopy('')
    setTerms(false)
    setLopd(false)
  }
  const handleClic = async () => {
    const validation = validate()
    if (validation.error) {
      setMessage(validation.message)
    } else {
      if (terms && lopd) {
        await mutate({
          variables: {
            input: {
              token: token,
              username: email,
              password: password,
              member: {
                cellphone: phone,
                registered: true,
                terms: true,
                lopd: true,
                receive_info: true
              }
            }
          }
        })
          .then((response: any) => {
            if (response && response.data && response.data.register) {
              if (forceShowNotification) {
                forceShowNotification({
                  type: 'info',
                  message: 'Registrado exitosamente.'
                })
                closeModal && closeModal(false)
              }
            }
          })
          .catch(() => {
            if (forceShowNotification) {
              forceShowNotification({
                type: 'fail',
                message: 'Error al guardar la información, inténtalo más tarde'
              })
            }
            setTerms(false)
          })

        clearFields()
      } else {
        if (forceShowNotification) {
          forceShowNotification({
            type: 'fail',
            message: 'Acepta los términos y condiciones'
          })
        }
      }
    }
  }
  const validateChecks = () => {
    return (terms && lopd)
  }
  const handleChange = (e: any) => {
    const { id, value } = e.target
    switch (id) {
      case 'email':
        setEmail(value)
        setError((prevState: any) => ({
          ...prevState,
          email: false
        }))
        break
      case 'password':
        setPassword(value)
        setError((prevState: any) => ({
          ...prevState,
          password: false
        }))
        break
      case 'passwordCopy':
        setPasswordCopy(value)
        setError((prevState: any) => ({
          ...prevState,
          passwordCopy: false
        }))
        break

      case 'phone':
        const currentValue = value.replace(/[^0-9]/gi, '')
        if (currentValue.length <= 10) {
          setPhone(currentValue)
          setError((prevState: any) => ({
            ...prevState,
            phone: false
          }))
        }
        break

      case 'reEmail':
        setReEmail(value)
        setError((prevState: any) => ({
          ...prevState,
          reEmail: false
        }))
        break

      default:
        break
    }
  }

  return (
    <Row>
      <Column md={12}>
        <Row className="contact-form">
          <Column lg={12} md={12} sm={12}>
            <FlexCol>
              <Input
                id="email"
                label="Email"
                type="email"
                required
                value={email}
                onChange={(e) => handleChange(e)}
              />
              {error.email ? <Error>{message}</Error> : <Spacer />}
            </FlexCol>
          </Column>
        </Row>

        <Row>
          <Column lg={12} md={12} sm={12}>
            <FlexCol>
              <Input
                id="reEmail"
                label="Confirmar Email"
                type="email"
                required
                value={reEmail}
                onChange={(e) => handleChange(e)}
              />
              {error.reEmail ? <Error>{message}</Error> : <Spacer />}
            </FlexCol>
          </Column>
        </Row>

        <Row>
          <Column lg={12} md={12} sm={12}>
            <FlexCol>
              <Input
                id="password"
                label="Nueva contraseña"
                type="password"
                required
                value={password}
                onChange={(e) => handleChange(e)}
              />
              {error.password ? <Error>{message}</Error> : <Spacer />}
            </FlexCol>
          </Column>
        </Row>

        <Row>
          <Column lg={12} md={12} sm={12}>
            <FlexCol>
              <Input
                id="passwordCopy"
                label="Confirmar contraseña"
                type="password"
                required
                value={passwordCopy}
                onChange={(e) => handleChange(e)}
              />
              {error.passwordCopy ? <Error>{message}</Error> : <Spacer />}
            </FlexCol>
          </Column>
        </Row>

        <Row>
          <Column lg={12} md={12} sm={12}>
            <FlexCol>
              <Input
                id="phone"
                label="Celular"
                type="text"
                required
                value={phone}
                onChange={(e) => handleChange(e)}
              />
              {error.phone ? <Error>{message}</Error> : <Spacer />}
            </FlexCol>
          </Column>
        </Row>

        <WrapContaninerChecks>
          <Checkboxs>
            <CheckboxsGroup>
              <div>
                <input type="checkbox" id="terms" name="terms"
                  className="checkboxs__custom" onChange={() => setTerms(!terms)} />
              </div>
              <span>
                He leído y acepto los&nbsp;
                <a
                  className="modal-link"
                  href="https://nova-ecuador.s3.amazonaws.com/docs/TERMINOS_Y_CONDICIONES_DEL_PROGRAMA.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  términos y condiciones.
                </a>
              </span>
            </CheckboxsGroup>
            <CheckboxsGroup>
              <div>
                <input type="checkbox" id="lopd" name="lopd"
                  className="checkboxs__custom" onChange={() => setLopd(!lopd)} />
              </div>
              <span>
                He leído y autorizo el&nbsp;
                <a
                  className="modal-link"
                  href="/docs/aviso_de_la_politica_de_privacidad_pyp_nova_2023.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  tratamiento de datos personales.
                </a>
              </span>
            </CheckboxsGroup>
          </Checkboxs>
          <ButtonAccept
            className={(validateChecks()) ? 'btnAccept--active': ''}
            disabled={!(validateChecks())}
            onClick={handleClic}
          >Aceptar</ButtonAccept>
        </WrapContaninerChecks>
      </Column>
    </Row>
  )
}

const RegisterWithMutation = compose(graphql(REGISTER_MEMBER))(
  withRouter(withApollo(RegisterForm))
)
export default RegisterWithMutation
