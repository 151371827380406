import styled from 'styled-components'
import sizes from '../../../styles/media'
import * as React from 'react'

const overlay: React.CSSProperties = {
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '1.2rem',
  zIndex: 10
}

const content: React.CSSProperties = {
  backgroundColor: '#f5f5f5',
  boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.2)',
  padding: 0,
  border: 0,
  top: '30%',
  bottom: 'auto',
  left: '50%',
  right: 'auto',
  width: '100%',
  maxWidth: '300px',
  margin: '0 auto',
  transform: 'translate(-50%)'
}

export const modalStyles = {
  overlay: overlay,
  content: content
}

export const Title = styled.h1`
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #1428a0;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 2.5rem 1rem;
  margin-bottom: 10px;
  height: 49px;
  font-size: 14px;
  text-align: center;
  color: #75787b;
  @media ${sizes.sm} {
    flex-direction: row;
  }
`
