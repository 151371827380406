import React, { useState } from 'react'
import { ApolloConsumer } from 'react-apollo'

import {
  Img,
  ImgNova,
  ActionLinksContainer,
  LogoContainer,
  StyledLogoLinkContainer,
  HeaderLinks,
  HeaderNoLinks,
  Wrapper,
  Line,
  LogoNovaContainer,
  Circle,
  Colapsable
} from './style'
import { useIsMobile } from '../../helpers/resize'
import logoDesktop from '../../assets/header/logopyp.svg'
import logoMobile from '../../assets/header/logopyp.svg'
import novaLogo from '../../assets/header/nova-logo-blanco.svg'

const menuItems = [
  { name: 'start', icon: '', label: '', prev: '', next: 'home' },
  {
    name: 'home',
    icon: 'icon-home',
    label: 'Inicio',
    prev: 'start',
    next: 'profile'
  },
  {
    name: 'profile',
    icon: 'icon-mi_cuenta',
    label: 'Mi Cuenta',
    prev: 'home',
    next: 'points'
  },
  {
    name: 'points',
    icon: 'icon-puntos',
    label: 'Puntos Acumulados',
    prev: 'profile',
    next: 'catalog'
  },
  {
    name: 'catalog',
    icon: 'icon-catalogo',
    label: 'Catálogo de Premios',
    prev: 'points',
    next: 'client'
  },
  {
    name: 'client',
    icon: 'icon-cliente',
    label: 'Preséntanos un cliente NOVA',
    prev: 'catalog',
    next: 'contact',
    link: 'https://www.novaseguroslatam.online/pypclientedirecto'
  },
  {
    name: 'contact',
    icon: 'icon-contactanos',
    label: 'Contáctanos',
    prev: 'client',
    next: 'end'
  },
  { name: 'end', icon: '', label: '', prev: 'contact', next: '' }
]
type Props = {
  currentPage: string
}

function MenuBar({ currentPage }: Props) {
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const currentItem = menuItems.filter((item: any) => {
    return currentPage == item.name
  })
  const [openMenu, setOpenMenu] = useState(false)
  /* const [page, setPage] = useState({
    name: 'home',
    icon: 'icon-home',
    label: 'Inicio',
    prev: 'start',
    next: 'acount'
  }) */
  /* const handleClic = (item: any) => {
    setPage(item)
  } */

  /*  useEffect(() => {
    if (currentPage != page.name) {
      const currentItem = menuItems.filter((item: any) => {
        return currentPage == item.name
      })
      if (currentItem && currentItem.length > 0) {
        setPage(currentItem[0])
      }
    }
  }, [currentPage]) */
  return (
    <ApolloConsumer>
      {(client) => (
        <Wrapper>
          <LogoContainer>
            <StyledLogoLinkContainer to="/">
              {!isMobile && <Img src={logoDesktop} />}
              {isMobile && <Img src={logoMobile} />}
            </StyledLogoLinkContainer>
            <Circle className="menu" onClick={() => setOpenMenu(!openMenu)}>
              <i className="flex-icon icon-menu"></i>
            </Circle>
          </LogoContainer>
          <Line />
          <Colapsable className={openMenu ? 'opened' : ''}>
            <ActionLinksContainer>
              {menuItems.map((item: any, index: number) => {
                let className = ''
                if (
                  currentItem &&
                  currentItem.length > 0 &&
                  item.name == currentItem[0].prev
                  //page &&
                  //page.prev &&
                  //page.prev == item.name
                )
                  className = className + 'right-before'
                if (
                  currentItem &&
                  currentItem.length > 0 &&
                  item.name == currentItem[0].next
                  //page &&
                  //page.next &&
                  //page.next == item.name
                )
                  className = className + 'right-after'
                if (item.label == '') className = className + ' no-show'
                return (
                  <>
                    {item.label !== '' && !item.link ? (
                      <HeaderLinks
                        to={item.name}
                        className={
                          currentItem &&
                          currentItem.length > 0 &&
                          item.name == currentItem[0].name
                            ? 'active ' + className
                            : ' ' + className
                        }
                        key={index}
                      >
                        <div className="bullet"></div>
                        <span
                          className={
                            currentItem &&
                            currentItem.length > 0 &&
                            item.name == currentItem[0].name
                              ? 'active'
                              : ''
                          }
                        >
                          <i className={item.icon} />
                          {item.label}
                        </span>
                      </HeaderLinks>
                    ) : item.label === '' ? (
                      <HeaderNoLinks
                        className={
                          currentItem &&
                          currentItem.length > 0 &&
                          item.name == currentItem[0].name
                            ? 'active ' + className
                            : ' ' + className
                        }
                        key={index}
                      >
                        <div className="bullet"></div>
                        <span
                          className={
                            currentItem &&
                            currentItem.length > 0 &&
                            item.name == currentItem[0].name
                              ? 'active'
                              : ''
                          }
                        >
                          <i className={item.icon} />
                          {item.label}
                        </span>
                      </HeaderNoLinks>
                    ) : (
                      <HeaderNoLinks
                        className={
                          currentItem &&
                          currentItem.length > 0 &&
                          item.name == currentItem[0].name
                            ? 'active ' + className
                            : ' ' + className
                        }
                        key={index}
                      >
                        <a href={item.link ? item.link : '#'} target="_blank">
                          <div className="bullet"></div>
                          <span
                            className={
                              currentItem &&
                              currentItem.length > 0 &&
                              item.name == currentItem[0].name
                                ? 'active'
                                : ''
                            }
                          >
                            <i className={item.icon} />
                            {item.label}
                          </span>
                        </a>
                      </HeaderNoLinks>
                    )}
                  </>
                )
              })}
            </ActionLinksContainer>
            <LogoNovaContainer>
              <StyledLogoLinkContainer to="/">
                <ImgNova src={novaLogo} />
              </StyledLogoLinkContainer>
            </LogoNovaContainer>
          </Colapsable>
        </Wrapper>
      )}
    </ApolloConsumer>
  )
}

export default MenuBar
