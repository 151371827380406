import styled from 'styled-components'
import sizes from '../../styles/media'
import { Row } from '../../components/Grid'

interface Props {
  backgroundcolor?: string
}
interface RangeProps {
  color?: string
  secondColor?: string
}

export const ContainerForm = styled.div`
  ${(props: Props) => {
    return `
      width: 100%;
      padding: 31px 32px 0px 32px;
      @media ${sizes.sm} {
        margin-bottom: 2rem;
      }
      h2 {
        margin-bottom: 5px;
      }
      &:focus {
        border: solid 1px #2684FF;
      }
    `
  }}
`

export const Hr = styled.hr`
  border: 1px solid #d9d9d9;
  margin-top: 0;
  margin-bottom: 28px;
`

export const H3 = styled.h3`
  font-family: 'Prelo';
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;
  margin: 0.2rem 0 1.5rem;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  /* position: relative; */
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  span {
    margin-top: 0px;
  }
`

export const Span = styled.span`
  margin-bottom: 7px;
`

export const ContainerElements = styled.div`
  margin-bottom: 1rem;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  margin-top: 3px;
  line-height: 2;
`

export const StyledAction = styled.span`
  margin: 0 !important;
  font-weight: bold;
  color: #008900;
`

export const StyledNewChoice = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: #9cd878;
    ${StyledAction} {
      color: #fff;
    }
  }
`
export const StyledNewData = styled.span`
  margin: 0 !important;
  position: relative;
  top: 2px;
`

type PropsSpan = {
  empty?: boolean
}
export const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  span {
    margin-top: 0px;
  }

  .css-dpec0i-option {
    background-color: ${(props: PropsSpan) => {
      return props.empty === true ? '#9cd878 !important' : 'initial'
    }};
    -webkit-tap-highlight-color: ${(props: PropsSpan) =>
      props.empty === true ? 'unset' : 'rgba(0, 0, 0, 0)'};
  }
`

export const AmountsContainer = styled.div`
  display: flex;
  position: fixed;
  background-color: #f1f1f1;
  width: 100%;
  bottom: 0;
  left: 0;
  @media ${sizes.sm} {
    background-color: transparent;
    position: relative;
    justify-content: right;
    margin-left: auto;
    width: 40%;
    height: auto;
    bottom: unset;
    left: unset;
  }
`
export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 100%;
  @media ${sizes.sm} {
    padding: 1rem 0 4px 0;
  }
`

export const TotalContainer = styled.div`
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  font-weight: bold;
  padding: 1rem 0 1rem 1rem;
  @media ${sizes.md} {
    padding: 1rem 16px 4px 16px;
  }
`

export const SpanPayment = styled.span`
  display: flex;
  color: #014178;
  font-weight: bold;
  font-size: 1.5rem;
  &.total {
    font-family: 'PreloSlab';
    font-size: 1rem;
    font-weight: normal;
    color: #0066cc;
  }
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #0066cc;
  &.back {
    color: #ffd000;
  }
  p {
    display: none;
    @media ${sizes.sm} {
      display: block;
    }
    &.mobile {
      display: block;
      @media ${sizes.sm} {
        display: none;
      }
    }
    margin: 0.6rem auto 0;
    text-align: center;
  }
`

export const MobileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: unset;
  right: 1rem;
  font-size: 0.7rem;
  color: #0066cc;
  width: auto;
  &.back {
    right: unset;
    left: 1rem;
    align-items: flex-start;
    color: #ffd000;
  }
  @media ${sizes.sm} {
    display: none;
    position: relative;
    font-size: 1rem;
  }
  p {
    margin: 0.6rem 0 0;
    text-align: center;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  @media ${sizes.sm} {
    width: 58px;
    height: 58px;
  }
  i {
    font-size: 1.5rem;
    @media ${sizes.sm} {
      font-size: 2rem;
    }
  }
`
export const Wrapper = styled.div`
  width: 100%;
`
export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background: #ffffff;
  margin: 1rem 1rem 5%;
  @media ${sizes.md} {
    margin: 1rem 0 0;
  }
`

export const TitleContainer = styled.div`
  font-family: 'PreloSlab';
  position: relative;
  display: flex;
  text-align: left;
  border-radius: 10px 10px 0 0;
  background-color: #f9f9f9;
  padding: 0.5rem 5rem;
  font-weight: 700;
  align-items: center;
  @media ${sizes.md} {
    padding: 2rem;
  }
  .desktop-wrapper {
    display: none;
    @media ${sizes.md} {
      display: flex;
    }
  }

  a {
    color: unset;

    &:visited {
      color: unset;
    }

    &:active {
      color: unset;
    }

    &:hover {
      text-decoration: none;
    }
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-family: 'PreloSlab';
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;
  justify-content: center;
  br {
    @media ${sizes.md} {
      display: none;
    }
  }
  h2 {
    font-family: 'PreloSlab';
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    @media ${sizes.md} {
      margin: auto;
      font-size: 2.1rem;
    }
  }
`

export const StyledRow = styled(Row)`
  margin: 0;
  padding: 0 32px 32px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  p {
    font-weight: normal;
    text-align: center;
    color: #014178;
  }
`

export const Points = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  border: solid 1px #014178;
  background-color: #ffffff;
  padding: 1rem;
  font-family: PreloSlab;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #014178;
  width: 100%;
  max-width: 500px;
  margin: auto;
  span {
    font-size: 0.95rem;
    font-weight: normal;
    text-align: center;
    color: #0066cc;
  }
`

export const RangeWrapper = styled.div<RangeProps>`
  margin: 5rem auto 2rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  @media ${sizes.md} {
    flex-direction: row;
  }
  .range-logo {
    display: none;
    width: 70px;
    min-width: 90px;
    margin-top: 2rem;
    @media ${sizes.md} {
      margin-right: 2rem;
      margin-top: 0;
      width: 130px;
      min-width: 130px;
      display: block;
    }
    &.mobile {
      display: block;
      @media ${sizes.md} {
        display: none;
      }
    }
  }
  align-items: center;
  .input-range__track--active {
    background: ${(props) => (props.color ? props.color : '#ffe200')};
    border-color: ${(props) => (props.color ? props.color : '#ffe200')};
  }
  .input-range__track {
    height: 1.1rem;
  }
  .input-range__slider {
    height: 1.8rem;
    width: 1.8rem;
    border: solid 1px #c5c5c5;
    background-color: #f4f8f9;
    margin-top: -1.45rem;
  }
  .input-range__label--value {
    top: -3.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      left: -40%;
      background: ${(props) => (props.color ? props.color : '#ffe200')};
      color: ${(props) => (props.secondColor ? props.secondColor : '#014178')};
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }

  .input-range__label--min {
    bottom: -2.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      background-color: #f4f8f9;
      color: #014178;
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }

  .input-range__label--max {
    bottom: -2.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      background-color: #014178;
      color: #ffffff;
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }
`

export const RangeNovaWrapper = styled.div`
  margin: 5rem auto 2rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  @media ${sizes.md} {
    flex-direction: row;
  }
  .range-logo {
    display: none;
    width: 90px;
    min-width: 90px;
    margin-right: 2rem;
    margin-top: 2rem;
    @media ${sizes.md} {
      margin-top: 0m;
      width: 130px;
      min-width: 130px;
      display: block;
    }
    &.mobile {
      display: block;
      @media ${sizes.md} {
        display: none;
      }
    }
  }
  align-items: center;
  .input-range__track--active {
    background: #0066cc;
    border-color: #0066cc;
  }
  .input-range__track {
    height: 1.1rem;
  }
  .input-range__slider {
    height: 1.8rem;
    width: 1.8rem;
    border: solid 1px #c5c5c5;
    background-color: #f4f8f9;
    margin-top: -1.45rem;
  }
  .input-range__label--value {
    top: -3.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      left: -40%;
      background-color: #0066cc;
      color: #ffffff;
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }

  .input-range__label--min {
    bottom: -2.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      background-color: #f4f8f9;
      color: #014178;
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }

  .input-range__label--max {
    bottom: -2.4rem;
    font-size: 12px;
    font-weight: bold;
    .input-range__label-container {
      background-color: #014178;
      color: #ffffff;
      border-radius: 2px;
      padding: 0.3rem 1rem;
    }
  }
`
