import React, { useState, useContext } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { Row, Column } from '../../components/Grid'

import { Validate } from '../../helpers/inputValidations'
import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
import { Button, NavButton } from '../../components/Buttons'

import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'

import { FlexCol, ContainerButton, Wrapper } from './style'
import { GET_TOKEN_BY_ID, SEND_PQR_MUTATION } from './query'
import errorIcon from '../../assets/images/error.svg'
import checkIcon from '../../assets/images/ok.svg'

interface IProps {
  handleShowForm: any
  handleClic: any
  client: any
  closeModal?: any
  openContact?: any
}
function DocumentForm({
  handleShowForm,
  client,
  closeModal,
  handleClic,
  openContact
}: IProps) {
  const { forceShowNotification } = useContext(CartContext)
  const { secondary } = useContext(ThemeContext)
  const [idNumberState, setIdNumber] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })
  const [registered, setRegistered] = useState(false)
  const [notallowed, setNotallowed] = useState(false)

  // Validate input data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value)
    switch (id) {
      case 'identificationNumber':
        dataValidated = Validate(value, 'CI', 11)
        setIdNumber(dataValidated)
        break
    }
    if (dataValidated.error) return true
    else return false
  }

  const handleSendDocument = async (identification: string) => {
    await client
      .query({
        query: GET_TOKEN_BY_ID,
        variables: {
          identification: idNumberState.value
        }
      })
      .then((data: any) => {
        if (
          data &&
          data.data &&
          data.data.tokenById &&
          data.data.tokenById.token &&
          data.data.tokenById.token !== '' &&
          data.data.tokenById.token !== null
        ) {
          if (data.data.tokenById.registered) {
            setRegistered(true)
            handleClic()
          } else {
            handleShowForm(data.data.tokenById.token)
          }
        } else {
          setNotallowed(true)
          handleClic()
        }
      })
      .catch((error: any) => {
        console.log(error)
        setNotallowed(true)
        handleClic()
      })
  }

  const handleShowContact = () => {
    closeModal(false)
    openContact && openContact(true)
  }
  const handleValidatePQR = () => {
    validateData(idNumberState.value, 'identificationNumber')

    if (idNumberState.error) {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Ingresa tu identificación'
        })
      return
    }

    handleSendDocument(idNumberState.value)
  }

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleValidatePQR()
    }
  }

  return (
    <>
      <Row>
        {registered ? (
          <Column md={12}>
            <Row>
              <Column md={1}></Column>
              <Column md={10}>
                <Wrapper>
                  <img src={checkIcon} alt="OK" />
                  <p>
                    Ya te encuentras registrado anteriormente en Puntos y
                    Premios. Si no recuerdas tu usuario y contraseña has click
                    en el siguiente botón.
                  </p>
                  <ContainerButton>
                    <NavButton
                      backgroundColor={'#0066cc'}
                      color={secondary}
                      to={'/remember'}
                    >
                      Olvidé mi contraseña
                    </NavButton>
                  </ContainerButton>
                </Wrapper>
              </Column>
            </Row>
          </Column>
        ) : notallowed ? (
          <Column>
            <Row>
              <Column>
                <Wrapper>
                  <img src={errorIcon} alt="X" />
                  <p>
                    No formas parte del programa Puntos y Premios. Si quieres
                    validar tu usuario por favor ingresa tu caso por medio de la
                    opción contáctanos.
                  </p>
                  <ContainerButton>
                    <Button
                      backgroundColor={'#0066cc'}
                      color={secondary}
                      onClick={() => handleShowContact()}
                    >
                      Aceptar
                    </Button>
                  </ContainerButton>
                </Wrapper>
              </Column>
            </Row>
          </Column>
        ) : (
          <Column md={12}>
            <Row className="contact-form">
              <Column lg={12} md={12} sm={12}>
                <FlexCol>
                  <Input
                    onChange={(e: any) => {
                      validateData(e.target.value, 'identificationNumber')
                    }}
                    onKeyDown={(e: any) => {
                      handleEnter(e)
                    }}
                    id="identificationNumber"
                    label="Número de cédula"
                    type="text"
                    required
                    value={idNumberState.value}
                  />
                  {idNumberState.error ? (
                    <Error>{idNumberState.message}</Error>
                  ) : (
                    <Spacer />
                  )}
                </FlexCol>
              </Column>
            </Row>
            <Row>
              <Column lg={6} md={6} sm={12}>
                <ContainerButton>
                  <Button
                    backgroundColor={'#0066cc'}
                    color={secondary}
                    onClick={handleValidatePQR}
                  >
                    Enviar
                  </Button>
                </ContainerButton>
              </Column>
            </Row>
          </Column>
        )}
      </Row>
    </>
  )
}

const DocumentWithMutation = compose(graphql(SEND_PQR_MUTATION))(
  withApollo(DocumentForm)
)
export default DocumentWithMutation
