import React, { useEffect, useState } from 'react'
import {
  ToastWrapper,
  ToastBody,
  ToastContent,
  ToastTitle,
  ToastDescription,
  ToastBtn,
  ToastClose
} from './style'

type Props = {
  loggedin?: string
  loginCollapsed?: boolean
  registerModal?: boolean
  contactModal?: boolean
  hiddenToast?: boolean
}

const Toast = ({
  loggedin = '',
  loginCollapsed = false,
  registerModal = false,
  contactModal = false,
  hiddenToast = false
}: Props) => {
  const [showToast, setShowToast] = useState('hide')

  const handleClose = (e: any) => {
    createCookie('privacy-policy-notice', true);
    setShowToast('hide')
  }

  const createCookie = (name: string, value: any, days?: any) => {
    let expires;

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date['toGMTString']();
    } else {
      expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
  }

  const readCookie = (name: string) => {
    let nameEQ = encodeURIComponent(name) + "=";
    let ca = document.cookie.split(';');
    ca = ca.map(str => str.trim());
    for (let i of ca) {
      let c:any = ca.find(element => element === i);

      if (c.indexOf(nameEQ) === 0)
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

  const classToastDefined = () => {
    const listClass: string[] = [];

    listClass.push(showToast)
    listClass.push(loggedin)
    listClass.push(loginCollapsed ? 'collapsedTranslate' : '')
    listClass.push(registerModal ? 'fixedRegister' : '')
    listClass.push(contactModal ? 'fixedContact' : '')

    return listClass.filter(lc => lc).join(' ')
  }

  useEffect(() => {
    if (readCookie('privacy-policy-notice') === null) {
      setShowToast('showing')
    }

    if (hiddenToast) {
      setShowToast('hide')
    }
  }, [])

  return (
    <ToastWrapper className={classToastDefined()}>
      <ToastBody>
        <ToastContent>
          <ToastTitle>
            Políticas de privacidad
          </ToastTitle>
          <ToastDescription>
            Hemos actualizado nuestro Aviso de Política de Privacidad.
          </ToastDescription>
          <ToastBtn
            href="/docs/aviso_de_la_politica_de_privacidad_pyp_nova_2023.pdf"
            target="_blank"
            rel="noopener"
          >
            Conoce más
          </ToastBtn>
        </ToastContent>
        <ToastClose onClick={(e) => handleClose(e)}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8058 9.7034C20.8058 9.55608 20.6853 9.43555 20.538 9.43555L18.3281 9.44559L15
              13.4132L11.6752 9.44894L9.46207 9.4389C9.31475 9.4389 9.19421 9.55608 9.19421 9.70675C9.19421
              9.77037 9.21765 9.83064 9.25783 9.88086L13.6139 15.0706L9.25783 20.257C9.21737 20.306 9.19492
              20.3675 9.19421 20.4311C9.19421 20.5784 9.31475 20.6989 9.46207 20.6989L11.6752 20.6889L15
              16.7213L18.3248 20.6855L20.5346 20.6956C20.6819 20.6956 20.8025 20.5784 20.8025 20.4277C20.8025
              20.3641 20.779 20.3039 20.7388 20.2536L16.3895 15.0672L20.7455 9.87751C20.7857 9.83064 20.8058
              9.76702 20.8058 9.7034Z" fill="#0F265C"/>
            <path d="M15 0C6.71652 0 0 6.71652 0 15C0 23.2835 6.71652 30 15 30C23.2835 30 30 23.2835 30 15C30
              6.71652 23.2835 0 15 0ZM15 27.4554C8.12277 27.4554 2.54464 21.8772 2.54464 15C2.54464 8.12277
              8.12277 2.54464 15 2.54464C21.8772 2.54464 27.4554 8.12277 27.4554 15C27.4554 21.8772 21.8772
              27.4554 15 27.4554Z" fill="#0F265C"/>
          </svg>
        </ToastClose>
      </ToastBody>
    </ToastWrapper>
  )
}

export default Toast;