import styled from 'styled-components'
import sizes from '../../styles/media'
import { Column } from '../../components/Grid'

export const Banner = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;
  margin-bottom: 2rem;
  img {
    width: 90%;
    margin: auto;
    padding-top: 1rem;
  }
  @media ${sizes.md} {
    img {
      height: 100%;
      padding-top: 1rem;
    }
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: #014178;
  h3 {
    margin: 0;
    font-size: 30px;
    font-weight: normal;
  }
  p {
    margin: 0.5rem 0 0;
    font-size: 1.1rem;
  }
`
export const StyledColumn = styled(Column)`
  display: flex;
  align-items: center;
`
