import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import {
  ButtonContainer,
  NavButtonContainer,
  StyledNavIconButton,
  StyledIconButton,
  Title
} from './style'
import Icon from '../Icon'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  backgroundColor?: string
  bordercolor?: string
  color: string
  sizeicon?: string
  width?: string
  hoverColor?: string
  padding?: string
  disabled?: boolean
}

export const Button = ({ ref, as, ...props }: ButtonProps) => {
  const {
    children,
    backgroundColor,
    bordercolor,
    color,
    width,
    hoverColor,
    padding,
    disabled
  } = props
  return (
    <ButtonContainer
      {...props}
      backgroundcolor={backgroundColor}
      bordercolor={bordercolor}
      color={color}
      width={width}
      hoverColor={hoverColor}
      padding={padding}
      disabled={disabled}
    >
      <Title>{children}</Title>
    </ButtonContainer>
  )
}

interface NavButtonProps extends RouteComponentProps<any> {
  to: string
  children: React.ReactNode
  backgroundColor: string
  color: string
  bordercolor?: string
  onClick?: () => void
  id?: string
  padding?: string
  className?: string
}

export const NavButton = withRouter(({ ...props }: NavButtonProps) => {
  const {
    to,
    children,
    backgroundColor,
    color,
    bordercolor,
    padding,
    onClick,
    id,
    className
  } = props

  return (
    <NavButtonContainer
      to={to}
      backgroundcolor={backgroundColor}
      color={color}
      bordercolor={bordercolor}
      onClick={onClick}
      id={id}
      className={className}
      padding={padding}
    >
      {children}
    </NavButtonContainer>
  )
})

export const NavIconButton = withRouter(({ to, icon, ...props }: any) => {
  let ref = '#'

  if (to) {
    ref = to
  }

  return (
    <StyledNavIconButton {...props} to={ref}>
      {icon && <Icon name={icon} />}
    </StyledNavIconButton>
  )
})

export const IconButton = ({ ref, icon, ...props }: any) => (
  <StyledIconButton {...props}>{icon && <Icon name={icon} />}</StyledIconButton>
)
