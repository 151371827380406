import React, { useState, useContext } from 'react'
import { Query, QueryResult } from 'react-apollo'
import Loader from '../../components/loader'
import Collapsible from 'react-collapsible'
import Icon from '../../components/Icon'
import Transaction from '../../model/Transaction'
import CartContext from '../../context/CartContext'
import formatNumber from '../../helpers/formatNumber'
import {
  MobileWrapper,
  Wrapper,
  StyledColumn,
  Header,
  Content,
  Title,
  ItemWrapper,
  Tr,
  Th,
  Td,
  Tfooter,
  Table,
  Circle,
  Detalle,
  Sort,
  SortWrapper
} from './style'
//import PointsData from './data'
import { GET_TRANSACTIONS_QUERY } from './query'
interface Filter {
  s: String
  member: String
}

interface Options {
  limit: Number
  offset: Number
  sortBy: String
}
type DataTransactions = {
  transactions: {
    nodes: Transaction[]
    pagination: {
      total: number
      limit: number
      offset: number
    }
  }
}
interface Variables {
  filters: Filter
  options: Options
}

type Props = {}

function Points(props: Props) {
  const { account } = useContext(CartContext)
  //const account = JSON.parse(window.localStorage.getItem('account') || '{}')
  const [sort, setSort] = useState(false)
  //const { handleSetCategory, selectedCategory } = props;
  return (
    <Query<DataTransactions, Variables>
      variables={{
        filters: {
          s: '',
          member: account.id
        },
        options: {
          limit: 20,
          offset: 0,
          sortBy: ''
        }
      }}
      query={GET_TRANSACTIONS_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<DataTransactions, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p></p>
        }

        if (!data) {
          return <p>No tienes transacciones</p>
        }
        const { nodes } = data.transactions
        const transactions = nodes
          .filter((item: Transaction) => {
            return item.status && item.status === 'approved'
          })
          .filter((item: Transaction, index: number) => {
            return index < 10
          })
          .sort(function (a: Transaction, b: Transaction) {
            if (sort) {
              if (a.createdAt > b.createdAt) {
                return 1
              }
              if (a.createdAt < b.createdAt) {
                return -1
              }
              return 0
            } else {
              if (a.createdAt < b.createdAt) {
                return 1
              }
              if (a.createdAt > b.createdAt) {
                return -1
              }
              return 0
            }
          })

        return (
          <>
            <MobileWrapper>
              {transactions.length === 0 && <p>No tienes transacciones</p>}
              {transactions.map((item: Transaction) => {
                let date = new Date(item.createdAt)
                return (
                  <ItemWrapper>
                    <Collapsible
                      trigger={
                        <Header>
                          <Title>{date.toLocaleDateString()}</Title>
                          <>
                            <Icon
                              color="#0066cc"
                              size="20px"
                              name="abajo"
                              className="flex-icon"
                            />
                          </>
                        </Header>
                      }
                      triggerWhenOpen={
                        <Header className="opened">
                          <Title>{date.toLocaleDateString()}</Title>
                          <>
                            <Icon
                              color="#0066cc"
                              size="20px"
                              name="arriba"
                              className="flex-icon"
                            />
                          </>
                        </Header>
                      }
                    >
                      <Content>
                        <Tr>
                          <Th>Descripción</Th>
                          <Td>
                            {item.transactionType && item.transactionType.name}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Puntos</Th>
                          {item.transactionType &&
                          item.transactionType.type === 'debit' ? (
                            <Td className="red">
                              -{formatNumber(item.amount)}
                            </Td>
                          ) : (
                            <Td className="green">
                              {formatNumber(item.amount)}
                            </Td>
                          )}
                        </Tr>
                        <Tr>
                          <Th>Saldo</Th>
                          <Td>{formatNumber(item.totalBalance)}</Td>
                        </Tr>
                        <Tfooter>
                          <Tr>
                            <Th>Detalle</Th>
                            <Td>
                              <Detalle>
                                {item.details &&
                                  item.details.purchases &&
                                  item.details.purchases.length > 0 && (
                                    <div className="detalle bordered">
                                      <div>
                                        <ul>
                                          {item.details.purchases.map(
                                            (purchase) => {
                                              return purchase.variation ? (
                                                <li>
                                                  {
                                                    purchase.variation.product
                                                      .name
                                                  }
                                                </li>
                                              ) : (
                                                <></>
                                              )
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                {item.copaymentCurrency &&
                                  item.copaymentCurrency.length &&
                                  item.copaymentCurrency.map(
                                    (currency: any, subindex: number) => {
                                      return (
                                        <div
                                          key={subindex}
                                          className={
                                            subindex !==
                                            item.copaymentCurrency.length - 1
                                              ? 'detalle bordered'
                                              : 'detalle'
                                          }
                                        >
                                          <div>{currency.currency_name}</div>
                                          {item.transactionType &&
                                          item.transactionType.type ===
                                            'debit' ? (
                                            <div>
                                              -{formatNumber(currency.amount)}
                                            </div>
                                          ) : (
                                            <div>
                                              {formatNumber(currency.amount)}
                                            </div>
                                          )}
                                        </div>
                                      )
                                    }
                                  )}
                                {item.transactionType &&
                                  item.transactionType.name ===
                                    'Acreditación' && (
                                    <div className={'detalle'}>
                                      <div>
                                        {item.details && item.details.promoName}
                                      </div>
                                      {item.transactionType &&
                                      item.transactionType.type === 'debit' ? (
                                        <div>-{formatNumber(item.amount)}</div>
                                      ) : (
                                        <div>{formatNumber(item.amount)}</div>
                                      )}
                                    </div>
                                  )}
                              </Detalle>
                            </Td>
                          </Tr>
                        </Tfooter>
                      </Content>
                    </Collapsible>
                  </ItemWrapper>
                )
              })}
            </MobileWrapper>
            <Wrapper>
              <Table>
                <Tr>
                  <StyledColumn md={2}>
                    <Th>
                      <SortWrapper onClick={() => setSort(!sort)}>
                        Fecha
                        <Sort>
                          <i className="flex-icon icon-arriba"></i>
                          <i className="flex-icon icon-abajo"></i>
                        </Sort>
                      </SortWrapper>
                    </Th>
                  </StyledColumn>
                  <StyledColumn md={4}>
                    <Th>Descripción</Th>
                  </StyledColumn>
                  <StyledColumn md={2}>
                    <Th>Puntos</Th>
                  </StyledColumn>
                  <StyledColumn md={2}>
                    <Th>Saldo</Th>
                  </StyledColumn>
                  <StyledColumn md={1}></StyledColumn>
                  <StyledColumn md={1}>
                    <Th>Detalle</Th>
                  </StyledColumn>
                </Tr>

                {transactions.length === 0 && (
                  <StyledColumn>No tienes transacciones</StyledColumn>
                )}
                {transactions.map((item: Transaction) => {
                  const date = new Date(item.createdAt)
                  return (
                    <Collapsible
                      trigger={
                        <>
                          <Tr>
                            <StyledColumn md={2}>
                              {date.toLocaleDateString()}
                            </StyledColumn>
                            <StyledColumn md={4}>
                              {item.transactionType
                                ? item.transactionType.name
                                : item.details &&
                                  item.details.purchases &&
                                  item.details.purchases.length > 0 &&
                                  item.details.purchases[0].variation
                                ? `Canje ${item.details.purchases[0].variation.product.name}`
                                : ''}
                            </StyledColumn>
                            {item.transactionType &&
                            item.transactionType.type === 'debit' ? (
                              <StyledColumn className="red" md={2}>
                                -{formatNumber(item.amount)}
                              </StyledColumn>
                            ) : (
                              <StyledColumn className="green" md={2}>
                                {formatNumber(item.amount)}
                              </StyledColumn>
                            )}
                            <StyledColumn md={2}>
                              {formatNumber(item.totalBalance)}
                            </StyledColumn>
                            <StyledColumn md={2} className="right">
                              <Circle>
                                <Icon
                                  color="#0066cc"
                                  size="2rem"
                                  name="mas"
                                  className="flex-icon"
                                />
                              </Circle>
                            </StyledColumn>
                          </Tr>
                        </>
                      }
                      triggerWhenOpen={
                        <>
                          <Tr>
                            <StyledColumn md={2}>
                              {date.toLocaleDateString()}
                            </StyledColumn>
                            <StyledColumn md={4}>
                              {item.transactionType
                                ? item.transactionType.name
                                : item.details &&
                                  item.details.purchases &&
                                  item.details.purchases.length > 0 &&
                                  item.details.purchases[0].variation
                                ? `Canje ${item.details.purchases[0].variation.product.name}`
                                : ''}
                            </StyledColumn>

                            {item.transactionType &&
                            item.transactionType.type === 'debit' ? (
                              <StyledColumn className="red" md={2}>
                                -{formatNumber(item.amount)}
                              </StyledColumn>
                            ) : (
                              <StyledColumn className="green" md={2}>
                                {formatNumber(item.amount)}
                              </StyledColumn>
                            )}
                            <StyledColumn md={2}>
                              {formatNumber(item.totalBalance)}
                            </StyledColumn>
                            <StyledColumn md={2} className="right">
                              <Circle className="opened">
                                <Icon
                                  color="#f4f8f9"
                                  size="2rem"
                                  name="menos"
                                  className="flex-icon"
                                />
                              </Circle>
                            </StyledColumn>
                          </Tr>
                        </>
                      }
                    >
                      <Detalle>
                        {item.details &&
                          item.details.purchases &&
                          item.details.purchases.length > 0 && (
                            <div className="detalle bordered">
                              <StyledColumn md={2}></StyledColumn>
                              <StyledColumn md={4}>
                                <ul>
                                  {item.details.purchases.map((purchase) => {
                                    return purchase.variation ? (
                                      <li>{purchase.variation.product.name}</li>
                                    ) : (
                                      <></>
                                    )
                                  })}
                                </ul>
                              </StyledColumn>
                            </div>
                          )}
                        {item.copaymentCurrency &&
                          item.copaymentCurrency.length &&
                          item.copaymentCurrency.map(
                            (currency: any, subindex: number) => {
                              return (
                                <div
                                  key={subindex}
                                  className={
                                    subindex !==
                                    item.copaymentCurrency.length - 1
                                      ? 'detalle bordered'
                                      : 'detalle'
                                  }
                                >
                                  <StyledColumn md={2}></StyledColumn>
                                  <StyledColumn md={4}>
                                    {currency.currency_name}
                                  </StyledColumn>
                                  {item.transactionType &&
                                  item.transactionType.type === 'debit' ? (
                                    <StyledColumn className="credit" md={2}>
                                      -{formatNumber(currency.amount)}
                                    </StyledColumn>
                                  ) : (
                                    <StyledColumn className="credit" md={2}>
                                      {formatNumber(currency.amount)}
                                    </StyledColumn>
                                  )}

                                  <StyledColumn md={2}>
                                    {/* {formatNumber(item.totalBalance)} */}
                                  </StyledColumn>
                                </div>
                              )
                            }
                          )}
                        {item.transactionType &&
                          item.transactionType.name === 'Acreditación' && (
                            <div className={'detalle'}>
                              <StyledColumn md={2}></StyledColumn>
                              <StyledColumn md={4}>
                                {item.details && item.details.promoName}
                              </StyledColumn>
                              {item.transactionType &&
                              item.transactionType.type === 'debit' ? (
                                <StyledColumn className="credit" md={2}>
                                  -{formatNumber(item.amount)}
                                </StyledColumn>
                              ) : (
                                <StyledColumn className="credit" md={2}>
                                  {formatNumber(item.amount)}
                                </StyledColumn>
                              )}

                              <StyledColumn md={2}>
                                {formatNumber(item.totalBalance)}
                              </StyledColumn>
                            </div>
                          )}
                      </Detalle>
                    </Collapsible>
                  )
                })}
              </Table>
            </Wrapper>
          </>
        )
      }}
    </Query>
  )
}
export default Points
