import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { Row, Column } from '../../components/Grid'
import Modal from 'react-modal'
interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const Wrapper = styled.div`
  width: 100%;
`
export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background: #ffffff;
  margin: 1rem 1rem 10%;
  @media ${sizes.md} {
    margin: 1rem 0 0;
  }
`

export const TitleContainer = styled.div`
  font-family: 'PreloSlab';
  position: relative;
  display: flex;
  color: #0066cc;
  text-align: left;
  border-radius: 10px 10px 0 0;
  background-color: #f9f9f9;
  padding: 0.5rem 5rem;
  font-weight: 700;
  align-items: center;
  @media ${sizes.md} {
    padding: 2rem;
  }
  .desktop-wrapper {
    display: none;
    @media ${sizes.md} {
      display: flex;
    }
  }

  a {
    color: unset;

    &:visited {
      color: unset;
    }

    &:active {
      color: unset;
    }

    &:hover {
      text-decoration: none;
    }
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-family: 'PreloSlab';
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;
  justify-content: center;
  br {
    @media ${sizes.md} {
      display: none;
    }
  }
  h2 {
    font-family: 'PreloSlab';
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    @media ${sizes.md} {
      margin: auto;
      font-size: 2.1rem;
    }
  }
`

export const Bluelabel = styled.div`
  font-size: 0.85rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #014178;
  margin-top: 1rem;
`

export const Name = styled.div`
  font-family: 'PreloSlab';
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.2rem 0;
  height: auto;
  width: 100%;
  color: #014178;
  overflow-wrap: break-word;

  @media ${sizes.md} {
    font-size: 1.4rem;
    margin: 0 0 1.5rem;
  }
`

export const Reference = styled.div`
  font-size: 12px;
  margin: 0.2rem;
  @media ${sizes.lg} {
    font-size: 14px;
    margin: 0;
  }
`

export const IconTitle = styled.div`
  font-weight: bold;
  margin: 0 4px;
  @media ${sizes.xl} {
    margin: 0 4px 0 0;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  @media ${sizes.sm} {
    padding: 2rem 0;
    height: 100%;
    flex-direction: row;
  }
`

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 1.05rem;
  padding-bottom: 1rem;
  width: 100%;
  @media ${sizes.sm} {
    padding-bottom: 0;
  }
  i {
    margin-right: 4px;
  }
  .summary {
    font-size: 0.8rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
  }
`

export const ItemStock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 1rem 1rem;
  @media ${sizes.md} {
    margin-left: 1rem;
  }
  @media ${sizes.xl} {
    padding: 0;
    margin-left: 0;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  border: solid 1px #c5c5c5;
  background-color: #ffffff;
  width: 92%;
  flex-direction: column;
  justify-content: center;
  @media ${sizes.md} {
    display: block;
    width: 225px;
    margin-right: 1rem;
  }
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
`

export const Stock = styled.span`
  color: red;
`

export const Quantity = styled.div`
  border: solid 1px #e9eff4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  border-radius: 4px 0 0 4px;
  width: 64px;
  height: 100%;
  color: #0066cc;
`

export const RightQuantity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
    background-color: rgba(244, 247, 249, 0.4);
    color: ${props.fontcolor};
    border: solid 1px ${props.backgroundcolor};
    outline: none;
    cursor: pointer;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    min-height: 23px;
    border-radius: 0 4px 4px 0;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 46px;
  margin-bottom: 2rem;
  width: 100%;
  @media ${sizes.md} {
    margin-bottom: 0;
    width: auto;
  }
`

export const Line = styled.div`
  margin: auto;
  border-bottom: solid 1px #eeeeee;
  width: 100%;
`

export const ActionsCartContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
`

export const Clean = styled.div`
  background: none;
  cursor: pointer;
  color: #1428a0;
  text-decoration: underline;
  font-size: 12px;
`

export const Continue = styled(Link)`
  color: #1428a0;
  font-size: 12px;
  text-decoration: underline;
`

export const DetailPayment = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
    `
  }}
`
export const DetailPaymentMobile = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
      margin-left:auto;
      width:60%;
    `
  }}
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const SpanPayment = styled.span`
  display: flex;
  color: #014178;
  font-weight: bold;
  font-size: 1.5rem;
  &.total {
    font-family: 'PreloSlab';
    font-size: 1rem;
    font-weight: normal;
    color: #0066cc;
  }
`

export const SpanShipping = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-weight: bold;
      margin-bottom:1rem;
      float:right;
      padding-right: 1rem;
      margin-top: 1rem;
      font-size: 12px;
      @media ${sizes.md} {
        font-size: 14px;
      }
    `
  }}
`

export const TotalContainer = styled.div`
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  font-weight: bold;
  padding: 1rem 0 1rem 1rem;
  @media ${sizes.md} {
    padding: 1rem 16px 4px 16px;
  }
`

export const TotalContainerMobile = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    padding: 0rem 16px 4px 16px;
    margin-left:auto;
    width:60%;
  `
  }}
`

export const AmountsContainer = styled.div`
  display: flex;
  position: fixed;
  background-color: #f1f1f1;
  width: 100%;
  bottom: 0;
  left: 0;
  @media ${sizes.sm} {
    background-color: transparent;
    position: relative;
    justify-content: right;
    margin-left: auto;
    width: 40%;
    height: auto;
    bottom: unset;
    left: unset;
  }
`

export const AmountsContainerMobile = styled.div`
  justify-content: right;
  margin-left: auto;
  margin-top: 0.5rem;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 100%;
  @media ${sizes.sm} {
    padding: 1rem 0 4px 0;
  }
`
export const ButtonContainerMobile = styled.div`
  margin-left: auto;
  padding: 1rem 16px 4px 16px;
  width: 100%;
`
export const ButtonMobile = styled.div`
  margin-left: auto;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`

export const ListContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  position: relative;
  width: 100%;
`

export const ContainerMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  background: #ffffff;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`

export const RowContainer = styled.div`
  display: inline-flex;
  height: 100%;
`

export const PriceContainer = styled.div`
  padding-left: 0.5rem;
  font-weight: bold;
  margin: auto 0;
`

export const ContainerItem = styled.div`
  width: 100%;
`

export const LinkProduct = styled(Link)`
  color: black;
`

export const InfoPromoContainer = styled.div`
  background: #e3eed6;
  border: solid 1px #d2e4bd;
  display: flex;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 1rem 0;
`

export const CustomIcon = styled.img`
  margin-right: 1rem;
  width: 6rem;
  max-width: 100%;
`

export const InfoText = styled.h4`
  font-weight: 400;
  margin: 0;
  font-size: 0.9rem;
`

interface ModalProps {
  backgroundcolor?: string
  padding: string
}

export const ModalDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0 0 20px 0;

    .amount-modal {
      font-weight: bold;
      color: #112340;
    }
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: #ffffff;
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }

  @media ${sizes.sm} {
    .description {
      margin: 0px 30px 30px 30px;
    }
  }
`

export const AmountModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  /* padding: 30px; */
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  p {
    display: none;
    @media ${sizes.sm} {
      display: block;
    }
    &.mobile {
      display: block;
      @media ${sizes.sm} {
        display: none;
      }
    }
    margin: 0.6rem auto 0;
    text-align: center;
  }
`

export const MobileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 1rem;
  font-size: 0.7rem;
  @media ${sizes.sm} {
    display: none;
    position: relative;
    font-size: 1rem;
  }
  p {
    margin: 0.6rem 0 0;
    text-align: center;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  @media ${sizes.sm} {
    width: 58px;
    height: 58px;
  }
  i {
    font-size: 1.5rem;
    @media ${sizes.sm} {
      font-size: 2rem;
    }
  }
`

export const MarginRow = styled(Row)`
  @media ${sizes.md} {
    width: 94%;
    margin: auto;
    .description {
      margin: 0px 30px 30px 30px;
    }
  }
  .desktop_wrapper {
    display: none;
    @media ${sizes.md} {
      display: flex;
    }
  }
`

export const StyledRow = styled(Row)`
  margin: 0;
`

export const StyledColumn = styled(Column)`
  padding: 0;
`

export const MobileProduct = styled.div`
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem 1rem;
`
export const MobileQuantity = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    width: 100%;
    color: #494949;
    font-size: 0.9rem;
    font-weight: 700;
  }
  @media ${sizes.md} {
    display: none;
  }
`
