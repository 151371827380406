import styled from 'styled-components'
//import sizes from '../../styles/media'

export const Banner = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;
  margin-bottom: 2rem;
  img {
    height: 100%;
    padding-top: 1rem;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #333333;
  font-size: 1.1rem;
  margin: 2.2rem 0 2rem;
`
