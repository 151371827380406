import styled from 'styled-components'
import sizes from '../../styles/media'
//import Select from 'react-select'

export const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${sizes.sm} {
    justify-content: space-between;
  }
`

export const Button = styled.button`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0066cc;
  padding: 0.5rem 0.75rem;
  background-color: transparent;
  border: 0px;
  border: none;
  cursor: pointer;
  outline: none;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: inherit;
    color: #c5c5c5;
  }
  &.current {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: #ffffff;
  }
`
export const NavContainer = styled.div`
  display: flex;
`
