import React from 'react'
//import { Row } from '../Grid'

import { Wrapper, Button, NavContainer } from './style'

type PaginaionProps = {
  limit: number
  offset: number
  page: number
  pages: number
}

type Props = {
  pagination: PaginaionProps
  setPage: any
}

function Pagination({ pagination, setPage }: Props) {
  const { pages, page, limit } = pagination
  const handleClic = (type: string) => {
    let to = 0
    switch (type) {
      case 'next':
        to = page * limit
        break
      case 'prev':
        to = (page - 2) * limit
        break

      case 'last':
        to = (pages - 1) * limit
        break

      case 'first':
        to = 0
        break

      default:
        break
    }
    setPage((prevState: any) => ({
      ...prevState,
      offset: to
    }))
  }
  return (
    <>
      <Wrapper>
        <NavContainer>
          <Button onClick={() => handleClic('first')}>{`<<`}</Button>
          {page > 1 && (
            <>
              <Button onClick={() => handleClic('prev')}>{`<`}</Button>
              <Button disabled>{`...`}</Button>
              <Button disabled>{page - 1}</Button>
            </>
          )}
          <Button className={'current'}>{page}</Button>
          {page < pages && (
            <>
              <Button disabled>{page + 1}</Button>
              <Button disabled>{`...`}</Button>
              <Button onClick={() => handleClic('next')}>{`>`}</Button>
            </>
          )}
          <Button onClick={() => handleClic('last')}>{`>>`}</Button>
        </NavContainer>
      </Wrapper>
    </>
  )
}

export default Pagination
