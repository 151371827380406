import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { Column } from '../Grid'
import Select from 'react-select'

export const StyledColumn = styled(Column)`
  display: flex;

  /* &.show-desktop {
    display: none;

    @media ${sizes.xl} {
      display: flex;
    }
  } */
`
export const Pe = styled.p`
  font-size: 16px;
  color: #333333;
  text-align: center;
`
export const DesktopColumn = styled(Column)`
  display: flex;

  @media ${sizes.sm} {
    display: none;
  }
`

export const StyledRightColumn = styled(Column)`
  display: flex;
  justify-content: flex-start;

  @media only screen and (min-width: 1025px) {
    & {
      display: flex;
    }
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
`

export const CustomOption = styled.div`
  display: flex;
  height: 50px;
  align-items: center;

  .label {
    margin-left: 20px;
    font-size: 1.1rem;
  }

  img {
    width: 30px;
    margin-left: 15px;
  }
`

export const FlexCol = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  label,
  span {
    margin-bottom: 7px;
    width: 30%;
    font-weight: bold;
    text-align: center;
  }
  span {
    margin-right: 10px;
    margin-top: 0px;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  padding: 1.5rem;
  border: 2px solid;
  border-color: transparent;
  background-color: white;
  margin: 1rem 0;
  width: 100%;
  flex: 1;
  &:hover {
    border-color: #1428a0;
    box-shadow: 0 4px 8px 0 rgba(117, 120, 123, 0.6);
    cursor: pointer;
  }
`

export const ImgContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const ProductInfoContainer = styled.div`
  display: block;
  margin-bottom: 0.7rem;
  max-height: 8rem;
  overflow: hidden;
`

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.7rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
`

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`

export const LinkResponsive = styled(Link)`
  color: black;
`

export const PriceContainer = styled.div`
  display: flex-end;
`

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 18px;

  &.ipad {
    display: block;
  }

  &.desktop {
    display: none;
  }

  @media ${sizes.xl} {
    & {
      font-size: 24px;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    &.ipad {
      display: none;
    }

    &.desktop {
      display: block;
    }
  }
`
