import styled from 'styled-components'
import sizes from '../../styles/media'

interface ImageProps {
  status: string
}

export const StyledImage = styled.img`
  display: ${(props: ImageProps) =>
    props.status === 'loaded' ? 'flex' : 'none'};
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 10px 10px 0 0;
  @media ${sizes.sm} {
    max-width: inherit;
    height: 100%;
  }
`

export const StyledLoading = styled.img`
  display: ${(props: ImageProps) =>
    props.status !== 'loaded' ? 'flex' : 'none'};
  margin: auto;
  max-width: 100%;
  padding: 27.5% 3.5rem;
  height: 180px;
  max-height: 180px;
  transition: max-height 1s ease;
  border-radius: 10px 10px 0 0;
  @media ${sizes.xxl} {
    height: auto;
    min-height: 164px;
  }
`

export const StyledContainer = styled.div`
  height: 180px;
  max-height: 180px;
  min-height: 180px;
  @media ${sizes.md} {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
`
