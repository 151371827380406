import gql from 'graphql-tag'

const GET_CATEGORIES_QUERY = gql`
  query getCategoriesQuery(
    $filters: CategoriesFilters!
    $options: CategoriesOptions!
  ) {
    categories(filters: $filters, options: $options) {
      nodes {
        id
        name
        slug
      }
    }
  }
`

export { GET_CATEGORIES_QUERY }
