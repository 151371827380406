import gql from 'graphql-tag'

const GET_VARIATIONS_QUERY = gql`
  query variationsByProductQuery($productId: String!) {
    variationsByProduct(productId: $productId) {
      nodes {
        id
        price
        regularPrice
        reference
        status
        pointCost
        inventory {
          localStock
          storageStock
        }
        assets {
          url
          order
        }
        options {
          id
          name
          feature {
            id
            name
          }
        }
      }
      pagination {
        limit
        offset
        total
      }
    }
  }
`

export { GET_VARIATIONS_QUERY }
