

export default function formatNumber(val: number) {
  const value = val.toString();
  let newValue = `${value.substring(0, value.length - 3)}.${value.substring(value.length - 3, value.length)}`
  if(value.length< 4){
    newValue = value
  }
  return newValue;
}

// export default function formatNumber(n: number) {
  /*const numberFormat = new Intl.NumberFormat('en-En', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
  const splitedNumber = numberFormat.formatToParts(n).map((item: any) => {
    return item.type === 'group' ? `.` : item.value
  })
  return splitedNumber.join('')*/
// }
