import styled from 'styled-components'
import Modal from 'react-modal'
import sizes from '../../styles/media'

interface ModalProps {
  backgroundcolor?: string
  padding: string
}

export const ModalWrapper = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  background: rgb(255, 255, 255);
  outline: none;
  max-width: 550px;
  width: 100%;
  text-align: left;
  z-index: 100;
  height: auto;
  transform: translate(-50%, -50%);

  @media ${sizes.sm} {
    height: auto;
    top: 50%;
  }
`
export const ModalDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;
  border-radius: 0 0 10px 10px;
  &.header {
    border-radius: 10px 10px 0 0;
  }

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0;
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: #ffffff;
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }
`

export const FeatureErrors = styled.span`
  color: red;
  font-size: 11px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.md} {
    margin-top: 0;
    width: 100%;
  }
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  text-align: left;
  h1 {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #014178;
    margin-top: 0;
  }
`

export const TimesBtn = styled.div`
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c5c5c5;
  &:hover {
    cursor: pointer;
  }
`
