import styled from 'styled-components'
import sizes from '../../styles/media'

export const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 0.8rem;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;

  @media ${sizes.md} {
    padding: 2rem;
  }
`
export const Title = styled.div`
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  h1 {
    color: #014178;
    font-size: 1.8rem;
    font-weight: normal;
    margin: 0 0 1.2rem;
  }
  p {
    margin: 0 0 2rem;
  }
`
