import React, { useContext, useState, useEffect } from 'react'
import Collapsible from 'react-collapsible'
import gql from 'graphql-tag'
import { graphql, withApollo, compose } from 'react-apollo'

import Checkout from '../../components/Checkout'
import * as Grid from '../../components/Grid'
import { NavButton, Button } from '../../components/Buttons'
import ProductSummary from '../../components/Checkout/components/ProductSummary'
import TitleSummary from '../../components/Checkout/components/TitleSummary'
import DateDelivery from '../../components/Checkout/components/DateDelivery'
import Icon from '../../components/Icon'
import p2pImage from '../../assets/p2p/p2p.png'
//import franchiseImg from "../../assets/p2p/franchiseImage.png";
import { ContainerElements } from '../../components/Global/style'
import Loader from '../../components/loader'

import { useIsMobile, useIsIpad } from '../../helpers/resize'
import TextDelivery from '../../helpers/dateDelivery'
import ResumeData from '../../helpers/resumeData'

import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import PaymentResponseContext from '../../context/PaymentResponseContext'
import LoaderContext from '../../context/LoaderContext'
import Customer from '../../model/Customer'
import Address from '../../model/Address'

import {
  PaymentContainer,
  Card,
  HeaderCard,
  LinkResponsive,
  BodyCard,
  TitleCard,
  TitleBody,
  TermsContainer,
  Checkbox,
  ContainerTransaction,
  ContainerLink
} from './style'
import TitleSummaryResponsive from '../../components/Checkout/components/TitleSummaryResponsive'

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $paymentResponse: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      paymentResponse: $paymentResponse
      docUpdated: $docUpdated
    ) {
      token
    }
  }
`

const GENERATE_ORDER_MUTATION = gql`
  mutation generateOrder($input: CreateOrderInput!) {
    generateOrder(input: $input) {
      id
      status
    }
  }
`

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
    }
  }
`

const GET_CHECK_REQUEST = gql`
  query getP2PStatus($requestId: String!) {
    P2PStatus(requestId: $requestId) {
      payment {
        status {
          status
        }
      }
    }
  }
`

const GENEREATE_REQUEST_PAYMENT = gql`
  mutation requestPayment($input: RequestOrderInput!) {
    requestPayment(input: $input) {
      processUrl
    }
  }
`

let timer: number = 0
declare const P: any

function Payment({ history, mutate, client }: any) {
  let { setResponse } = useContext(PaymentResponseContext)
  const {
    onSetLoaded,
    loaded,
    updateItems,
    onDelAllItems,
    forceShowNotification
  } = useContext(CartContext)
  const { setLoading } = useContext(LoaderContext)
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)

  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`)
  const isIpad = useIsIpad(initialMqlIpad.matches)

  const { primary, secondary } = useContext(ThemeContext)
  const [termsState, setTerms] = useState(false)

  const {
    cityName,
    city,
    province,
    provinceName,
    zoneName,
    street1,
    street2,
    number,
    zone,
    reference,
    firstName,
    lastName,
    phone,
    email
  }: Address = JSON.parse(window.localStorage.getItem('address') || '{}')

  const {
    identificationType,
    identificationTypeName,
    identificationNumber,
    firstName: firstNameCustomer,
    lastName: lastNameCustomer,
    businessName: businessNameCustomer,
    mobile: mobileCustomer,
    address: addressCustomer,
    email: emailCustomer
  }: Customer = JSON.parse(window.localStorage.getItem('customer') || '{}')

  const itemsCart = JSON.parse(window.localStorage.getItem('items') || '{}')

  const handleClickOnNext = (
    phoneNumber: any,
    email: any,
    document: any,
    paymentResponse: string
  ) => {
    var token = window.localStorage.getItem('token') || ''
    mutate({
      mutation: ADD_TRACKING_MUTATION,
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: 'PAYMENT_EVENT',
        eventType: 'onClick',
        email: email,
        phoneNumber: phoneNumber,
        document: document,
        paymentResponse: paymentResponse,
        docUpdated: 1
      }
    })
  }

  const generateOrder = async () => {
    if (termsState == true) {
      setLoading && setLoading({ loading: true })

      const items: any[] = []
      itemsCart.forEach((item: any) => {
        for (let i = 0; i < item.quantity; i++) {
          items.push({ variationId: item.id })
        }
      })

      const order = {
        address: {
          state: province,
          city: city,
          zone: zone,
          reference: reference,
          street1: street1,
          street2: street2,
          number: number,
          email: email,
          customerName: `${firstName} ${lastName}`,
          phone: phone
        },
        customer: {
          firstName: firstNameCustomer,
          lastName: lastNameCustomer,
          identificationNumber: identificationNumber,
          identificationType: identificationType,
          mobile: mobileCustomer,
          email: emailCustomer,
          address: addressCustomer,
          businessName: businessNameCustomer
        },
        lineItems: items
      }
      const orderResult = await mutate({
        mutation: GENERATE_ORDER_MUTATION,
        variables: {
          input: order
        }
      })
      // setOrderId(order_id.data.generateOrder.id);
      window.localStorage.setItem('orderId', orderResult.data.generateOrder.id)

      timer = window.setInterval(
        () => getOrderStatus(orderResult.data.generateOrder.id),
        2000
      )
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Para avanzar, debe aceptar los terminos y condiciones'
        })
    }
  }

  const getOrderStatus = async (order_id: string) => {
    const { data, loading } = await client.query({
      query: GET_ORDER_STATUS,
      variables: {
        orderId: order_id
      },
      fetchPolicy: 'network-only'
    })

    if (loading) {
    }

    status = data.OrderStatus.status
    if (status == 'approved') {
      window.clearInterval(timer)
      generatePaymentRequest(order_id)
    }
  }

  const generatePaymentRequest = async (order_id: string) => {
    const order = {
      orderId: order_id
    }
    const p2pLink = await mutate({
      mutation: GENEREATE_REQUEST_PAYMENT,
      variables: {
        input: order
      }
    })
    window.localStorage.setItem(
      'paymentURL',
      p2pLink.data.requestPayment.processUrl
    )
    setLoading && setLoading({ loading: false })
    P.init(p2pLink.data.requestPayment.processUrl)
  }

  const getCheckRequest = async (
    status: string,
    requestId: string,
    signature: string,
    reference: string
  ) => {
    let response = {
      title: '',
      message: '',
      body: [''],
      image: '',
      analyticId: '',
      requestId: '',
      signature: '',
      status: '',
      orderId: '',
      orderStatus: '',
      reference: '',
      emailCustomer: ''
    }

    const { data, loading, error } = await client.query({
      query: GET_CHECK_REQUEST,
      variables: {
        requestId: requestId
      },
      fetchPolicy: 'network-only'
    })

    if (loading) {
      // console.log("esta cargando");
    }

    if (error) {
      setLoading && setLoading({ loading: false })
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'ERROR, INTENTELO DE NUEVO.'
        })
    }
    const payment = data.P2PStatus.payment
    setLoading && setLoading({ loading: false })
    const orderId = window.localStorage.getItem('orderId') || ''
    if (payment) {
      switch (payment[0].status.status) {
        case 'REJECTED':
          response = ResumeData(
            status,
            requestId,
            signature,
            TextDelivery,
            emailCustomer,
            orderId,
            reference
          )
          setResponse && setResponse(response)
          break
        case 'PENDING':
          response = ResumeData(
            status,
            requestId,
            signature,
            TextDelivery,
            emailCustomer,
            orderId,
            reference
          )
          onDelAllItems && onDelAllItems()
          setResponse && setResponse(response)
          break
        case 'MANUAL':
          response = ResumeData(
            status,
            requestId,
            signature,
            TextDelivery,
            emailCustomer,
            orderId,
            reference
          )
          onDelAllItems && onDelAllItems()
          setResponse && setResponse(response)
          break
        case 'APPROVED':
          response = ResumeData(
            status,
            requestId,
            signature,
            TextDelivery,
            emailCustomer,
            orderId,
            reference
          )
          onDelAllItems && onDelAllItems()
          setResponse && setResponse(response)
          break
      }
      window.localStorage.setItem('response', JSON.stringify(response))
      handleClickOnNext(
        mobileCustomer,
        emailCustomer,
        identificationNumber,
        status
      )
      history.push('/resume')
    } else {
      if (status == 'REJECTED') {
        localStorage.setItem('sendRequest', '0')
        forceShowNotification &&
          forceShowNotification({
            type: 'alert',
            message: 'Pago Cancelado!'
          })
        handleClickOnNext(
          mobileCustomer,
          emailCustomer,
          identificationNumber,
          'CanceledPayment'
        )
      } else if (status == 'PENDING') {
        response = ResumeData(
          status,
          requestId,
          signature,
          TextDelivery,
          emailCustomer,
          orderId,
          reference
        )
        onDelAllItems && onDelAllItems()
        setResponse && setResponse(response)
        window.localStorage.setItem('response', JSON.stringify(response))
        handleClickOnNext(
          mobileCustomer,
          emailCustomer,
          identificationNumber,
          status
        )
        history.push('/resume')
      }
    }
  }

  useEffect(() => {
    const orderStatusFromLS = window.localStorage.getItem(
      'paymentCancelledFromIOS'
    )
    if (orderStatusFromLS && orderStatusFromLS === '1') {
      window.localStorage.removeItem('paymentCancelledFromIOS')
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'El pago ha sido cancelado.'
        })
    }

    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })

  useEffect(() => {
    try {
      P.on('response', (o: any) => {
        setLoading && setLoading({ loading: true })
        const state = localStorage.getItem('sendRequest')
        if (state == '0') {
          localStorage.setItem('sendRequest', '1')
          const status = o.status.status
          const requestId = o.requestId
          const signature = o.signature
          const reference = o.reference
          const orderId = window.localStorage.getItem('orderId') || ''
          if (orderId) {
            setTimeout(() => {
              getCheckRequest(
                status,
                requestId.toString(),
                signature,
                reference
              )
            }, 2000)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }, [0])

  return loaded ? (
    <Checkout>
      <Grid.Row>
        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <DateDelivery />
            </ContainerElements>
          </Grid.Column>
        )}
        <Grid.Column md={isMobile || isIpad ? 12 : 9}>
          <div className="animated fadeInLeft">
            <h3>Resumen del Pedido</h3>
            <PaymentContainer>
              <Card>
                <HeaderCard>
                  <TitleCard>Dirección de envío</TitleCard>
                  <LinkResponsive to="/shipping">Editar</LinkResponsive>
                </HeaderCard>
                <BodyCard>
                  <div>
                    <TitleBody>Provincia:</TitleBody>
                    <span>{provinceName}</span>
                  </div>
                  <div>
                    <TitleBody>Ciudad:</TitleBody>
                    <span>{cityName}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Principal:</TitleBody>
                    <span>{street1}</span>
                  </div>
                  <div>
                    <TitleBody>Calle Secundaria:</TitleBody>
                    <span>{street2}</span>
                  </div>
                  <div>
                    <TitleBody>Numeración:</TitleBody>
                    <span>{number}</span>
                  </div>
                  <div>
                    <TitleBody>Sector o Referencia:</TitleBody>
                    <span>{zoneName}</span>
                  </div>
                  <div>
                    <TitleBody>Detalle de Vivienda:</TitleBody>
                    <span>{reference}</span>
                  </div>
                </BodyCard>
                <h4>Persona de Contacto</h4>
                <BodyCard>
                  <div>
                    <TitleBody>Nombre:</TitleBody>
                    <span>{firstName}</span>
                  </div>
                  <div>
                    <TitleBody>Apellido:</TitleBody>
                    <span>{lastName}</span>
                  </div>
                  <div>
                    <TitleBody>Teléfono de Contacto:</TitleBody>
                    <span>{phone}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{email}</span>
                  </div>
                </BodyCard>
              </Card>
              <Card>
                <HeaderCard>
                  <TitleCard>Datos del Cliente</TitleCard>
                  <LinkResponsive to="/billing" fontcolor="#1428a0">
                    Editar
                  </LinkResponsive>
                </HeaderCard>
                <BodyCard>
                  <div>
                    <TitleBody>Tipo de Identificación:</TitleBody>
                    <span>{identificationTypeName}</span>
                  </div>
                  <div>
                    <TitleBody>Identificación:</TitleBody>
                    <span>{identificationNumber}</span>
                  </div>

                  {identificationType == 'RUC' ? (
                    <div>
                      <TitleBody>Razón Social:</TitleBody>
                      <span>{businessNameCustomer}</span>
                    </div>
                  ) : (
                    <>
                      <div>
                        <TitleBody>Nombre:</TitleBody>
                        <span>{firstNameCustomer}</span>
                      </div>
                      <div>
                        <TitleBody>Apellido:</TitleBody>
                        <span>{lastNameCustomer}</span>
                      </div>
                    </>
                  )}
                  <div>
                    <TitleBody>Teléfono Celular:</TitleBody>
                    <span>{mobileCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Dirección:</TitleBody>
                    <span>{addressCustomer}</span>
                  </div>
                  <div>
                    <TitleBody>Correo Electrónico:</TitleBody>
                    <span>{emailCustomer}</span>
                  </div>
                </BodyCard>
              </Card>
            </PaymentContainer>
          </div>
        </Grid.Column>

        {!isMobile && (
          <Grid.Column md={isIpad ? 6 : 3}>
            <TitleSummary />
            <ContainerElements>
              <ProductSummary />
            </ContainerElements>
            {!isIpad && (
              <>
                <ContainerElements>
                  <DateDelivery />
                </ContainerElements>
                <TermsContainer onClick={() => setTerms(!termsState)}>
                  <Checkbox onClick={() => setTerms(!termsState)}>
                    {termsState == true ? (
                      <Icon
                        color="black"
                        size="11px"
                        name="ok"
                        className="kronos-icon"
                      />
                    ) : (
                      <div />
                    )}
                  </Checkbox>
                  <label onClick={() => setTerms(!termsState)}>
                    Acepto{' '}
                    <a
                      target="_blank"
                      href="https://diners-ecuador.s3.amazonaws.com/documents/tyc_marketplace_dia_del_Padre.pdf"
                    >
                      <u>Términos y condiciones</u>
                    </a>
                  </label>
                </TermsContainer>
                <ContainerElements>
                  <Button
                    backgroundColor={primary}
                    color={secondary}
                    onClick={() => {
                      generateOrder()
                    }}
                    id={'MKTPL_PAY_NEXT'}
                  >
                    COMPRAR
                  </Button>
                </ContainerElements>
                {/* <ContainerFranchise>
                  <img src={franchiseImg} />
                </ContainerFranchise> */}
                <ContainerTransaction>
                  <label>Esta transacción se procesa con</label>
                  <ContainerLink
                    href="https://www.placetopay.com/web/home"
                    target="_blank"
                  >
                    <img src={p2pImage} />
                  </ContainerLink>
                </ContainerTransaction>

                <ContainerElements>
                  <NavButton
                    to="/billing"
                    backgroundColor="#f7f7f7"
                    color={primary}
                    bordercolor={primary}
                    onClick={() => {
                      onSetLoaded && onSetLoaded(false)
                    }}
                  >
                    Atrás
                  </NavButton>
                </ContainerElements>
              </>
            )}
          </Grid.Column>
        )}
        {!isMobile && isIpad && (
          <Grid.Column md={6} style={{ margin: '3.5rem 0 0' }}>
            <ContainerElements>
              <DateDelivery />
            </ContainerElements>
            <TermsContainer onClick={() => setTerms(!termsState)}>
              <Checkbox>
                {termsState == true ? (
                  <Icon
                    color="black"
                    size="11px"
                    name="ok"
                    className="kronos-icon"
                  />
                ) : (
                  <div />
                )}
              </Checkbox>
              <label>
                Acepto{' '}
                <a
                  target="_blank"
                  href="https://diners-ecuador.s3.amazonaws.com/documents/tyc_marketplace_dia_del_Padre.pdf"
                >
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={primary}
                color={secondary}
                onClick={() => {
                  generateOrder()
                }}
                id={'MKTPL_PAY_NEXT'}
              >
                COMPRAR
              </Button>
            </ContainerElements>
            {/* <ContainerFranchise>
              <img src={franchiseImg} />
            </ContainerFranchise> */}
            <ContainerTransaction>
              <label>Esta transacción se procesa con</label>
              <ContainerLink
                href="https://www.placetopay.com/web/home"
                target="_blank"
              >
                <img src={p2pImage} />
              </ContainerLink>
            </ContainerTransaction>
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor="#f7f7f7"
                color={primary}
                bordercolor={primary}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false)
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}

        {isMobile && (
          <Grid.Column>
            <ContainerElements>
              <Collapsible
                trigger={<TitleSummaryResponsive iconName="down-open" />}
                triggerWhenOpen={<TitleSummaryResponsive iconName="up-open" />}
              >
                <ProductSummary />
              </Collapsible>
            </ContainerElements>
            <TermsContainer onClick={() => setTerms(!termsState)}>
              <Checkbox>
                {termsState == true ? (
                  <Icon
                    color="black"
                    size="11px"
                    name="ok"
                    className="kronos-icon"
                  />
                ) : (
                  <div />
                )}
              </Checkbox>
              <label>
                Acepto{' '}
                <a
                  target="_blank"
                  href="https://diners-ecuador.s3.amazonaws.com/documents/tyc_marketplace_dia_del_Padre.pdf"
                >
                  <u>Términos y condiciones</u>
                </a>
              </label>
            </TermsContainer>
            <ContainerElements>
              <Button
                backgroundColor={primary}
                color={secondary}
                onClick={() => {
                  generateOrder()
                }}
                id={'MKTPL_PAY_NEXT'}
              >
                COMPRAR
              </Button>
            </ContainerElements>
            {/* <ContainerFranchise>
              <img src={franchiseImg} />
            </ContainerFranchise> */}
            <ContainerTransaction style={{ textAlign: 'center' }}>
              <label>Esta transacción se procesa con</label>
              <ContainerLink
                href="https://www.placetopay.com/web/home"
                target="_blank"
              >
                <img src={p2pImage} />
              </ContainerLink>
            </ContainerTransaction>
            <ContainerElements>
              <NavButton
                to="/billing"
                backgroundColor="#f7f7f7"
                color={primary}
                bordercolor={primary}
                onClick={() => {
                  onSetLoaded && onSetLoaded(false)
                }}
              >
                Atrás
              </NavButton>
            </ContainerElements>
          </Grid.Column>
        )}
      </Grid.Row>
    </Checkout>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  )
}

const AddTrackWithMutation = compose(
  graphql(GENERATE_ORDER_MUTATION),
  graphql(ADD_TRACKING_MUTATION)
)(withApollo(Payment))

export default AddTrackWithMutation
