import React from 'react'
import MenuBar from '../../components/MenuBar'
import Header from '../../components/Header'
import footerBg from '../../assets/home/home-bg.png'
import Toast from '../../components/Toast'
import TycLopdModal from '../../components/TycLopdModal'

import {
  Main,
  ContentWrapper,
  MenuWrapper,
  Wrapper,
  Footer,
  HeaderWrapper,
  Line
} from './style'

type Props = {
  children: React.ReactNode
  backgroundColor?: string
  isFixed?: boolean
  showBanner?: boolean
  showCart?: boolean
  currentPage?: string
}

function Layout({
  children,
  backgroundColor,
  isFixed,
  showBanner,
  showCart = false,
  currentPage
}: Props) {
  /* var token = window.localStorage.getItem('token') || ''
  if (!token) {
    token =
      Math.random().toString(36).substring(2, 15).toUpperCase() +
      Math.round(+new Date() / 1000)
    window.localStorage.setItem('token', token)
  } */

  return (
    <Wrapper>
      <MenuWrapper>
        <MenuBar currentPage={currentPage ? currentPage : ''} />
      </MenuWrapper>
      <ContentWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <Line />
        <Main>
          <Toast loggedin='loggedin' />
          <TycLopdModal />
          {children}
        </Main>
      </ContentWrapper>
      <Footer src={footerBg} />
    </Wrapper>
  )
}

export default Layout
