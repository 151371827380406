import styled from 'styled-components'

export const Title = styled.span`
  font-weight: bold;
  font-size: 1rem;
`
export const PriceContainer = styled.div`
  font-family: 'PreloSlab';
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  color: #014178;
  span {
    color: #0066cc;
    font-family: 'Prelo';
    font-weight: 500;
    }
`

export const ItemContainer = styled.div`
  margin-bottom: 0.5rem;

  i {
    margin-right: 4px;
  }
`
export const RegularPrice = styled.span`
  color: #014178;
  text-decoration: line-through;
`

export const StyledPrice = styled.div`
  color: #014178;
  font-size: 18px;
`

export const SavedPrice = styled.span`
  color: #198e00;
  font-weight: bold;
`
