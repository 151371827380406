import styled from 'styled-components'
import Modal from 'react-modal'

export const ModalWrapper = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  outline: none;
  max-width: 487px;
  width: 100%;
  z-index: 100;
`

export const ModalBody = styled.div`
  position: relative;
  padding: 20px;
`

export const MainModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Prelo';
  color: #0F265C;
`

export const MainModalImage = styled.div`
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: 5%;
`

export const MainModalTitle = styled.h1`
  margin: 30px 0 18px 0;
  text-align: center;
  font-family: 'PreloSlab';
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;

  @media (min-width: 480px) {
    font-size: 35px;
    line-height: 43.75px;
  }
`

export const MainModalCopys = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  max-width: 422px;
  margin-bottom: 21px;

  p {
    margin: 0;
    padding: 0;
    color: #0F265C;
    font-size: 14px;
    text-align: center;

    @media (min-width: 480px) {
      font-size: 18px;
    }
  }
`

export const MainModalBtnAccept = styled.button`
  transition: all .5s ease-in-out 0s;
  border: none;
  border-radius: 0 0 0 14px;
  display: inline-block;
  background: #CDCDCD;
  color: #FFFFFF;
  width: 100%;
  padding: 19px 0;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: not-allowed;

  @media (min-width: 375px) {
    width: 295px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const Checkboxs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 325px;
  align-items: flex-start;
  text-align: start;
  margin: 0 auto;
  margin-bottom: 21px;
`

export const CheckboxsGroup = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  span {
    color: #0F265C;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
  }

  @media (min-width: 480px) {
    span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`
