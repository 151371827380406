import React, { useState, useContext } from 'react'
import { graphql, withApollo, compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Row, Column } from '../../components/Grid'

import { Validate } from '../../helpers/inputValidations'
import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
import { Button } from '../../components/Buttons'
import LoaderButton from '../../components/LoaderButton'

import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import {
  FlexCol,
  ContainerButton,
  StyledRow,
  Title,
  File,
  Preview,
  FileWrapper
} from './style'
import previewIcon from '../../assets/images/preview.png'

import {
  UPLOAD_PROFILE_IMAGE,
  EDIT_MEMBER,
  DELETE_PROFILE_IMAGE
} from './query'
import { RESET_MUTATION } from '../../views/Reset/query'
export interface Asset {
  id?: string
  type: string
  url: string
  file?: any
  order?: number
}
interface Member {
  firstName: string
  firstLastName: string
  cellphone: string
  phone: string
  files?: any
}
function ProfileForm({ mutate, history }: any) {
  const { forceShowNotification, account, onSetAccountData } = useContext(
    CartContext
  )
  const { btnPrimary, secondary } = useContext(ThemeContext)
  const [asset, setAsset] = useState({} as Asset)
  const [image, setImage] = useState(false)
  const [loading, setLoading] = useState(false)

  /* const [oldAsset] = useState({
    id: '',
    url: '',
    type: '',
    order: 1
  }) */
  const [firstName] = useState(account.firstName)
  const [lastName] = useState(account.lastName)
  const [idNumber] = useState(account.document)

  const [emailState, setEmail] = useState({
    error: false,
    message: '',
    hide: false,
    value: account.email
  })

  const [phoneState, setPhone] = useState({
    error: false,
    message: '',
    hide: false,
    value: account.mobile
  })

  const [passwordState, setPassword] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })
  const [rePasswordState, setRePassword] = useState({
    error: false,
    message: '',
    hide: false,
    value: ''
  })

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    if (
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/png'
    ) {
      reader.readAsDataURL(file)
      reader.onloadend = function (e: any) {
        setAsset({
          id: '',
          url: e.target.result as string,
          type: 'image',
          order: 1,
          file: file
        })
        setImage(true)
      }
    }
  }
  // Validate input data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value)
    switch (id) {
      case 'phone':
        const currentValue = value.replace(/[^0-9]/gi, '')
        dataValidated = Validate(currentValue, 'phone', 10)
        setPhone(dataValidated)
        break
      case 'email':
        dataValidated = Validate(value, 'email', 60)
        setEmail(dataValidated)
        break
      case 'ogpassword':
        const reDataValidated = {
          error: false,
          message: '',
          value: rePasswordState.value,
          hide: false,
          fillColor: '#FFF'
        }
        if (rePasswordState.value !== value) {
          dataValidated = {
            error: true,
            message: 'Las contraseñas no coinciden',
            hide: false,
            value: value,
            fillColor: '#FFF'
          }
        } else {
          dataValidated = {
            error: false,
            message: '',
            value: value,
            hide: false,
            fillColor: '#FFF'
          }
          setRePassword(reDataValidated)
        }
        setPassword(dataValidated)
        break
      case 'repassword':
        const pasDataValidated = {
          error: false,
          message: '',
          value: passwordState.value,
          hide: false,
          fillColor: '#FFF'
        }
        if (passwordState.value !== value) {
          dataValidated = {
            error: true,
            message: 'Las contraseñas no coinciden',
            hide: false,
            value: value,
            fillColor: '#FFF'
          }
        } else {
          dataValidated = {
            error: false,
            message: '',
            value: value,
            hide: false,
            fillColor: '#FFF'
          }
          setPassword(pasDataValidated)
        }
        setRePassword(dataValidated)
        break
    }
    if (dataValidated.error) return true
    else return false
  }

  const clearFileds = () => {
    setPassword({
      message: '',
      hide: false,
      value: '',
      error: false
    })
    setRePassword({
      message: '',
      hide: false,
      value: '',
      error: false
    })
  }
  const handleSaveForm = async (url?: string) => {
    let member: Member = {
      firstName: firstName,
      firstLastName: lastName,
      cellphone: phoneState.value,
      phone: account.phone
    }
    if (image) {
      member.files = [
        {
          url: url,
          type: asset.type,
          order: asset.order
        }
      ]
    }

    await mutate({
      mutation: EDIT_MEMBER,
      variables: {
        input: {
          id: account.id,
          username: emailState.value,
          member: member
        }
      }
    })
      .then((response: any) => {
        if (response && response.data && response.data.editMemberData) {
          if (passwordState.value.length > 0) {
            mutate({
              mutation: RESET_MUTATION,
              variables: {
                input: {
                  email: emailState.value,
                  password: passwordState.value,
                  token: account.token
                }
              }
            })
              .then((resetResult: any) => {
                if (
                  resetResult &&
                  resetResult.data &&
                  resetResult.data.reset &&
                  resetResult.data.reset.email
                ) {
                  setLoading(false)
                  if (forceShowNotification) {
                    forceShowNotification({
                      type: 'info',
                      message: 'Actualizado'
                    })
                  }
                }
              })
              .catch(() => {
                setLoading(false)
                if (forceShowNotification) {
                  forceShowNotification({
                    type: 'fail',
                    message:
                      'Error al actualizar la contraseña, inténtalo más tarde'
                  })
                }
              })
          } else {
            setLoading(false)
            if (forceShowNotification) {
              forceShowNotification({
                type: 'info',
                message: 'Actualizado'
              })
            }
          }

          const updatedAccount = { ...account }
          updatedAccount.mobile = phoneState.value
          updatedAccount.email = emailState.value
          if (image) {
            updatedAccount.files = [
              { id: asset.id, type: asset.type, url: asset.url }
            ]
          }
          onSetAccountData && onSetAccountData(updatedAccount)
        }

        clearFileds()
      })
      .catch(() => {
        setLoading(false)
        if (forceShowNotification) {
          forceShowNotification({
            type: 'fail',
            message: 'Error al guardar la información, inténtalo más tarde'
          })
        }
      })
  }
  const hanldeDeteleImage = async () => {
    setLoading(true)
    if (image) {
      if (
        account.files &&
        account.files.length > 0 &&
        account.files[0].id &&
        account.files[0].id !== '1'
      ) {
        await mutate({
          mutation: DELETE_PROFILE_IMAGE,
          variables: {
            id: account.files[0].id
          }
        })
          .then((deleteResult: any) => {
            if (
              deleteResult &&
              deleteResult.data &&
              deleteResult.data.deleteProfileFile
            ) {
              handleSaveImage()
            }
          })
          .catch(() => {
            setLoading(false)
            if (forceShowNotification) {
              forceShowNotification({
                type: 'fail',
                message: 'Error al actualizar su foto, inténtalo más tarde'
              })
            }
          })
      } else {
        handleSaveImage()
      }
    } else {
      handleSaveForm()
    }
  }
  const handleSaveImage = async () => {
    if (image) {
      await mutate({
        mutation: UPLOAD_PROFILE_IMAGE,
        variables: {
          file: asset.file
        }
      })
        .then(async (response: any) => {
          if (
            response &&
            response.data &&
            response.data.uploadProfileFile &&
            response.data.uploadProfileFile.url
          ) {
            await setAsset((prevState: any) => ({
              ...prevState,
              id: response.data.uploadProfileFile.id,
              url: response.data.uploadProfileFile.url
            }))
            handleSaveForm(response.data.uploadProfileFile.url)
          }
        })
        .catch(() => {
          setLoading(false)
          if (forceShowNotification) {
            forceShowNotification({
              type: 'fail',
              message: 'Algo salió mal, inténtalo más tarde'
            })
          }
        })
    } else {
      handleSaveForm()
    }
  }

  const handleValidate = () => {
    let validated = true
    if (emailState.value !== account.email) {
      validated =
        passwordState.value !== '' && rePasswordState.value !== ''
          ? true
          : false
    }
    if (validated) {
      validateData(emailState.value, 'email')
      validateData(phoneState.value, 'phone')
      validateData(passwordState.value, 'ogpassword')
      validateData(rePasswordState.value, 'rePassword')

      if (
        emailState.error ||
        phoneState.error ||
        passwordState.error ||
        rePasswordState.error
      ) {
        forceShowNotification &&
          forceShowNotification({
            type: 'alert',
            message: 'Hay errores en el formulario'
          })
        return
      }

      hanldeDeteleImage()
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Nueva contraseña requerida'
        })
      return
    }
  }
  const logout = () => {
    window.localStorage.removeItem('token')
    history.push('/')
  }

  return (
    <Row>
      <Column md={12}>
        <Row className="contact-form">
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                id="firstName"
                type="text"
                label="Nombres"
                disabled={true}
                value={firstName}
              />
              <Spacer />
            </FlexCol>
          </Column>
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                id="lastName"
                type="text"
                label="Apellidos"
                disabled={true}
                value={lastName}
              />
              <Spacer />
            </FlexCol>
          </Column>
        </Row>

        <Row className="contact-form">
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                id="identificationNumber"
                label="C.I."
                type="text"
                disabled={true}
                value={idNumber}
              />
              <Spacer />
            </FlexCol>
          </Column>
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <label htmlFor="picture">Subir imagen de perfil</label>
              <FileWrapper>
                <File>
                  <input
                    id="picture"
                    type="file"
                    onChange={(e) => handleFileChange(e)}
                  />
                  Selecciona una foto de tu ordenador
                </File>
                <Preview>
                  <img src={asset.url ? asset.url : previewIcon} alt="PHOTO" />
                </Preview>
              </FileWrapper>
            </FlexCol>
          </Column>
        </Row>
        <Row className="contact-form">
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                onChange={(e: any) => {
                  validateData(e.target.value, 'phone')
                }}
                id="phone"
                type="text"
                label="Teléfono"
                required
                error={phoneState.error}
                value={phoneState.value}
              />
              {phoneState.error ? (
                <Error>{phoneState.message}</Error>
              ) : (
                <Spacer />
              )}
            </FlexCol>
          </Column>
          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                onChange={(e: any) => {
                  validateData(e.target.value, 'email')
                }}
                id="email"
                type="text"
                label="Email"
                maxLength={60}
                required
                error={emailState.error}
                value={emailState.value}
              />
              {emailState.error ? (
                <Error>{emailState.message}</Error>
              ) : (
                <Spacer />
              )}
            </FlexCol>
          </Column>
          <Column lg={12} md={12} sm={12}>
            <Title>
              <h1>Cambio de Contraseña</h1>
            </Title>
          </Column>

          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                onChange={(e: any) => {
                  validateData(e.target.value, 'ogpassword')
                }}
                id="ogpassword"
                type="password"
                label="Nueva Contraseña"
                maxLength={60}
                required
                value={passwordState.value}
                autoComplete={'off'}
              />
              <Spacer />
            </FlexCol>
          </Column>

          <Column lg={6} md={6} sm={12}>
            <FlexCol>
              <Input
                onChange={(e: any) => {
                  validateData(e.target.value, 'repassword')
                }}
                id="repassword"
                type="password"
                label="Confirmar Contraseña"
                maxLength={60}
                required
                value={rePasswordState.value}
                autoComplete={'off'}
              />
              {rePasswordState.error || passwordState.error ? (
                <Error>
                  {rePasswordState.message !== ''
                    ? rePasswordState.message
                    : passwordState.message}
                </Error>
              ) : (
                <Spacer />
              )}
            </FlexCol>
          </Column>
        </Row>
        <StyledRow>
          <Column lg={4} md={6} sm={12}>
            <ContainerButton>
              <Button
                backgroundColor={loading ? secondary : btnPrimary}
                color={secondary}
                onClick={handleValidate}
                disabled={loading}
                padding={loading ? '0.2rem 0.5rem' : '0.8rem 0.5rem 0.8rem'}
              >
                {loading ? <LoaderButton /> : 'Guardar'}
              </Button>
            </ContainerButton>
          </Column>
          <Column lg={4} md={6} sm={12}>
            <ContainerButton>
              <Button
                backgroundColor={'#ffffff'}
                color={btnPrimary ? btnPrimary : '#0066cc'}
                bordercolor={btnPrimary ? btnPrimary : '#0066cc'}
                onClick={logout}
              >
                Cerrar sesión
              </Button>
            </ContainerButton>
          </Column>
        </StyledRow>
      </Column>
    </Row>
  )
}
const ProfileWithApollo = compose(graphql(UPLOAD_PROFILE_IMAGE))(
  withRouter(withApollo(ProfileForm))
)

export default ProfileWithApollo
