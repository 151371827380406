import React, { useRef } from 'react'
import Layout from '../../components/Layout'
import { Container, Column, Row } from '../../components/Grid'
import Points from '../../components/Points'
import { Text, Wrapper } from './style'
import { Link } from 'react-router-dom'

function PointsPage() {
  const CatalogRef = useRef<null | HTMLDivElement>(null)

  return (
    <Layout showBanner={true} showCart={true} currentPage={'points'}>
      <Container id="MKTPL_HOME">
        <Wrapper>
          <Text>
            <h3>Historial de Puntos Acumulados</h3>
          </Text>
          <Row>
            <div style={{ width: '100%' }} ref={CatalogRef}>
              <Column md={12}>
                <Points />
              </Column>
            </div>
          </Row>
          <Text>
            <p>
              * Si tienes inquietudes por los canjes o acreditaciones
              realizadas, comunícate con nosotros al botón{' '}
              <Link to="/contact">
                <strong>CONTÁCTANOS</strong>
              </Link>
            </p>
          </Text>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default PointsPage
