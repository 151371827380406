import React from 'react'
import { Redirect } from 'react-router-dom'

interface Props {
  children: React.ReactNode
}

class TycLopdViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props
    const getAccount = window.localStorage.getItem('account') || '{}'
    const account = JSON.parse(getAccount)
    return (account && (account.terms || account.lopd)) ? (children) : (<Redirect to={'/home'} />)
  }
}

export default TycLopdViewHandler
