import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import formatNumber from '../../helpers/formatNumber'
import { Query, QueryResult } from 'react-apollo'
//import { Column, Row } from '../../components/Grid'
import LazyImage from '../LazyImage'
import CartContext from '../../context/CartContext'
import ProductModal from '../ProductModal'
//import Loader from '../../components/loader'
import { GET_VARIATIONS_QUERY } from './query'
import {
  ProductContainer,
  ImgContainer,
  ProductInfoContainer,
  ProductPriceContainer,
  CustomTitle,
  RegularPrice,
  CartButton
} from './style'
// import { RegularPrice } from '../Global/style'
import Product from '../../model/Product'
import Variation from '../../model/Variation'
import Assets from '../../model/Assets'

import productExample from '../../assets/catalog/product-samsung.png'

interface DataVariations {
  variationsByProduct: {
    nodes: Variation[]
    pagination: {
      total: number
      limit: number
      offset: number
    }
  }
}
interface Variables {
  productId: string
}

interface Props extends RouteComponentProps<any> {
  product: Product
}
/* type Data = {
  product: ProductModel
}

interface Variables {
  productSlug: String
} */

export const ProductCard = ({ history, product }: Props) => {
  const [selectedVariation, setselectedVariation] = useState<
    Variation | undefined
  >()
  const [assetsArray, setAssetsArray] = useState<Assets[] | []>([])
  console.log(assetsArray.length ? '' : '')
  const { forceShowNotification, onAddToCart } = useContext(CartContext)
  const [openModal, setOpenModal] = useState(false)
  const [variations, setVariations] = useState([] as Variation[])

  const { name, assets, minPoint } = product
  const handleVAriationsData = (variations: any) => {
    const filteredVariations = variations
      .filter((variation: any) => {
        return variation.status == 'active'
      })
      .sort((a: Variation, b: Variation) => {
        return a.pointCost < b.pointCost ? -1 : 1
      })

    return filteredVariations
  }
  //const redirectProduct = (slug: string) => history.push(`/product/${slug}`)
  const selectVariation = (variation: Variation) => {
    setselectedVariation(variation)
    let arrAssets: Assets[] = []
    if (variations) {
      arrAssets = arrAssets.concat(variation.assets)
    }
    if (product.assets) {
      arrAssets = arrAssets.concat(product.assets)
    }
    setAssetsArray(arrAssets)
  }

  const addtoCart = () => {
    if (selectedVariation) {
      const {
        inventory: { localStock, storageStock }
      } = selectedVariation
      let no_stock = localStock + storageStock == 0 ? true : false
      if (no_stock) {
        forceShowNotification &&
          forceShowNotification({
            type: 'fail',
            message: 'Producto fuera de stock'
          })
      } else {
        const addVariation = selectedVariation
        addVariation['product'] = product
        onAddToCart && onAddToCart(addVariation, 1)
        forceShowNotification &&
          forceShowNotification({
            type: 'ok',
            message: name + ' se ha añadido al carrito.'
          })
      }
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Seleccione un producto'
        })
    }
  }

  const renderProductImages = () => {
    if (product.assets) {
      setAssetsArray(product.assets)
    }
  }

  useEffect(() => {
    if (product) {
      if (variations) {
        const filteredVariations = variations
          .filter((variation) => {
            return variation.status == 'active'
          })
          .sort((a: Variation, b: Variation) => {
            return a.pointCost < b.pointCost ? -1 : 1
          })
        if (filteredVariations.length > 0) {
          selectVariation(filteredVariations[0])
        } else {
          renderProductImages()
        }
      }
    }
  }, [variations])
  const variationVariables = {
    productId: product.id
  }
  return (
    <>
      <>
        <ProductContainer
          onClick={() => setOpenModal(!openModal)}
          className="MKTPL_HOME_SELEC"
          data-product={name}
        >
          <Query<DataVariations, Variables>
            query={GET_VARIATIONS_QUERY}
            variables={variationVariables}
          >
            {({
              loading,
              error,
              data
            }: QueryResult<DataVariations, Variables>) => {
              if (loading) {
                return (
                  <ImgContainer>
                    <LazyImage
                      src={
                        assets && assets.length > 0
                          ? assets[0].url
                          : variations &&
                            variations.length > 0 &&
                            variations[0].assets.length > 0
                          ? variations[0].assets[0].url
                          : ''
                      }
                      alt="Producto"
                    />
                  </ImgContainer>
                )
              }

              if (error) {
                return <ImgContainer>NO IMAGE</ImgContainer>
              }

              if (!data) {
                return <p>No Data</p>
              }
              setVariations(data.variationsByProduct.nodes)
              return (
                <ImgContainer>
                  <LazyImage
                    src={
                      assets
                        ? assets.length > 0
                          ? assets[0].url
                          : selectedVariation &&
                            selectedVariation.assets.length > 0
                          ? selectedVariation.assets[0].url
                          : productExample
                        : productExample
                    }
                    alt="Producto"
                  />
                </ImgContainer>
              )
            }}
          </Query>

          <div className="content">
            <ProductInfoContainer>
              <CustomTitle>{name}</CustomTitle>
            </ProductInfoContainer>
            <ProductPriceContainer>
              <RegularPrice>
                {formatNumber(
                  selectedVariation ? selectedVariation.pointCost : minPoint
                )}
                <span>Puntos</span>
              </RegularPrice>
              {selectedVariation && (
                <CartButton onClick={addtoCart}>
                  <i className="flex-icon icon-carrito_agregar"></i>
                </CartButton>
              )}
            </ProductPriceContainer>
          </div>
        </ProductContainer>
        <ProductModal
          key={product.name}
          product={product}
          variations={
            variations ? handleVAriationsData(variations) : ([] as Variation[])
          }
          modalOpen={openModal}
          closeModal={setOpenModal}
        />
      </>
    </>
  )
}

export default withRouter(ProductCard)
