import React from 'react'
import Variation from '../../../../../../model/Variation'
import {
  //Title,
  PriceContainer
  //ItemContainer,
  //RegularPrice,
  //SavedPrice
} from './style'
import FormatNumber from '../../../../../../helpers/formatNumber'

type Props = {
  variation: Variation
}

const Price = ({ variation }: Props) => {
  let { pointCost } = variation
  // let { price, regularPrice } = variation
  //const { primary, third } = useContext(ThemeContext)

  return (
    <PriceContainer>
      <div>
        {FormatNumber(pointCost)}
        <br />
        <span>Puntos</span>
      </div>
      <div></div>
    </PriceContainer>
  )
}

export default Price
