import React from "react";

type Props = {
  children: (hasCart: boolean) => React.ReactNode;
};

class CartViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props;

    const cart = JSON.parse(window.localStorage.getItem("items") || "[]");

    if (cart && cart.length > 0) {
      return children(true);
    }

    return children(false);
  }
}

export default CartViewHandler;
