import styled from 'styled-components'
import sizes from '../../../../styles/media'

export const DateDeliveyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 20px;
  background-color: #96beff;
  padding: 1rem 2rem;
  margin: 1rem 0;
  text-align: center;
  align-items: center;

  @media ${sizes.xs} {
    padding: 1rem 1.5rem;
  }

  @media ${sizes.md} {
    padding: 1rem 0.5rem;
  }

  @media ${sizes.xl} {
    max-width: 258px;
    padding: 1rem 1.5rem 1rem 1rem;
  }
`

export const DateIcon = styled.div``

export const DateInfo = styled.div`
  line-height: 1.2;
  display: flex;
`

export const DateText = styled.span`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: normal;
  color: #000000;
  line-height: 1.2;
  text-align: left;
  padding-right: 0.2rem;
  margin-left: 10px;
`
