import React from 'react'
// import formatNumber from '../../helpers/formatNumber'

type Props = {
  children: (hasCart: boolean) => React.ReactNode
}

class ShippingViewHandler extends React.Component<Props> {
  /*calculatePayment = (items: any) => {
    let iva: number = 0,
      discount: number = 0,
      subtotal: number = 0,
      total: number = 0

    items.forEach((item: any) => {
      discount +=
        (item.supplierCost * item.supplierDiscount * item.quantity) / 100
      subtotal += item.supplierCost * item.quantity
    })

    iva = (subtotal - discount) * 0.12
    total = subtotal - discount + iva
    return formatNumber(total)
  }*/

  render() {
    const { children } = this.props

    const cart = JSON.parse(window.localStorage.getItem('items') || '[]')
    //const total = this.calculatePayment(cart)

    if (cart && cart.length <= 0) {
      return children(false)
    }

    /*const minimumAmount = formatNumber(
      cart[0].product.supplier.metaData.monto_minimo
    )

    if (minimumAmount > total) {
      return children(false)
    }*/
    return children(true)
  }
}

export default ShippingViewHandler
