import React, { useState, useEffect, useContext } from 'react'
import {
  StyledSelect,
  customStyles,
  ModalWrapper,
  ModalDataContainer,
  TimesBtn,
  StyledColumnCustom,
  ItemContainer,
  Name,
  CartButton,
  Content,
  FeaturesWrapper,
  MobileFeaturesWrapper,
  StyledColumn
} from './style'
import CartContext from '../../context/CartContext'
import { Row, Column } from '../../components/Grid'
import SliderProduct from '../../components/SliderProduct'
import Variation from '../../model/Variation'
import Assets from '../../model/Assets'
import Product from '../../model/Product'
//import Stock from '../../views/Product/components/Description/components/Stock'
import Price from '../../views/Product/components/Description/components/Price'

interface Props {
  modalOpen: boolean
  closeModal?: any
  product?: Product
  variations?: any
}

const ProductModal = ({
  modalOpen,
  closeModal,
  product,
  variations
}: Props) => {
  if (!product) {
    return <></>
  }
  const { changeDeliveryInfo, onAddToCart, forceShowNotification } = useContext(
    CartContext
  )
  const [featureSelected, setFeaturesSelected] = useState(false)
  const [count, setCount] = useState(1)
  const [assetsArray, setAssetsArray] = useState<Assets[] | []>([])
  const [selectedVariation, setselectedVariation] = useState<
    Variation | undefined
  >()

  const [optionSelect, setOptionSelect] = useState({
    label:
      variations && variations.length > 0 ? variations[0].options[0].name : '',
    value:
      variations && variations.length > 0 ? variations[0].options[0].id : ''
  })

  let no_stock = false
  if (selectedVariation) {
    let {
      inventory: { localStock, storageStock }
    } = selectedVariation
    no_stock = localStock + storageStock == 0 ? true : false
  }
  const selectVariation = (variation: Variation) => {
    setOptionSelect({
      label: variation.options[0].name,
      value: variation.options[0].id
    })
    setselectedVariation(variation)
    let arrAssets: Assets[] = []
    if (variations) {
      arrAssets = arrAssets.concat(variation.assets)
    }
    if (product.assets) {
      arrAssets = arrAssets.concat(product.assets)
    }
    setAssetsArray(arrAssets)
  }

  const renderProductImages = () => {
    if (product.assets) {
      setAssetsArray(product.assets)
    }
  }

  const handleSelectedOption = (option: any) => {
    //setOptionSelect(option)
    let selectedVariation = variations.filter((item: any) => {
      return item.options[0].id == option.value
    })
    if (selectedVariation && selectedVariation.length > 0) {
      selectVariation(selectedVariation[0])
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: `${name} ${option.label} no disponible.`
        })
    }
  }

  const addtoCart = async () => {
    if (selectedVariation) {
      const addVariation = selectedVariation
      addVariation['product'] = product

      const {
        inventory: { localStock, storageStock }
      } = selectedVariation
      let no_stock = localStock + storageStock == 0 ? true : false

      if (no_stock) {
        forceShowNotification &&
          forceShowNotification({
            type: 'fail',
            message: 'Producto fuera de stock'
          })
      } else {
        const addVariation = selectedVariation
        addVariation['product'] = product
        onAddToCart && onAddToCart(addVariation, count)

        forceShowNotification &&
          forceShowNotification({
            type: 'ok',
            message: name + ' se ha añadido al carrito.'
          })

        setCount(1)

        if (
          product.supplier &&
          product.supplier.metaData &&
          product.supplier.metaData.entrega
        ) {
          changeDeliveryInfo &&
            changeDeliveryInfo(product.supplier.metaData.entrega)
        }
      }
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Seleccione un producto'
        })
    }
  }

  useEffect(() => {
    if (product) {
      if (variations) {
        const filteredVariations = variations
          .filter((variation: Variation) => {
            return variation.status == 'active'
          })
          .sort((a: Variation, b: Variation) => {
            return a.pointCost < b.pointCost ? -1 : 1
          })
        if (filteredVariations.length > 0) {
          selectVariation(filteredVariations[0])
        } else {
          renderProductImages()
        }
      }
    }
  }, [variations])

  useEffect(() => {
    if (selectedVariation) {
      let {
        inventory: { localStock, storageStock }
      } = selectedVariation
      no_stock = localStock + storageStock == 0 ? true : false

      if (!no_stock) {
        addtoCart()
      } else {
        setFeaturesSelected(false)
      }
    }
  }, [featureSelected])

  let { name, description, features } = product

  const optionsFeatureAux: any[] = []
  variations.forEach((variation: Variation) => {
    variation.options.forEach(option => {
      features && features.length > 0 && features.forEach(feature => {
        feature.options.forEach(opfeature => {
          if (opfeature.id === option.id) {
            optionsFeatureAux.push({
              label: opfeature.name,
              value: opfeature.id
            })
          }
        });
      });
    });
  });

  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '12px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={modalOpen}
      className="short"
    >
      <>
        <ModalDataContainer
          padding={'30px 30px 30px 30px'}
          backgroundcolor={'#f4f8f9'}
        >
          <TimesBtn onClick={() => closeModal && closeModal(false)}>
            <i className="icon-cerrar" />
          </TimesBtn>
          <Row>
            <Column xs={12}>
              <Row>
                <StyledColumnCustom xs={8} md={7}>
                  {assetsArray.length > 0 && (
                    <SliderProduct assets={assetsArray} />
                  )}
                </StyledColumnCustom>
                <StyledColumn xs={4} md={5}>
                  <Content>
                    {/* {no_stock && (
                      <ItemContainer>
                        {selectedVariation && (
                          <Stock variation={selectedVariation} />
                        )}
                      </ItemContainer>
                    )} */}
                    <ItemContainer>
                      {selectedVariation && (
                        <Price variation={selectedVariation} />
                      )}
                    </ItemContainer>
                    {selectedVariation && (
                      <CartButton onClick={addtoCart}>
                        <i className="flex-icon icon-carrito_agregar"></i>
                      </CartButton>
                    )}
                    {/* {quantityComponent()} */}
                  </Content>
                  {variations &&
                    variations.length > 0 &&
                    features &&
                    features.length > 0 &&
                    features.map((feature, index) => {
                      return (
                        <FeaturesWrapper key={index}>
                          {feature.options && feature.options.length > 0 && (
                            <>
                              <p>{feature.name}</p>

                              <StyledSelect
                                styles={customStyles}
                                id="feature"
                                options={optionsFeatureAux}
                                autoFocus={false}
                                onChange={async (optionSelected: any) => {
                                  handleSelectedOption(optionSelected)
                                }}
                                value={optionSelect}
                              />
                            </>
                          )}
                        </FeaturesWrapper>
                      )
                    })}
                </StyledColumn>
              </Row>
              <Row>
                {variations &&
                  variations.length > 0 &&
                  features &&
                  features.length > 0 &&
                  features.map((feature, index) => {
                    return (
                      <Column xs={6}>
                        <MobileFeaturesWrapper key={index}>
                          {feature.options && feature.options.length > 0 && (
                            <>
                              <p>{feature.name}</p>

                              <StyledSelect
                                styles={customStyles}
                                id="feature"
                                options={optionsFeatureAux}
                                autoFocus={false}
                                onChange={async (optionSelected: any) => {
                                  handleSelectedOption(optionSelected)
                                }}
                                value={optionSelect}
                              />
                            </>
                          )}
                        </MobileFeaturesWrapper>
                      </Column>
                    )
                  })}
              </Row>
            </Column>
          </Row>
          <Row>
            <Column>
              <Name>{name}</Name>
            </Column>
          </Row>
          <Row>
            <Column>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </Column>
          </Row>
        </ModalDataContainer>
      </>
    </ModalWrapper>
  )
}

export default ProductModal
