import styled from 'styled-components'
import sizes from '../../styles/media'
import { Row, Column } from '../../components/Grid'
import { Link } from 'react-router-dom'

export const LogoContainer = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;

  @media ${sizes.sm} {
    justify-content: left;
    flex-wrap: nowrap;
  }
`

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c5c5c5;
  border-left: 1px solid #f4f8f9;
  margin-right: 10px;
  span {
    display: none;
    &.mobile {
      display: none;
      align-items: center;
      justify-content: center;
      height: 14px;
      width: 14px;
      font-size: 0.6rem;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &.active {
    color: #0066cc;
    font-weight: 600;
    span {
      &.mobile {
        display: flex;
        color: #ffffff;
        background-color: #0066cc;
      }
    }
  }
  @media ${sizes.sm} {
    margin-right: 0;
    border-left: 1px solid #f4f8f9;
    &.active {
      color: #0066cc;
    }
    span {
      margin-top: 0.3rem;
      display: block;
      &.mobile {
        display: none;
      }
    }
  }
`
export const CartContainer = styled(Link)`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c5c5c5;
  border-left: 1px solid #f4f8f9;
  margin: 0 1rem 0 0;
  span {
    display: none;
    &.mobile {
      display: none;
      align-items: center;
      justify-content: center;
      height: 15px;
      width: 15px;
      font-size: 0.6rem;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &.active {
    color: #f1bf06;
    font-weight: 600;
    span {
      &.mobile {
        display: flex;
        color: #014178;
        background-color: #f1bf06;
      }
    }
  }

  @media ${sizes.sm} {
    border-left: 1px solid #f4f8f9;
    &.active {
      color: #f1bf06;
    }
    span {
      margin-top: 0.3rem;
      display: block;
      &.mobile {
        display: none;
      }
    }
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  border-radius: 50%;
  &.blue {
    font-family: PreloSlab;
    font-size: 1.22rem;
    background-color: #007aff;
    line-height: 1px;
    color: #f4f8f9;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  @media ${sizes.sm} {
    width: 58px;
    height: 58px;
    background-color: #f4f8f9;
  }
  i {
    font-size: 2rem;
  }
`

export const Title = styled.div`
  margin: 0;
  width: 60%;
  color: #014178;
  h1 {
    font-size: 1rem;
  }
  @media ${sizes.sm} {
    margin: auto;
    width: 90%;
    h1 {
      text-align: center;
      font-size: 2rem;
    }
  }

  @media ${sizes.md} {
    margin: 0;
    h1 {
      text-align: left;
      font-size: 1.9rem;
    }
  }
  @media ${sizes.xl} {
    h1 {
      font-size: 2.9rem;
    }
  }
`

export const HeaderCard = styled.div`
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;
  padding: 1rem;
  display: none;
  @media ${sizes.sm} {
    padding: 1rem 0;
    display: block;
  }
  @media ${sizes.lg} {
    padding: 1rem;
  }
  span {
    &.name {
      font-family: PreloSlab;
      color: #0066cc;
    }
  }
  .logout {
    cursor: pointer;
  }
`
export const MobileHeaderCard = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  p {
    margin: 0;
  }
  @media ${sizes.sm} {
    font-size: 1rem;
    display: none;
  }

  span {
    &.name {
      font-family: PreloSlab;
      color: #0066cc;
    }
  }
`

export const Points = styled.div`
  margin: 0;
  text-align: left;
  color: #0066cc;
  display: flex;
  font-family: PreloSlab;
  @media ${sizes.sm} {
    text-align: center;
    flex-direction: column;
  }
  h2 {
    color: #014178;
    font-size: 1.3rem;
    margin: 0;
    @media ${sizes.lg} {
      font-size: 1.7rem;
    }
  }
  p {
    font-family: PreloSlab;
    margin: 0 0 0 0.7rem;
    font-size: 0.8rem;
    @media ${sizes.sm} {
      margin: 0.3rem 0 0;
    }
    @media ${sizes.lg} {
      font-size: 1rem;
    }
  }
`

export const CustomColumn = styled(Column)`
  padding: 0;
`

export const HalfColumn = styled(Column)`
  margin-top: 1rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  @media ${sizes.sm} {
    margin-top: 0;
  }
  &.mobile {
    width: 90%;
    margin-top: 0;
    @media ${sizes.sm} {
      display: none;
    }
  }
`

export const CustomRow = styled(Row)`
  margin: auto;
  display: flex;
  align-items: center;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c5c5c5;
  margin: 10px auto;
  @media ${sizes.sm} {
    display: none;
  }
`
