import gql from 'graphql-tag'

const GENERATE_ORDER_MUTATION = gql`
  mutation generateOrder($input: CreateOrderInput!) {
    generateOrder(input: $input) {
      id
      status
    }
  }
`

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
    }
  }
`

const SAVE_ADDRESS_MUTATION = gql`
  mutation saveMemberAddress($input: MemberAddressInput!) {
    editMemberAddress(input: $input) {
      nodes {
        id
      }
    }
  }
`

export { GENERATE_ORDER_MUTATION, SAVE_ADDRESS_MUTATION, GET_ORDER_STATUS }
