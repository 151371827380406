import React from 'react'

type Props = {
  children: (hasToken: boolean) => React.ReactNode
}

class PrehomeViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props

    const token = window.localStorage.getItem('token') || ''

    if (token && token.length > 0) {
      return children(true)
    }

    return children(false)
  }
}

export default PrehomeViewHandler
