import React from 'react'
//import gql from 'graphql-tag'
//import { Query, QueryResult } from 'react-apollo'
import { Redirect } from 'react-router-dom'
//import ThemeContext from '../context/ThemeContext'

/* const GET_CHECK_LOGIN_QUERY = gql`
  query getCheckLoginQuery($token: String!) {
    checkLogin(token: $token) {
      token
    }
  }
` */

interface Props {
  children: React.ReactNode
}

/* interface Data {
  checkLogin: {
    token: any
  }
} */

class AuthViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props
    const token = window.localStorage.getItem('token') || ''
    return token ? (
      /* <Query
        variables={{ token }}
        query={GET_CHECK_LOGIN_QUERY}
        fetchPolicy="network-only"
      >
        {({ data }: QueryResult<Data, any>) => {
          if (!data) {
            return <Redirect to={'/'} />
          }
          //const decode = jwt_decode(token)
          sessionStorage.setItem('token', token)
          return children
        }}
      </Query> */
      children
    ) : (
      <Redirect to={'/'} />
    )
  }
}

export default AuthViewHandler
