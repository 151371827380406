import styled from 'styled-components'
import { StickyContainer } from 'react-sticky'
import Sidebar from 'react-sidebar'
import { Container } from '../../components/Grid'
import sizes from '../../styles/media'

interface Props {
  backgroundcolor?: string
  showBanner?: boolean
  isSticky?: boolean
}
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #014178;
  flex-direction: column;
  @media ${sizes.md} {
    flex-direction: row;
  }
`
export const CustomStickyContainer = styled(StickyContainer)`
  flex: auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: 100vh;
`

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 94%;
  margin: auto;
  padding: 0 0 20px;
  background-color: #f4f8f9;
  border-radius: 35px 35px 0 0;
  @media ${sizes.md} {
    padding: 0 40px 20px;
    border-radius: unset;
    min-height: 100vh;
    width: 78%;
    margin: 0;
  }
`

export const Line = styled.div`
  height: 1px;
  background-color: #c5c5c5;
  margin: 20px 15px 0px;
  display: none;
  @media ${sizes.sm} {
    display: block;
  }
  @media ${sizes.md} {
    margin: 0 15px 20px;
  }
`
export const MenuWrapper = styled.div`
  width: 100%;
  background-color: transparent;

  @media ${sizes.md} {
    width: 20%;
    min-height: 100vh;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: auto;
  @media ${sizes.md} {
    height: 25vh;
  }
`

export const FooterWrapper = styled.footer`
  ${(props: Props) => {
    let styles = `
        box-shadow: 0 0 4px 0 rgba(117, 120, 123, 0.5);
        background-color: ${props.backgroundcolor};
        padding: 1rem 0;
    `

    return styles
  }};
`

export const Footer = styled.img`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  @media (max-height: 710px) {
    height: 80px;
  }
`

export const Main = styled.div<Props>`
  display: block;
  flex: auto;
  padding: 1.5rem 0;
  background-color: ${(props) => props.backgroundcolor};
`

export const StyledSideBar = styled(Sidebar)`
  display: flex;
`

export const StyledContainer = styled(Container)`
  display: flex;
`

export const StyledAnimationLarge = styled.div`
  display: none;
  @media ${sizes.xl} {
    width: 1115px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) =>
      props.isSticky === true ? 'none !important' : 'block'};
  }
`

export const StyledAnimationMedium = styled.div`
  display: none;
  @media ${sizes.md} {
    width: 766px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) =>
      props.isSticky === true ? 'none !important' : 'block'};
  }

  @media ${sizes.xl} {
    display: none;
  }
`

export const StyledAnimationMobile = styled.div`
  width: 335px !important;
  max-width: 100% !important;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2rem;
  overflow: hidden;
  z-index: 5;
  display: ${(props: Props) =>
    props.isSticky === true ? 'none !important' : 'block'};

  @media ${sizes.md} {
    display: none;
  }
`
