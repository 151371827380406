import styled from 'styled-components'
import sizes from '../../styles/media'

export const ToastWrapper = styled.div`
  position: absolute;
  top: 123px;
  right: calc(50% - (335px / 2));
  z-index: 100;
  max-width: 335px;
  overflow: hidden;
  color: #0F265C;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #0066CC;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  margin: 0 auto;
  opacity: 0;

  @media ${sizes.md} {
    right: 15px;
  }
`

export const ToastBody = styled.div`
  display: flex;
  padding: 15px 13px 30px;
  gap: 25px;
`

export const ToastContent = styled.div`
  max-width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 8px 0 0 50px;
`

export const ToastTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-family: 'PreloSlab';
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
`

export const ToastDescription = styled.p`
  max-width: 210px;
  margin: 0;
  font-family: 'Prelo';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

export const ToastBtn = styled.a`
  border: none;
  max-width: 153px;
  max-height: 40px;
  background-color: #0066CC;
  color: #ffffff;
  padding: 9px 30px;
  border-radius: 0px 0px 0px 14px;
  font-family: 'PreloSlab';
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
`

export const ToastClose = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  background: transparent;
  cursor: pointer;
`