import React, { useContext, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import { Column } from '../../components/Grid'
import CartContext from '../../context/CartContext'
import ThemeContext from '../../context/ThemeContext'
import formatNumber from '../../helpers/formatNumber'
import { IconButton } from '../../components/Buttons'
import { NavButton } from '../../components/Buttons'
import Modal from '../../components/Modal/ModalRoot'
import { Link } from 'react-router-dom'

import {
  ProductListContainer,
  TitleContainer,
  ItemContainer,
  Title,
  ImgContainer,
  Img,
  Name,
  //Reference,
  QuantityButton,
  QuantityContainer,
  Quantity,
  StockContainer,
  Line,
  //Clean,
  //Continue,
  //ActionsCartContainer,
  //FlexRow,
  SpanPayment,
  //SpanShipping,
  TotalContainer,
  AmountsContainer,
  ButtonContainer,
  //LinkProduct,
  IconWrapper,
  Bluelabel,
  MarginRow,
  Wrapper,
  StyledRow,
  StyledColumn,
  IconContainer,
  MobileIconContainer,
  RightQuantity,
  MobileQuantity,
  MobileProduct
} from './style'

import productExample from '../../assets/catalog/product-samsung.png'

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $items: [VariationItems]
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      docUpdated: $docUpdated
      items: $items
    ) {
      token
    }
  }
`

function ProductsList({ mutate }: any) {
  const {
    items,
    onRemoveFromCart,
    onAddToCart,
    onDelFromCart,
    //onDelAllItems,
    forceShowNotification
    //onSetLoaded
  } = useContext(CartContext)

  const { primary } = useContext(ThemeContext)

  const [validAmount] = useState(true)
  //const [modalOpen, setModalOpen] = useState(false)

  let total: number = 0
  const calculatePayment = () => {
    items.forEach((item: any) => {
      total += item.pointCost * item.quantity
    })
  }

  /*   const handleClickonEvent = (eventType: any, type: any, items: any) => {
    if (eventType === 'onClick') {
      onSetLoaded && onSetLoaded(false)
    }
    let itemsTrack: any = []

    items.map((item: any, index: number) => {
      const {
        id,
        pointCost,
        regularPrice,
        reference,
        product: { name, assets }
      } = item

      itemsTrack[index] = {
        id: id,
        name: name,
        reference: reference,
        price: price,
        regularPrice: regularPrice,
        image: assets.length > 0 ? assets[0].url : ''
      }
    })

    var token = window.localStorage.getItem('token') || ''
    mutate({
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: type,
        eventType: eventType,
        items: itemsTrack,
        docUpdated: 0
      }
    })
  } */
  calculatePayment()

  useEffect(() => {
    items.forEach((element: any) => {
      if (element.quantity === 0) {
        onDelFromCart && onDelFromCart(element)
      }
    })
  }, [])

  /*   const clearCart = () => () => {
    onDelAllItems && onDelAllItems()
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: 'Se ha limpiado el carrito.'
      })
  } */

  const deleteProduct = (name: string, index: number) => () => {
    onRemoveFromCart && onRemoveFromCart(index)
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: name + ' se ha eliminado del carrito.'
      })
  }

  /*   useEffect(() => {
    const supplierDiscount =
      items.length > 0
        ? items[0].product.supplier &&
          items[0].product.supplier.metaData.monto_minimo
        : null

    if (supplierDiscount) {
      if (supplierDiscount > total) {
        setValidAmount(false)
      } else {
        setValidAmount(true)
      }
    }
  }, [total]) */

  return (
    <>
      <Modal>
        {({ openModal }) => {
          return (
            <Wrapper>
              <StyledRow>
                <StyledColumn
                  md={12}
                  /* onLoad={() => {
                    handleClickonEvent('onLoad', 'VIEW_CART_EVENT', items)
                  }} */
                >
                  <ProductListContainer>
                    <TitleContainer>
                      <MobileIconContainer>
                        <Link to="/catalog">
                          <IconWrapper>
                            <i className={'flex-icon icon-catalogo'}></i>
                          </IconWrapper>
                          <p>Catálogo</p>
                        </Link>
                      </MobileIconContainer>
                      <Column md={2} className="desktop-wrapper">
                        <Link to="/catalog">
                          <IconContainer>
                            <IconWrapper>
                              <i className={'flex-icon icon-catalogo'}></i>
                            </IconWrapper>
                            <p>Ir al catálogo de premios</p>
                          </IconContainer>
                        </Link>
                      </Column>
                      <Column md={10}>
                        <Title>
                          <h2>
                            Carrito <br />
                            de Compras
                          </h2>
                        </Title>
                      </Column>
                    </TitleContainer>

                    {items.map((item: any, index: number) => {
                      const {
                        pointCost,
                        product: { name, assets },
                        inventory: { storageStock, localStock },
                        quantity
                      } = item
                      let image = productExample
                      if (assets && assets.length > 0) {
                        const composeAssets =
                          item.assets && item.assets.length > 0
                            ? item.assets
                            : assets
                        image = composeAssets.sort(function (a: any, b: any) {
                          if (a.order > b.order) {
                            return 1
                          }
                          if (a.order < b.order) {
                            return -1
                          }
                          return 0
                        })[0].url
                      }
                      if (quantity >= 0) {
                        return (
                          <MarginRow key={index}>
                            <Column md={7}>
                              <ItemContainer>
                                <MobileProduct>
                                  {/* <LinkProduct
                                    to={`/product/${slug}`}
                                    key={index}
                                  > */}
                                  <ImgContainer>
                                    <Img src={image} />
                                  </ImgContainer>
                                  {/* </LinkProduct> */}
                                  <MobileQuantity>
                                    <ItemContainer>
                                      <p>Cantidad:</p>
                                      <QuantityContainer>
                                        <Quantity>{quantity}</Quantity>
                                        <RightQuantity>
                                          <QuantityButton
                                            backgroundcolor={'#e9eff4'}
                                            fontcolor={'#808fa3'}
                                            onClick={() => {
                                              localStock + storageStock >
                                                quantity &&
                                                10 > quantity &&
                                                onAddToCart &&
                                                onAddToCart(item, 1)
                                            }}
                                          >
                                            <i className="flex-icon icon-sumar" />
                                          </QuantityButton>

                                          <QuantityButton
                                            backgroundcolor={'#e9eff4'}
                                            fontcolor={'#808fa3'}
                                            onClick={() => {
                                              if (
                                                quantity > 1 &&
                                                onDelFromCart
                                              ) {
                                                onDelFromCart(item)
                                              } else if (quantity == 1) {
                                                openModal('CONFIRM_DELETE', {
                                                  title: '',
                                                  description:
                                                    '¿Está seguro de que desea eliminar el producto?',
                                                  functionOK: deleteProduct.call(
                                                    {},
                                                    name,
                                                    index
                                                  )
                                                })
                                              }
                                            }}
                                          >
                                            <i className="flex-icon icon-restar" />
                                          </QuantityButton>
                                        </RightQuantity>
                                      </QuantityContainer>
                                    </ItemContainer>

                                    <ItemContainer>
                                      <IconButton
                                        onClick={() => {
                                          openModal('CONFIRM_DELETE', {
                                            title: '',
                                            description:
                                              '¿Está seguro de que desea eliminar el producto?',
                                            functionOK: deleteProduct.call(
                                              {},
                                              name,
                                              index
                                            )
                                          })
                                        }}
                                        icon="borrar"
                                        color="#ffd000"
                                        backgroundcolor="#f4f8f9"
                                        sizeicon="2rem"
                                      />
                                    </ItemContainer>
                                  </MobileQuantity>
                                </MobileProduct>
                                <StockContainer>
                                  {/* <LinkProduct
                                    to={`/product/${slug}`}
                                    key={index}
                                  > */}
                                  <Name>{item.product.name}</Name>
                                  <span className="summary">
                                    {item.product.summary}
                                  </span>
                                  <Bluelabel>
                                    Stock: {storageStock + localStock}
                                  </Bluelabel>
                                  <Bluelabel>
                                    Costo: {formatNumber(pointCost)}
                                  </Bluelabel>
                                  {/* </LinkProduct> */}
                                </StockContainer>
                              </ItemContainer>
                            </Column>
                            <Column md={1}></Column>
                            <Column md={2} className="desktop_wrapper">
                              <ItemContainer>
                                <QuantityContainer>
                                  <Quantity>{quantity}</Quantity>
                                  <RightQuantity>
                                    <QuantityButton
                                      backgroundcolor={'#e9eff4'}
                                      fontcolor={'#808fa3'}
                                      onClick={() => {
                                        localStock + storageStock > quantity &&
                                          10 > quantity &&
                                          onAddToCart &&
                                          onAddToCart(item, 1)
                                      }}
                                    >
                                      <i className="flex-icon icon-sumar" />
                                    </QuantityButton>

                                    <QuantityButton
                                      backgroundcolor={'#e9eff4'}
                                      fontcolor={'#808fa3'}
                                      onClick={() => {
                                        if (quantity > 1 && onDelFromCart) {
                                          onDelFromCart(item)
                                        } else if (quantity == 1) {
                                          openModal('CONFIRM_DELETE', {
                                            title: '',
                                            description:
                                              '¿Está seguro de que desea eliminar el producto?',
                                            functionOK: deleteProduct.call(
                                              {},
                                              name,
                                              index
                                            )
                                          })
                                        }
                                      }}
                                    >
                                      <i className="flex-icon icon-restar" />
                                    </QuantityButton>
                                  </RightQuantity>
                                </QuantityContainer>
                              </ItemContainer>
                            </Column>
                            <Column md={2} className="desktop_wrapper">
                              <ItemContainer>
                                <IconButton
                                  onClick={() => {
                                    openModal('CONFIRM_DELETE', {
                                      title: '',
                                      description:
                                        '¿Está seguro de que desea eliminar el producto?',
                                      functionOK: deleteProduct.call(
                                        {},
                                        name,
                                        index
                                      )
                                    })
                                  }}
                                  icon="borrar"
                                  color="#ffd000"
                                  backgroundcolor="#f4f8f9"
                                  sizeicon="2rem"
                                />
                              </ItemContainer>
                            </Column>
                            <Column md={12}>
                              <Line />
                            </Column>
                          </MarginRow>
                        )
                      } else {
                        if (forceShowNotification && onRemoveFromCart) {
                          let result = forceShowNotification({
                            type: 'alert',
                            message: name + ' se ha quedado sin stock'
                          })

                          if (result) onRemoveFromCart(index)
                        }
                        return <div key={index} />
                      }
                    })}
                    {/*                     <ActionsCartContainer>
                      <Clean
                        onClick={() => {
                          openModal('CONFIRM_DELETE', {
                            title: '',
                            type: 'ok',
                            description:
                              '¿Está seguro de que desea eliminar todos los productos del carrito?',
                            functionOK: clearCart()
                          })
                        }}
                      >
                        Limpiar Carrito
                      </Clean>
                      <Continue to="/">Continuar Comprando</Continue>
                    </ActionsCartContainer> */}
                  </ProductListContainer>
                </StyledColumn>
                <AmountsContainer>
                  <TotalContainer>
                    <SpanPayment>{formatNumber(total)} Puntos</SpanPayment>
                    <SpanPayment className="total">TOTAL</SpanPayment>
                  </TotalContainer>
                  {validAmount && (
                    <ButtonContainer
                    /* onClick={() => {
                        handleClickonEvent('onClick', 'NEXT_CART_EVENT', items)
                      }} */
                    >
                      <NavButton
                        to="/billing"
                        backgroundColor={'#ffd000'}
                        color={primary}
                        id={'MKTPL_CAR_NEXT'}
                      >
                        Canjear
                      </NavButton>
                    </ButtonContainer>
                  )}
                </AmountsContainer>
              </StyledRow>
            </Wrapper>
          )
        }}
      </Modal>
    </>
  )
}

const AddTrackWithMutation = graphql(ADD_TRACKING_MUTATION)(ProductsList)

export default AddTrackWithMutation
