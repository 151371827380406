import React from 'react'
import PrehomeViewHandler from '../views/PrehomeViewHandler'

type SwitchProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

type PrehomeFallBackProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props
  return (
    <PrehomeViewHandler>
      {(hasToken: boolean) => {
        if (hasToken) {
          return <FallbackComponent {...rest} />
        } else {
          return <Component {...rest} />
        }
      }}
    </PrehomeViewHandler>
  )
}

const prehomeFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: PrehomeFallBackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  )
}

export default prehomeFallback
