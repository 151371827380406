import React from 'react'
import { ModalWrapper, ModalDataContainer, Title, TimesBtn } from './style'
import ContactForm from '../ContactForm'

interface ModalContainerProps {
  modalOpen: boolean
  closeModal?: any
}

const ContactModal = ({ modalOpen, closeModal }: ModalContainerProps) => {
  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={modalOpen}
      onRequestClose={() => closeModal && closeModal(false)}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#ffffff'}
          className={'header'}
        >
          <Title>
            <h1>Contáctanos</h1>
            <p>
              Ingresa tus datos completos y la explicación de tu requerimiento.
              En un plazo máximo de 72 horas daremos respuesta a tu inquietud.
            </p>
          </Title>
          <TimesBtn onClick={() => closeModal && closeModal(false)}>
            <i className="icon-cerrar" />
          </TimesBtn>
        </ModalDataContainer>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#f4f8f9'}
        >
          {closeModal ? (
            <ContactForm closeModal={closeModal} />
          ) : (
            <ContactForm />
          )}
        </ModalDataContainer>
      </>
    </ModalWrapper>
  )
}

export default ContactModal
