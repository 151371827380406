import React from 'react'

import { LoaderContainer, Gif } from './style'
import loaderGif from '../../assets/loader/loading.gif'

function Loader() {
  return (
    <LoaderContainer>
      <Gif src={loaderGif} />
    </LoaderContainer>
  )
}

export default Loader
