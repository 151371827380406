import styled from 'styled-components'
import sizes from '../../styles/media'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 60px;
    margin: 0 auto 1rem;
  }
  p {
    text-align: center;
  }
`
export const ContainerForm = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  @media ${sizes.md} {
    padding: 2rem;
  }

  @media ${sizes.xl} {
    padding: 2rem 12rem;
  }
  margin-bottom: 2rem;
  h2 {
    margin-bottom: 5px;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  label {
    color: #0066cc;
  }
  &.hidden-input {
    display: none;
  }
`

export const Hr = styled.hr`
  border: none;
  border-bottom: solid 1px #dadada;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  line-height: 2;
`
export const ContainerButton = styled.div`
  margin-top: 1rem;
  width: 100%;
`
