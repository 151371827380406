import React from 'react'
import { Container, Column, Row } from '../../components/Grid'
import Layout from '../../components/Layout'
import ContactForm from '../../components/ContactForm'
import { Wrapper, Title } from './style'

function Contact() {
  return (
    <Layout showBanner={true} showCart={true} currentPage={'contact'}>
      <Container id="MKTPL_HOME">
        <Wrapper>
          <Row>
            <Column md={10} lg={8} xl={7}>
              <Title>
                <h1>Importante</h1>
                <p>
                  Ingresa tus datos completos y la explicación de tu
                  requerimiento. En un plazo máximo de 72 horas daremos
                  respuesta a tu inquietud.
                </p>
              </Title>
            </Column>
          </Row>
          <Row>
            <div style={{ width: '100%' }}>
              <Column md={12}>
                <ContactForm />
              </Column>
            </div>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  )
}
export default Contact
