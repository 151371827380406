import React, { useContext, useState, useEffect } from 'react'
import Prelayout from '../../components/Prelayout'
import ContactModal from '../../components/ContactModal'
import RegisterModal from '../../components/RegisterModal'
import LoaderButton from '../../components/LoaderButton'
import FormModal from '../../components/FormModal'
import { graphql, withApollo, compose } from 'react-apollo'
import { Link } from 'react-router-dom'
import {
  Wrapper,
  Header,
  Footer,
  Content,
  LogoWrapper,
  FormWrapper,
  FlexCol,
  Cards,
  Card,
  CircleWrapper,
  Circle,
  Dot,
  ButtonContainer,
  Title,
  ButtonWrapper,
  FlexColButton
} from './style'
import logo from '../../assets/header/nova-logo.svg'
import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
//import * as Grid from '../../components/Grid'
import { Button } from '../../components/Buttons'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import logopyp from '../../assets/header/logopyp.svg'
//import AccountData from './data'
import { LOGIN_MUTATION } from './query'
import queryString from 'query-string'

type UserInput = {
  username: string
  password: string
}
function Prehome({ mutate, history }: any) {
  const token = queryString.parse(history.location.search).token
    ? queryString.parse(history.location.search).token
    : ''
  const { onSetAccountData, onSetSession } = useContext(CartContext)
  const { btnPrimary, btnSecondary, third } = useContext(ThemeContext)
  const [openLogin, setOpenLogin] = useState(false)
  const [openContact, setOpenContact] = useState(false)
  const [openRegister, setOpenRegister] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [user, setUser] = useState({} as UserInput)
  const [error, setError] = useState({
    username: false,
    password: false,
    invalid: false
  })
  const [tokenById, setToken] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleShowForm = (token: string) => {
    setToken(token)
    setOpenRegister(false)
    setOpenForm(true)
  }

  const validate = () => {
    if (!user.username || user.username.length === 0) {
      setError((prevState: any) => ({
        ...prevState,
        username: true
      }))
      return { error: true, message: 'Usuario inválido' }
    }
    if (!user.password || user.password.length === 0) {
      setError((prevState: any) => ({
        ...prevState,
        password: true
      }))
      return { error: true, message: 'Contraseña inválida' }
    }

    return { error: false, message: '' }
  }
  const handleClic = async () => {
    const validation = validate()
    if (validation.error) {
      setMessage(validation.message)
    } else {
      setLoading(true)
      mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          input: {
            username: user.username,
            password: user.password
          }
        }
      })
        .then(async (orderResult: any) => {
          if (
            orderResult.data &&
            orderResult.data.login &&
            orderResult.data.login.token
          ) {
            //Static data for testing
            /* if (!orderResult.data.login.files) {
              orderResult.data.login.files = AccountData.assets
            } */
            //orderResult.data.login.balances = AccountData.balances

            onSetAccountData && (await onSetAccountData(orderResult.data.login))
            onSetSession && (await onSetSession(orderResult.data.login.token))
            history.push('/home')
          }
        })
        .catch((errors: any) => {
          setLoading(false)
          setError((prevState: any) => ({
            ...prevState,
            invalid: true
          }))
          if (
            errors &&
            errors.message &&
            errors.message === 'GraphQL error: inactive user'
          ) {
            setMessage('Usuario inactivo')
          } else {
            setMessage('Usuario o contraseña incorrectos')
          }
        })
    }
  }
  const handleChange = (e: any) => {
    const { id, value } = e.target
    switch (id) {
      case 'username':
        setUser((prevState: any) => ({
          ...prevState,
          username: value
        }))
        setError((prevState: any) => ({
          ...prevState,
          username: false,
          invalid: false
        }))
        break
      case 'password':
        setUser((prevState: any) => ({
          ...prevState,
          password: value
        }))
        setError((prevState: any) => ({
          ...prevState,
          password: false,
          invalid: false
        }))
        break

      default:
        break
    }
  }
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleClic()
    }
  }
  useEffect(() => {
    if (
      queryString.parse(history.location.search).token &&
      queryString.parse(history.location.search).token !== ''
    ) {
      setOpenForm(true)
    }
  }, [])
  return (
    <Prelayout
      loginOpen={openLogin}
      registerOpen={openRegister}
      contactOpen={openContact}
    >
      <Wrapper>
        <Header>
          <LogoWrapper>
            <img src={logo} alt="" />
            <Circle
              onClick={() => setOpenLogin(!openLogin)}
              className={openLogin ? 'profile active' : 'profile'}
            >
              <i className={openLogin ? 'icon-cerrar' : 'icon-cliente'}></i>
            </Circle>
          </LogoWrapper>
          <FormWrapper className={openLogin ? 'opened' : ''}>
            <FlexCol>
              <Input
                id="username"
                type="text"
                label="Usuario"
                borderColor="#e9eff4"
                placeholder="Email"
                value={user.username}
                onChange={(e) => handleChange(e)}
              />
            </FlexCol>
            <FlexCol>
              <Input
                id="password"
                type="password"
                label="Contraseña"
                borderColor="#e9eff4"
                placeholder="Contraseña"
                value={user.password}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e: any) => {
                  handleEnter(e)
                }}
              />
              <Link to={'/remember'}>
                <p className="forgot-password">¿Olvidaste tu contraseña?</p>
              </Link>
            </FlexCol>
            <FlexColButton>
              <ButtonContainer>
                <Button
                  backgroundColor={
                    loading ? '#ffffff' : btnPrimary ? btnPrimary : '#000000'
                  }
                  color={third ? third : '#ffffff'}
                  bordercolor={loading ? '#ffffff' : btnPrimary}
                  id={'MKTPL_LOGIN'}
                  onClick={() => handleClic()}
                  padding={loading ? '0 0.5rem' : '0.65rem 0.5rem 0.65rem'}
                  disabled={loading}
                >
                  {loading ? <LoaderButton /> : 'Ingresar'}
                </Button>
              </ButtonContainer>

              {error.username || error.password || error.invalid ? (
                <Error>{message}</Error>
              ) : (
                <Spacer />
              )}
            </FlexColButton>
          </FormWrapper>
        </Header>
        <Content>
          <Title>
            <img src={logopyp} alt="" />
            <h1>
              Bienvenido a nuestro <br />
              Plan de Recompensas
            </h1>
          </Title>
          <p>
            Ahora Puntos y Premios recompensa tu esfuerzo y compromiso. Descubre
            todos los beneficios que puedes obtener canjeando los puntos
            acumulados por increibles premios.
          </p>
          <p>Conozca nuestro canal oficial de ayuda</p>
          <ButtonContainer>
            <ButtonWrapper>
              <Button
                backgroundColor={'transparent'}
                color={btnSecondary ? btnSecondary : '#f1bf06'}
                bordercolor={btnSecondary ? btnSecondary : '#f1bf06'}
                hoverColor={'#000000'}
                onClick={() => {
                  setOpenRegister(!openRegister)
                }}
                id={'MKTPL_SHIP_NEXT'}
              >
                Regístrate
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                backgroundColor={'transparent'}
                color={third ? third : '#ffffff'}
                bordercolor={third ? third : '#ffffff'}
                hoverColor={'#000000'}
                onClick={() => {
                  setOpenContact(!openContact)
                }}
                id={'MKTPL_SHIP_NEXT'}
              >
                Contáctanos
              </Button>
            </ButtonWrapper>
          </ButtonContainer>
        </Content>
        <Cards>
          <Card>
            <CircleWrapper>
              <Circle>
                <i className="flex-icon icon-editar" />
              </Circle>
              <Dot />
            </CircleWrapper>
            <h3>Inscríbete</h3>
            <p>
              Con los datos que te llegaron <br />a tu correo electrónico.
            </p>
          </Card>
          <Card>
            <CircleWrapper>
              <Circle>
                <i className="flex-icon icon-notificacion" />
              </Circle>
              <Dot />
              <Dot />
            </CircleWrapper>
            <h3>Acumula</h3>
            <p>
              Puntos por la colocación de productos <br />y servicios
              participantes.
            </p>
          </Card>
          <Card>
            <CircleWrapper>
              <Circle>
                <i className="flex-icon icon-carrito_lleno" />
              </Circle>
              <Dot />
              <Dot />
              <Dot />
            </CircleWrapper>
            <h3>Canjea</h3>
            <p>
              Tus puntos por espectaculares <br />
              premios de nuestro catálogo
            </p>
          </Card>
        </Cards>
        <Footer></Footer>
      </Wrapper>
      <ContactModal modalOpen={openContact} closeModal={setOpenContact} />
      <RegisterModal
        modalOpen={openRegister}
        closeModal={setOpenRegister}
        handleShowForm={handleShowForm}
        openContact={setOpenContact}
      />
      <FormModal
        modalOpen={openForm}
        closeModal={setOpenForm}
        token={token ? (token as string) : tokenById}
      />
    </Prelayout>
  )
}

const PrehomeMutate = compose(graphql(LOGIN_MUTATION))(withApollo(Prehome))

export default PrehomeMutate
