import styled from 'styled-components'
import sizes from '../../styles/media'
import { Container, Row } from '../../components/Grid'

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }
`
export const FileWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`
export const File = styled.div`
  border: 0px solid #0066cc;
  padding: 12px 13px 11px 13px;
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
  border-radius: 2px;
  background-color: #0066cc;
  color: white;
  text-align: center;
  width: 100%;
  position: relative;
  letter-spacing: 0.13px;

  input[type='file'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  @media ${sizes.sm} {
    width: 70%;
  }
`
export const Preview = styled.div`
  width: 25%;
  height: 100%;
  display: none;
  align-items: center;
  img {
    height: 100%;
    max-height: 40px;
    max-width: 100%;
  }

  @media ${sizes.sm} {
    display: flex;
  }
`
export const ContainerForm = styled.div`
  width: 100%;
  padding: 2rem 1rem;

  @media ${sizes.md} {
    padding: 2rem;
  }

  @media ${sizes.xl} {
    padding: 2rem 12rem;
  }
  margin-bottom: 2rem;
  h2 {
    margin-bottom: 5px;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
    color: #0066cc;
  }
  label {
    color: #0066cc;
  }
  &.hidden-input {
    display: none;
  }
`

export const Hr = styled.hr`
  border: none;
  border-bottom: solid 1px #dadada;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  line-height: 2;
`
export const ContainerButton = styled.div`
  margin-top: 1rem;
`

export const StyledRow = styled(Row)`
  flex-direction: row;
`
export const Title = styled.div`
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  h1 {
    color: #014178;
    font-size: 1.8rem;
    font-weight: normal;
    margin: 0 0 1.2rem;
  }
  p {
    margin: 0 0 2rem;
  }
`
