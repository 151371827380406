import React from 'react'
import { SliderContainer, StyledSlickCarousel } from './style'
import Assets from '../../model/Assets'

type Images = {
  assets: Assets[]
}

const SliderProduct = ({ assets }: Images) => {
  const settingsNew = {
    customPaging: function (i: number) {
      return (
        <a>
          <img
            className="thumb-image"
            src={
              assets[i] && assets[i].url
                ? assets[i].url
                : `https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg`
            }
          />
        </a>
      )
    },
    dots: false,
    swite: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <>
      <SliderContainer style={{ display: 'none' }}></SliderContainer>
      <StyledSlickCarousel {...settingsNew}>
        {assets
          .sort((a: Assets, b: Assets) => {
            return a.order < b.order ? -1 : 1
          })
          .slice(0, 4)
          .map((asset: Assets, index: number) => {
            return (
              <div key={index} className="slider-item">
                <img className="slider-image" src={asset.url} />
              </div>
            )
          })}
        {/* <div className='slider-item'>
          <img
            className='slider-image'
            src={
              'https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg'
            }
          />
        </div>
        <div className='slider-item'>
          <img
            className='slider-image'
            src={
              'https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract02.jpg'
            }
          />
        </div>
        <div className='slider-item'>
          <img
            className='slider-image'
            src={
              'https://s3.amazonaws.com/static.neostack.com/img/react-slick/abstract01.jpg'
            }
          />
        </div> */}
      </StyledSlickCarousel>
    </>
  )
}

export default SliderProduct
