import gql from 'graphql-tag'

const UPLOAD_PROFILE_IMAGE = gql`
  mutation UploadFiles($file: Upload!) {
    uploadProfileFile(file: $file) {
      url
      fileName
      id
    }
  }
`

const EDIT_MEMBER = gql`
  mutation UpdateMember($input: MemberInput!) {
    editMemberData(input: $input)
  }
`

const DELETE_PROFILE_IMAGE = gql`
  mutation DeleteProfileFile($id: String) {
    deleteProfileFile(id: $id)
  }
`

export { UPLOAD_PROFILE_IMAGE, EDIT_MEMBER, DELETE_PROFILE_IMAGE }
