export const customStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    height: '40px',
    'min-height': '40px',
    border: 'solid 1px #e9eff4'
    // border: "solid 1px #d9d9d9"
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided
  })
}

export const errorStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '2px',
    height: '40px',
    'min-height': '40px',
    border: 'solid 1px red'
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    overflowX: 'hidden',
    overflowY: 'hidden'
  })
}

export const identificationTypeOptions = [
  { value: 'CI', label: 'Cédula de Identidad' },
  { value: 'PPN', label: 'Pasaporte' }
]
