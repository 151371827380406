import styled from 'styled-components'

export const LoaderContainer = styled.div`
  margin: auto;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0;
`

export const Gif = styled.img`
  max-width: auto;
  height: 2.55rem;
  max-height: 2.55rem;
  margin: auto;
`
