const getdata = (
  status: string,
  requestId: string,
  signature: string,
  textDelivery: string,
  emailCustomer: string,
  orderId: string,
  reference: string
) => {
  switch (status) {
    case 'REJECTED':
      return {
        title: 'TRANSACCIÓN RECHAZADA',
        message: '',
        body: [
          `Lo sentimos,tu transacción #${reference} ha sido rechazado. Por favor reintenta`,
          `con otro medio de pago o comunícate con tu banco emisor.`,
          `<br/>`,
          `<br/>`
        ],
        image: 'resumeRejected.svg',
        analyticId: ' ID ANALITIC REJECTED',
        requestId: requestId,
        signature: signature,
        status: status,
        orderId: orderId,
        orderStatus: 'rejected',
        emailCustomer: emailCustomer,
        reference: reference
      }

    case 'PENDING':
      return {
        title: 'EN PROCESO DE APROBACIÓN',
        message: '',
        body: [
          `Tu transacción #${reference} se encuentra en proceso de aprobación. Se te confirmará`,
          `por correo electrónico la aprobación o negación de tu pago.`,
          `Cualquier duda o consulta por favor comunícate con tu banco emisor.`,
          `<br/>`,
          `<br/>`
        ],
        image: 'resumePending.svg',
        analyticId: ' ID ANALITIC PENDING',
        requestId: requestId,
        signature: signature,
        status: status,
        orderId: orderId,
        orderStatus: 'processing',
        emailCustomer: emailCustomer,
        reference: reference
      }

    case 'APPROVED':
      return {
        title: `Tu transacción #${reference} ha sido realizada exitosamente. Empezaremos a gestionar tu pedido`,
        message: ``,
        body: [
          `<br/>`,
          `<span>Recibirás la confirmación de tu compra al correo: <b>${emailCustomer}</b></span>`,
          `<br/>`,
          `<br/>`,
          `<span>Por cualquier duda por favor comunícate a nuestro call center</span> `,
          `<span><a href="tel:02 5000444"><b>(02) 5000 444</b></a></span> `,
          `<br/>`
        ],
        image: 'resumeApprove.svg',
        analyticId: ' ID ANALITIC APPROVED',
        requestId: requestId,
        signature: signature,
        status: status,
        orderId: orderId,
        orderStatus: 'paid',
        emailCustomer: emailCustomer,
        reference: reference
      }
    default:
      return {
        title: '',
        message: '',
        body: [],
        image: '',
        analyticId: '',
        requestId: requestId,
        signature: signature,
        status: status,
        orderId: orderId,
        orderStatus: '',
        emailCustomer: emailCustomer,
        reference: reference
      }
  }
}

export default getdata
