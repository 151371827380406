import React, { useEffect, useContext } from 'react'
import Checkout from '../../components/Checkout'
import ProductList from '../../components/ProductsList'
//import { Container } from '../../components/Grid'
import CartContext from '../../context/CartContext'
import Loader from '../../components/loader'
import * as Grid from '../../components/Grid'

function CartView() {
  const { loaded, onSetLoaded, updateItems } = useContext(CartContext)
  useEffect(() => {
    const response = window.localStorage.getItem('response') || ''
    !loaded &&
      updateItems &&
      !response &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })
  return (
    <Checkout>
      <div className="animated fadeIn MKTPL_HOME_CAR" style={{ width: '100%' }}>
        {loaded ? (
          <ProductList />
        ) : (
          <Grid.Container>
            <Loader />
          </Grid.Container>
        )}
      </div>
    </Checkout>
  )
}

export default CartView
