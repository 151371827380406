import styled from 'styled-components'
import sizes from '../../styles/media'

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  color: #014178;
  h3 {
    margin: 0;
    font-size: 1.6rem;
    font-weight: normal;
    @media ${sizes.md} {
      font-size: 2.1rem;
    }
  }
  p {
    color: #0066cc;
    margin: 0.5rem 0 0;
  }
  a {
    padding: 0.4rem 0 0 0.2rem;
    color: unset;

    &:visited {
      color: unset;
    }

    &:active {
      color: unset;
    }

    &:hover {
      text-decoration: none;
    }
  }
`

export const Wrapper = styled.div`
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;
  padding: 1rem;
`
