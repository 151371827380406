import React from 'react'
import { Container, Column, Row } from '../../components/Grid'
import Layout from '../../components/Layout'
import ProfileForm from '../../components/ProfileForm'
import { Wrapper, Title } from './style'

function Contact() {
  return (
    <Layout showBanner={true} showCart={true} currentPage={'profile'}>
      <Container id="MKTPL_HOME">
        <Wrapper>
          <Row>
            <Column md={10} lg={8} xl={7}>
              <Title>
                <h1>Mis datos</h1>
              </Title>
            </Column>
          </Row>
          <Row>
            <div style={{ width: '100%' }}>
              <Column md={12}>
                <ProfileForm />
              </Column>
            </div>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  )
}
export default Contact
