import { createGlobalStyle } from 'styled-components'
import 'normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-input-range/lib/css/index.css'

export const GlobalStyle = createGlobalStyle`

  html {
      font-family: 'Prelo';
      font-size: 14px;
      /* min-height: 100%;
      height: 100%; */
      body{
          background-color: #014178;
          /* min-height: 100%; */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          *, ::after, ::before{
            box-sizing: border-box;
          }
          #root{
            /* min-height: 100vh; */
            display: flex;
          }
        } 
  }

  .animate-spin {
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    display: inline-block;
  }
  
  input[type=search]{
    -webkit-appearance: none;
  }
  
  @-moz-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-o-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-ms-keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -moz-transform: rotate(359deg);
      -o-transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  input, textarea{
      box-shadow: inset 0px 0px 0px 0px red;
  }

  input, textarea, button {
      font-size: 14px;
      padding: 0;
  }

  h2 {
      font-size: 1.75rem;
  }

  h3 {
      font-size: 18px;
  }
  
  h2, h3 {
      font-weight: bold;
  }
  h1, h2, h3, h4 {
      font-weight: 600;
      font-family: 'PreloSlab';
  }

  a {
      text-decoration: none;
  }

  .kronos-icon
  {
    line-height: 0em;
  }

  .flex-icon{
    line-height: 0em;
  }

  .collapsible{

    display: block;
    font-weight: 400;
    text-decoration: none;
    color: black;
    position: relative;
    padding: 10px;

    &:after {
      content: '*';
      position: absolute;
      left: 0px;
      top: 35px;
      display: block;
      transition: transform 300ms;
    }

    &.is-open {
        &:after {
        transform: rotateZ(180deg);
      }
    }

  }

  .responsive-table-description{
    table{
      table-layout: fixed;
      td{
        padding: 10px 20px 5px 20px;
        word-wrap:break-word;
        &:first-child {
          width: 40% ;
        }
        &:nth-child(2) {
          width: 60% ;
        }
      }
      @media only screen and (max-width: 700px) {
        td{
          padding: 10px 10px 5px 10px !important;
          width: 50% !important;
        }
      }
    }
  }


    
  /* // Animaciones CSS */
  .animated {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }


  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }


.slick-prev:before,
.slick-next:before {
  color: #11234D !important;
  opacity: 1 !important;
}

.slick-dots li button{
  height: 40px;
  width: 40px;
}

.slick-dots li button:before{
  font-size: 12px;
}

&.collapsedTranslate {
  top: 345px !important;
}

&.loggedin {
  @media screen and (min-width: 375px){
    top: 128px !important;
  }

  @media screen and (min-width: 1024px){
    top: 242px !important;
  }
}

.showing {
  opacity: 1 !important;
  transition: all .3s ease-out;
}

.show {
  display: block;
  opacity: 1 !important;
}

.hide {
  display: none !important;
}

.fixedRegister {
  @media screen and (max-width: 375px){
    position: fixed !important;
    top: 75px !important;
  }
}

.fixedContact {
  @media screen and (max-width: 375px){
    position: fixed !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

.modal-link {
  text-decoration-line: underline;
  font-weight: 600;
  color: #0F265C;
}

.checkboxs__custom {
  margin: 0 !important;
  width: 20px;
  height: 20px;
  border: 5px solid #0F265C;
  border-radius: 4px;
}

.btnAccept--active {
  background: #0066CC !important;
  cursor: pointer !important;
}
`
