import gql from 'graphql-tag'

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      document
      id
      programCode
      miles
      fullName
      firstName
      firstName2
      lastName
      lastName2
      phone
      mobile
      token
      email
      registered
      passwordChanged
      terms
      lopd
      balances {
        id
        total
        currency {
          id
          name
          description
        }
      }
      files {
        id
        type
        url
      }
    }
  }
`
export { LOGIN_MUTATION }
