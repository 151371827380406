import React from 'react'
import Layout from '../../components/Prelayout'

import notFound from '../../assets/errorCode/404.svg'
import serverError from '../../assets/errorCode/500.svg'

import { StyledImage, StyledColumn, Wrapper, StyledRow } from './style'

type Props = {
  errorCode: number
}

function Error({ errorCode }: Props) {
  const getErrorCodeView = (errorCode: number) => {
    switch (errorCode) {
      case 500:
        return (
          <>
            <StyledImage src={serverError} />
            <span>Error de comunicación con servicios</span>
          </>
        )
      default: {
        return <StyledImage src={notFound} />
      }
    }
  }

  return (
    <Layout backgroundColor={'#353535'}>
      <Wrapper>
        <StyledRow>
          <StyledColumn md={12}>{getErrorCodeView(errorCode)}</StyledColumn>
        </StyledRow>
      </Wrapper>
    </Layout>
  )
}

export default Error
