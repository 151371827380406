import React, { useContext, useState } from 'react'
import Prelayout from '../../components/Prelayout'
import { graphql, withApollo, compose } from 'react-apollo'
import {
  Wrapper,
  Header,
  FormWrapper,
  FlexCol,
  ContainerButton,
  Message,
  WrapperButtons
} from './style'
import logo from '../../assets/header/nova-logo-blanco.svg'
import bg from '../../assets/home/faded_bg.jpg'
import bgMobile from '../../assets/home/faded_bg_mobile.jpg'
import { Input, Error } from '../../components/FormElements'
import { Button } from '../../components/Buttons'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import { FORGET_PASSWORD_QUERY } from './query'
import { Spacer } from '../../components/Global/style'

function RememberPage({ mutate, history, client }: any) {
  const { btnPrimary } = useContext(ThemeContext)
  const { forceShowNotification } = useContext(CartContext)

  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [sent, setSent] = useState(false)
  const handleClic = async () => {
    if (
      !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw]{2,4})(\]?)$/.test(
        email
      )
    ) {
      setError(true)
    } else {
      const { data } = await client.query({
        query: FORGET_PASSWORD_QUERY,
        variables: {
          email: email
        }
      })
      if (data) {
        if (data.forgotPassword && data.forgotPassword.email) {
          setSent(true)
        } else {
          if (forceShowNotification) {
            forceShowNotification({
              type: 'alert',
              message: 'No se encontró el usuario.'
            })
          }
        }
      } else {
        if (forceShowNotification) {
          forceShowNotification({
            type: 'fail',
            message: 'Algo salió mal. Inténtalo más tarde.'
          })
        }
      }
    }
  }
  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      handleClic()
    }
  }
  const handleChange = (e: any) => {
    setError(false)
    setEmail(e.target.value)
  }
  const handleBack = () => {
    history.push('/')
  }
  return (
    <Prelayout hiddenToast={true}>
      <Wrapper>
        <Header onClick={() => history.push('/')}>
          <img src={bg} className="background desktop" />
          <img src={bgMobile} className="background mobile" />
          <img src={logo} className="logo" />
        </Header>
        <FormWrapper>
          <h1>¿Olvidaste tu contraseña?</h1>
          {sent ? (
            <>
              <Message>
                Se ha enviado una notificación para restablecer su contraseña a
                su correo.
              </Message>

              <ContainerButton className={'lonely'}>
                <Button
                  backgroundColor={btnPrimary}
                  color={'#FFFFFF'}
                  onClick={handleBack}
                >
                  Aceptar
                </Button>
              </ContainerButton>
            </>
          ) : (
            <>
              <p>
                Ingrese su correo electrónico registrado y le enviaremos un
                enlace para recuperar su contraseña.
              </p>
              <FlexCol>
                <Input
                  id="email"
                  label="Correo electrónico"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e: any) => {
                    handleEnter(e)
                  }}
                />
                {error ? <Error>Ingrese un correo válido</Error> : <Spacer />}
              </FlexCol>
              <WrapperButtons>
                <ContainerButton>
                  <Button
                    backgroundColor={'#f4f8f9'}
                    color={btnPrimary ? btnPrimary : '#000000'}
                    bordercolor={btnPrimary ? btnPrimary : '#000000'}
                    onClick={handleBack}
                  >
                    Regresar
                  </Button>
                </ContainerButton>
                <ContainerButton>
                  <Button
                    backgroundColor={btnPrimary}
                    color={'#FFFFFF'}
                    onClick={handleClic}
                  >
                    Aceptar
                  </Button>
                </ContainerButton>
              </WrapperButtons>
            </>
          )}
        </FormWrapper>
      </Wrapper>
    </Prelayout>
  )
}

const RememberWithApollo = compose(graphql(FORGET_PASSWORD_QUERY))(
  withApollo(RememberPage)
)

export default RememberWithApollo
