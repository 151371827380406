import gql from 'graphql-tag'

const FORGET_PASSWORD_QUERY = gql`
  query ForgotPassword($email: String) {
    forgotPassword(email: $email) {
      email
    }
  }
`
export { FORGET_PASSWORD_QUERY }
