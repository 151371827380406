import styled from 'styled-components'
import sizes from '../../../../styles/media'
interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  span {
    font-family: 'Muller-Bold';
  }
`

export const SummaryContainer = styled.div`
  ${(props: Props) => {
    return `
      width: 100%;
      background-color: ${props.backgroundcolor};
      padding: 0px 16px 4px 16px;
      box-shadow: 0 0px 4px 0 #d9d9d9;
      @media ${sizes.md} {
        max-height: 390px;
        overflow-y: auto;
      }
    `
  }};
`

export const ProductContainer = styled.div`
  width: 100%;
  border-bottom: solid 1px #b3c4d7;
  display: flex;
  padding-bottom: 13px;
  padding-top: 22px;
`

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 84px;
  max-height: 87px;
  margin-right: 13px;
  border: solid 1px #dadada;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
`

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProductName = styled.div`
  width: 100%;
  height: 50px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  span {
    line-height: 145%;
  }
`

export const DetailPayment = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
    box-shadow: 0 3px 4px 0 #d9d9d9;
    `
  }}
`

export const SpanPayment = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-family: 'Muller-Bold';
    `
  }}
`

export const TotalContainer = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    line-height: 1.43;
    padding: 1rem 16px 4px 16px;
    box-shadow: 0 3px 4px 0 #d9d9d9;
  `
  }}
`
