import styled from 'styled-components'
import sizes from '../../styles/media'
import Select from 'react-select'
import { Column } from '../Grid'

export const StyledColumn = styled(Column)`
  display: flex;
  padding: 0;
  margin: 0.3rem 0;
  @media ${sizes.sm} {
    margin: 0;
    padding: 15px;
    flex-direction: row;
  }
`

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  input {
    margin-right: 1rem;
  }
  p {
    margin: 0 0 0 1rem;
  }
  .checkbox-selected {
    background-color: #0066cc !important;
  }
  .checkbox {
    outline: none;
    outline-color: transparent;
    min-width: 28px;
  }
`
export const StyledFinder = styled.div`
  display: flex;
  width: 100%;
  label {
    display: none;
  }
  input {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 2px 3px 11px 0 #f1f1f1;
  background-color: #ffffff;
  padding: 1.7rem 0.5rem;
  @media ${sizes.lg} {
    flex-direction: row;
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
`
export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#000000',
    backgroundColor: state.isSelected ? 'rgba(0, 66, 179, 0.2)' : '#ffffff',
    padding: 20,
    fontSize: '0.9rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 66, 179, 0.2)'
    },
    img: {
      width: '18px',
      marginRight: '0.5rem'
    }
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    height: '40px',
    'min-height': '40px',
    border: 'solid 1px #e9eff4'
    // border: "solid 1px #d9d9d9"
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '0 10px 12px 0 rgba(0, 0, 0, 0.15)'
  }),
  singleValue: (provided: any, state: any) => {
    const color = '#000000'
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition, color }
  }
  //dropdownIndicator: () => ({}),
  //indicatorSeparator: () => ({})
}
