import styled from 'styled-components'
import sizes from '../../styles/media'
import { Column } from '../../components/Grid'
import { Link } from 'react-router-dom'
import Icon from '../Icon'

export const Img = styled.img`
  width: 100%;
  margin: auto;
  @media ${sizes.md} {
    width: 60%;
  }
`

export const ImgNova = styled.img`
  width: 100%;
  margin: auto;
  @media ${sizes.md} {
    width: 48%;
    max-width: 150px;
  }
`
export const Burger = styled.img`
  width: 1.5rem;
`

export const HomeButton = styled.div`
  display: none;

  a {
    text-transform: uppercase;
    width: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'Muller-Regular';
    font-weight: bold;
    padding: 0.6rem 0rem 0.6rem;
    font-weight: bold;
    line-height: 1.2;
    border-radius: 20px;
    font-size: 12px;
    background-color: #4e8ee5;
    color: #ffffff;
    transition: 0.2s ease-in;
  }

  @media ${sizes.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const TextCart = styled.span`
  padding-top: 0.25rem;
  color: #f7f7f7;
  display: none;
  @media ${sizes.md} {
    display: inline-block;
  }
`

export const Cart = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  position: relative;
`

export const Oval = styled.span`
  font-family: Arial;
  color: #ffffff;
  background: red;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: -0.6rem;
  right: 0rem;
  width: 18px;
  height: 18px;
  padding-top: 3px;
`

export const BurguerContainer = styled(Column)`
  display: flex;
  align-items: center;
  @media ${sizes.md} {
    display: none;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  width: 85%;
  margin: 1.3rem auto;
  align-items: center;
  justify-content: space-between;
  height: auto;
  @media ${sizes.md} {
    margin: auto;
    height: 25vh;
    justify-content: center;
  }
`

export const LogoNovaContainer = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  position: relative;
  z-index: 2;
  @media ${sizes.md} {
    margin-top: 10%;
    width: 100%;
  }
  @media (max-height: 650px) and (min-width: 768px) {
    margin-top: 0;
  }
`

export const StyledLogoLinkContainer = styled(Link)`
  width: 40%;
  max-width: 40%;
  margin: 0.25rem;
  display: flex;
  @media ${sizes.md} {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
`

export const ActionLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 0 0 auto;
  background-color: #f4f8f9;
`

export const Colapsable = styled.div`
  display: block;
  opacity: 0;
  max-height: 0;
  transition: all 300ms ease-in-out;
  &.opened {
    opacity: 1;
    max-height: 500px;
  }
  @media ${sizes.md} {
    opacity: 1;
    max-height: 500px;
  }
`

export const Wrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0%;
  padding: 0 0 1.5rem;
  @media ${sizes.md} {
    padding: 0;
  }
`
export const HeaderNoLinks = styled.div`
  cursor: pointer;
  font-family: 'PreloSlab';
  font-weight: normal;
  background-color: #014178;
  width: 100%;
  transition: 100ms ease-in;
  transition-delay: 100ms;
  padding: 0.2rem 0;
  color: white;
  display: flex;
  align-items: center;
  &.no-show {
    cursor: inherit;
    display: none;
    @media ${sizes.md} {
      display: flex;
    }
  }
  &.active {
    font-weight: 600;
    .bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: white;
      @media ${sizes.md} {
        display: none;
      }
    }
  }
  a {
    color: white;
    &:hover {
      color: white;
    }
    &:visited {
      color: white;
    }
  }

  @media ${sizes.md} {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
    margin: 0;
    &.right-before {
      border-radius: 0 0 28px 0;
    }
    &.right-after {
      border-radius: 0 28px 0 0;
    }
    &.active {
      padding: 0;
    }
  }
  span {
    display: flex;
    min-height: 2rem;
    width: 100%;
    padding: 0.65rem 0.8rem;
    position: relative;
    line-height: 0.9;
    letter-spacing: normal;
    align-items: center;
    transition: border-radius 400ms ease-in, background-color 200ms ease-in,
      color 200ms ease-in;

    @media (max-height: 650px) {
      padding: 0.5rem 0.8rem;
    }
    &.active {
      margin-left: -8px;
      @media ${sizes.md} {
        margin-left: 0;
        background-color: #f4f8f9;
        color: #494949;
        border-radius: 40px 0 0 40px;
        i {
          font-size: 1.7rem;
          color: #014178;
        }
      }
    }
    i {
      font-size: 1.7rem;
      color: #0066cc;
    }
  }
`
export const HeaderLinks = styled(Link)`
  font-family: 'PreloSlab';
  font-weight: normal;
  background-color: #014178;
  width: 100%;
  transition: 100ms ease-in;
  transition-delay: 100ms;
  padding: 0.2rem 0;
  color: white;
  display: flex;
  align-items: center;
  &.no-show {
    display: none;
    @media ${sizes.md} {
      display: flex;
    }
  }
  &.active {
    font-weight: 600;
    .bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: white;
      @media ${sizes.md} {
        display: none;
      }
    }
  }

  @media ${sizes.md} {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
    margin: 0;
    &.right-before {
      border-radius: 0 0 28px 0;
    }
    &.right-after {
      border-radius: 0 28px 0 0;
    }
    &.active {
      padding: 0;
    }
  }
  span {
    display: flex;
    min-height: 2rem;
    width: 100%;
    padding: 0.65rem 0.8rem;
    position: relative;
    line-height: 0.9;
    letter-spacing: normal;
    align-items: center;
    transition: border-radius 400ms ease-in, background-color 200ms ease-in,
      color 200ms ease-in;
    @media (max-height: 650px) {
      padding: 0.5rem 0.8rem;
    }
    &.active {
      margin-left: -8px;
      @media ${sizes.md} {
        margin-left: 0;
        background-color: #f4f8f9;
        color: #494949;
        border-radius: 40px 0 0 40px;
        i {
          font-size: 1.7rem;
          color: #014178;
        }
      }
    }
    i {
      font-size: 1.7rem;
      color: #0066cc;
    }
  }
`

export const Space = styled.span`
  margin-left: 10px;
`

export const StyledIcon = styled(Icon)`
  position: relative;
  right: -0.8rem;
  @media ${sizes.md} {
    right: 0;
  }
`

export const CustomColumn = styled(Column)`
  margin: auto;
`

export const Line = styled.div`
  height: 1px;
  background-color: #0066cc;
  width: 90%;
  margin: auto;
  @media ${sizes.md} {
    margin: 0;
  }
`

export const Circle = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  background-color: #007aff;
  border-radius: 50%;
  margin: 0;
  @media ${sizes.md} {
    display: none;
  }
  i {
    font-size: 2rem;
    color: #f4f8f9;
    &.icon-menu {
      font-size: 1.6rem;
    }
  }
`
