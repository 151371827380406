import React, { useRef, useState } from 'react'
import Layout from '../../components/Layout'
import { Container, Column, Row } from '../../components/Grid'
import Catalog from '../../components/Catalog'
import Filter from '../../components/Filter'
import { Text } from './style'
type SortOptionProps = {
  value: string
  label: string
}
type OptionProps = {
  value: string
  label: string
  icon: string
}

function CatalogPage() {
  const [currentCategory, setCurrentCategory] = useState({
    label: 'Categorías',
    value: '',
    icon: ''
  } as OptionProps)
  const [selectedSort, setSelectedSort] = useState({
    label: 'Ordenar',
    value: 'random'
  } as SortOptionProps)
  const [page, setPage] = useState({ limit: 8, offset: 0 })
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('random')
  const CatalogRef = useRef<null | HTMLDivElement>(null)
  const [checked, setCheck] = useState(false)

  const handleCategories = (category: any) => {
    setPage({ limit: 8, offset: 0 })
    setCurrentCategory(category)
  }

  const handleSort = (sort: any) => {
    setSort(sort.value)
    setSelectedSort(sort)
  }
  return (
    <Layout showBanner={true} showCart={true} currentPage={'catalog'}>
      <Container id="MKTPL_HOME">
        <Row>
          <div style={{ width: '100%' }} ref={CatalogRef}>
            <Column md={12}>
              <Filter
                handleCategories={handleCategories}
                handleSort={handleSort}
                selectedCategory={currentCategory}
                selectedSort={selectedSort}
                search={search}
                setSearch={setSearch}
                setPage={setPage}
                checked={checked}
                setCheck={setCheck}
              ></Filter>
              <Text>
                * Los productos y experiencias están sujetos a cambios en precio
                y stock
              </Text>
              <Catalog
                currentCategory={currentCategory}
                limit={page.limit}
                offset={page.offset}
                pagination={true}
                setPage={setPage}
                search={search}
                sort={sort}
                checked={checked}
              />
            </Column>
          </div>
        </Row>
      </Container>
    </Layout>
  )
}

export default CatalogPage
