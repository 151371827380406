import React from 'react'
import Select from 'react-select'

import { StyledInput, StyledError, StyledTextarea } from './style'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  error?: boolean
  backgroundColor?: string
  borderColor?: string
}

export const Input = ({
  label,
  backgroundColor,
  borderColor,
  required,
  ref,
  as,
  error,
  autoComplete,
  ...props
}: InputProps) => (
  <>
    <label>
      {required && ''} {label}
    </label>
    <StyledInput
      {...props}
      error={error}
      required={required}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    />
  </>
)

type Options = {
  value: string
  label: string
}

type SelectProps = {
  options: [Options]
  style?: any
  required: boolean
  label: string
}

const customStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '2px',
    height: '40px',
    border: 'solid 1px #d9d9d9'
  }),
  container: (provided: any, state: any) => ({
    ...provided
  })
}

export const SelectInput = ({
  required,
  label,
  options,
  style
}: SelectProps) => (
  <>
    <label>
      {required && '*'} {label}
    </label>
    <Select options={options} styles={style ? style : customStyle} />
  </>
)

interface InputTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  error?: boolean
  backgroundColor?: string
  borderColor?: string
}

export const Textarea = ({
  label,
  backgroundColor,
  borderColor,
  required,
  ref,
  as,
  error,
  ...props
}: InputTextareaProps) => (
  <>
    <label>
      {required && ''} {label}
    </label>
    <StyledTextarea
      {...props}
      error={error}
      required={required}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    />
  </>
)

interface ErrorProps {
  children: React.ReactNode
}

export const Error = (props: ErrorProps) => (
  <StyledError>{props.children}</StyledError>
)
