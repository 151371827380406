import React, { useContext } from 'react'
import { Query, QueryResult } from 'react-apollo'
import Product from '../../model/Product'
import { GET_PRODUCTS_QUERY } from './query'
import CartContext from '../../context/CartContext'
import { Row } from '../Grid'

import { StyledColumn, Pe } from './style'

import Loader from '../loader'
import ProductCard from '../ProductCard'
import Pagination from '../Pagination'

interface DataProducts {
  products: {
    nodes: Product[]
    pagination: {
      total: number
      limit: number
      offset: number
    }
  }
}

interface Filter {
  s: String
}

interface Options {
  limit: Number
  sortBy: String
}

interface Variables {
  filters: Filter
  options: Options
}

type OptionProps = {
  value: string
  label: string
  icon: string
}

type Props = {
  currentCategory: OptionProps
  limit: number
  offset?: number
  pagination?: boolean
  setPage?: any
  search?: string
  sort?: string
  checked?: boolean
}

function Catalog({
  currentCategory,
  limit,
  offset,
  pagination,
  setPage,
  search,
  sort,
  checked
}: Props) {
  const { account } = useContext(CartContext)
  const total = account.balances
    ? account.balances.reduce(
        (accumulator: number, item: any, index: number) => {
          return accumulator + (item.total ? item.total : 0)
        },
        0
      )
    : 0
  //const myRef = createRef<HTMLDivElement>()
  /* const scroll = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  } */

  const productsVariables = {
    filters: {
      s: search && search.length > 2 ? search : '',
      category:
        currentCategory && currentCategory.value ? currentCategory.value : '',
      maxPoint: checked ? total : -1
    },
    options: {
      limit: limit,
      offset: offset ? offset : 0,
      sortBy: sort ? sort : '-priority'
    }
  }

  return (
    <Query<DataProducts, Variables>
      query={GET_PRODUCTS_QUERY}
      variables={productsVariables}
    >
      {({ loading, error, data }: QueryResult<DataProducts, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p>Error</p>
        }

        if (!data) {
          return <p>No Data</p>
        }

        if (data.products.pagination.total === 0 && pagination) {
          return <Pe>No hay productos disponibles</Pe>
        }
        const products = data.products.nodes
        const pages =
          data.products.pagination.total % data.products.pagination.limit === 0
            ? Math.floor(
                data.products.pagination.total / data.products.pagination.limit
              )
            : Math.floor(
                data.products.pagination.total /
                  data.products.pagination.limit +
                  1
              )
        const page = offset ? offset / limit + 1 : 1

        return (
          <>
            <div />
            <Row>
              {products &&
                products.map((product: Product, index: number) => {
                  return (
                    <StyledColumn
                      md={6}
                      xs={12}
                      lg={4}
                      xl={3}
                      key={`product-${index}`}
                    >
                      <ProductCard product={product} />
                    </StyledColumn>
                  )
                })}
            </Row>
            <Row>
              <StyledColumn xs={12} md={12}>
                {pagination && (
                  <Pagination
                    pagination={{
                      limit: limit,
                      offset: offset ? offset : 0,
                      page: page,
                      pages: pages
                    }}
                    setPage={setPage}
                  ></Pagination>
                )}
              </StyledColumn>
            </Row>
          </>
        )
      }}
    </Query>
  )
}

export default Catalog
