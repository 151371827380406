import React from 'react'
import { ModalWrapper, ModalDataContainer, Title } from './style'
import RegisterForm from '../RegisterForm'

interface ModalContainerProps {
  modalOpen: boolean
  closeModal?: any
  token: string
}

const FormModal = ({ modalOpen, closeModal, token }: ModalContainerProps) => {
  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={modalOpen}
      onRequestClose={() => closeModal && closeModal(false)}
      shouldCloseOnOverlayClick={false}
    >
      <>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#ffffff'}
          className={'header'}
        >
          <Title>
            <h1>Regístrate</h1>
          </Title>
        </ModalDataContainer>
        <ModalDataContainer
          padding={'20px 30px 20px 30px'}
          backgroundcolor={'#f4f8f9'}
        >
          <RegisterForm token={token} closeModal={closeModal} />
        </ModalDataContainer>
      </>
    </ModalWrapper>
  )
}

export default FormModal
