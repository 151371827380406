import React, { useState, useContext } from 'react'
import { graphql, compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  ModalWrapper,
  ModalBody,
  MainModal,
  MainModalImage,
  MainModalTitle,
  MainModalCopys,
  Checkboxs,
  CheckboxsGroup,
  MainModalBtnAccept
} from './style'
import termycond from '../../assets/images/termycond.svg'
import CartContext from '../../context/CartContext'
import { EDIT_MEMBER_TYC_LOPD } from './query'

const TycLopdModal = ({ mutate }: any) => {
  const { forceShowNotification, onSetAccountData, account } = useContext(CartContext)
  const [terms, setTerms] = useState(account.terms)
  const [lopd, setLopd] = useState(account.lopd)

  const handleChange = (e: any) => {
    if (e.target.name === 'terms') {
      setTerms(!terms)
    }

    if (e.target.name === 'lopd') {
      setLopd(!lopd)
    }
  }

  const validateChecks = () => {
    return (terms && lopd)
  }

  const onRequestClose = () => {
    return false;
  }

  const handleBtnAccept = async (e: any) => {
    if (validateChecks()) {
      try {
        let inputVariables = {
          input: {
            id: account.id,
            terms: true,
            lopd: true
          }
        }
        const response: any = await mutate({ mutation: EDIT_MEMBER_TYC_LOPD, variables: inputVariables })
        if (response.data.editMemberTycLopd) {
          updateAccountAndNotification()
        }
      } catch (err) {
        if (forceShowNotification) {
          forceShowNotification({
            type: 'fail',
            message: 'Error al guardar la información, inténtalo más tarde'
          })
        }
        setTerms(account.terms)
        setLopd(account.lopd)
      }
    } else {
      if (forceShowNotification) {
        forceShowNotification({
          type: 'fail',
          message: 'Acepta los términos y condiciones'
        })
      }
    }
  }

  function updateAccountAndNotification() {
    const updateAccount = { ...account, terms, lopd }
    if(onSetAccountData) {
      onSetAccountData(updateAccount)
    }

    onRequestClose()
  }

  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={(!account.terms || !account.lopd)}
      onRequestClose={() => onRequestClose()}
      shouldCloseOnOverlayClick={false}
    >
      <ModalBody>
        <MainModal>
          <MainModalImage>
            <img src={termycond} alt="read-icon" />
          </MainModalImage>
          <MainModalTitle>Hola {account.firstName},</MainModalTitle>
          <MainModalCopys>
            {!account.terms && (
              <p>Acepta los&nbsp;
                <a
                  href="https://nova-ecuador.s3.amazonaws.com/docs/TERMINOS_Y_CONDICIONES_DEL_PROGRAMA.pdf"
                  className="modal-link"
                  target="_blank"
                  rel="noopener"
                >
                  Términos y Condiciones,
                </a>
              </p>
            )}
            {!account.lopd && (
              <p>Acepta el&nbsp;
                <a
                  href="/docs/aviso_de_la_politica_de_privacidad_pyp_nova_2023.pdf"
                  className="modal-link"
                  target="_blank"
                  rel="noopener"
                >
                  Tratamiento de tus Datos Personales.
                </a>
              </p>
            )}
            <p>Recibirás ofertas personalizadas y beneficios exclusivos.</p>
          </MainModalCopys>
          <Checkboxs>
            {!account.terms && (
              <CheckboxsGroup>
                <div>
                  <input type="checkbox" id="terms" name="terms"
                    className="checkboxs__custom" onChange={handleChange} />
                </div>
                <span>
                  He leído y acepto los&nbsp;
                  <a
                    className="modal-link"
                    href="https://nova-ecuador.s3.amazonaws.com/docs/TERMINOS_Y_CONDICIONES_DEL_PROGRAMA.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    términos y condiciones.
                  </a>
                </span>
              </CheckboxsGroup>
            )}
            {!account.lopd && (
              <CheckboxsGroup>
                <div>
                  <input type="checkbox" id="lopd" name="lopd"
                    className="checkboxs__custom" onChange={handleChange} />
                </div>
                <span>
                  He leído y autorizo el&nbsp;
                  <a
                    className="modal-link"
                    href="/docs/aviso_de_la_politica_de_privacidad_pyp_nova_2023.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    tratamiento de datos personales.
                  </a>
                </span>
              </CheckboxsGroup>
            )}
          </Checkboxs>
          <MainModalBtnAccept
            className={(validateChecks()) ? 'btnAccept--active': ''}
            disabled={!(validateChecks())}
            onClick={handleBtnAccept}
          >Aceptar</MainModalBtnAccept>
        </MainModal>
      </ModalBody>
    </ModalWrapper>
  )
}

const TycLopdModalWithApollo = compose(graphql(EDIT_MEMBER_TYC_LOPD))(
  withRouter(withApollo(TycLopdModal))
)
export default TycLopdModalWithApollo
