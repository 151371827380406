import styled from 'styled-components'
import sizes from '../../styles/media'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: block;
  @media ${sizes.md} {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  @media (max-height: 621px) {
    flex-direction: column;
    align-items: flex-end;
  }
`
export const Header = styled.div`
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 0 35px;
  background-color: #f4f8f9;
  padding: 2rem 2rem;
  transition: 1s ease-in;

  @media ${sizes.md} {
    position: absolute;
    flex-direction: row;
    width: 90%;
    padding: 0.5rem 3rem;
  }
  @media (max-height: 621px) {
    position: relative;
  }
`
export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 85%;
  background-color: #014178;
  height: 16vh;
  display: none;
  @media ${sizes.md} {
    display: block;
  }
  @media (max-height: 621px) {
    display: none;
  }
`

export const Cards = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${sizes.md} {
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    bottom: 4%;
    right: 4%;
    width: 85%;
  }
  @media (max-height: 621px) {
    position: relative;
    margin-bottom: 30px;
  }
`
export const Card = styled.div`
  display: flex;
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  background-color: #0066cc;
  flex-direction: column;
  color: white;
  margin: 10px 0;
  @media ${sizes.md} {
    width: 28%;
    margin: 0;
  }
  p {
    margin: 0 0 20px;
  }
`

export const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Circle = styled.div`
  display: flex;
  width: 58px;
  height: 58px;
  justify-content: center;
  align-items: center;
  background-color: #007aff;
  border-radius: 50%;
  margin: 0 10px 0 0;
  &.profile {
    @media ${sizes.md} {
      display: none;
    }
    margin: 0;
    &.active {
      background-color: #c5c5c5;
    }
  }
  i {
    font-size: 2rem;
    color: #f4f8f9;
  }
`

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: #007aff;
  border-radius: 50%;
  margin-right: 5px;
`

export const Content = styled.div`
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #ffffff;
  margin: auto;
  z-index: 1;
  @media ${sizes.md} {
    width: 50%;
    margin: 0 0 12% 11%;
  }
  @media ${sizes.lg} {
    width: 40%;
  }
  @media (max-height: 621px) and (min-width: 768px) {
    margin: 1% 0 1% 10%;
    display: flex;
    align-self: baseline;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 120px;
  }
`

export const FormWrapper = styled.div`
  position: relative;
  padding: 20px 0 10px;
  display: none;
  transition: opacity 3000ms linear;
  &.opened {
    display: flex;
  }
  flex-direction: column;
  @media ${sizes.md} {
    display: flex;
    flex-direction: row;
  }
  label {
    display: none;
  }
`

export const FlexCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5px 0;

  @media ${sizes.md} {
    margin: 0 10px;
  }
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 0px;
  }
  input {
    margin: 0;
    @media ${sizes.md} {
      width: 200px;
    }
    @media ${sizes.xl} {
      width: 250px;
    }
  }
  p {
    &.forgot-password {
      font-size: 0.8rem;
      letter-spacing: 0.11px;
      color: #0066cc;
      margin: 0.8rem 0 0;
    }
  }
`

export const FlexColButton = styled.div`
  margin: 5px 0;
  @media ${sizes.md} {
    margin: 0 0 0 10px;
  }
  @media ${sizes.lg} {
    margin: 0 0 0 10px;
    width: 200px;
  }
  @media ${sizes.xl} {
    width: 240px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media ${sizes.md} {
    flex-direction: row;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0.6rem 0;
  @media ${sizes.md} {
    width: 48%;
    margin: 1% 2% 1% 0;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  img {
    width: 75%;
    height: auto;
    margin: 0 auto 2rem 0;
  }
  h1 {
    width: 100%;
    font-size: 20px;
    margin: 0;
    border-top: 1px solid #f1bf06;
    padding: 1rem 0 0;
  }

  @media ${sizes.md} {
    flex-direction: row;

    img {
      width: 25%;
      height: auto;
      margin: 0 20px 0.8rem 0;
    }
    h1 {
      padding: 0.1rem 0 0.2rem 20px;
      border-top: none;
      border-left: 1px solid #f1bf06;
    }
  }
`
