import React, { useContext, useState, useEffect } from 'react'
import gql from 'graphql-tag'
import {
  Query,
  QueryResult,
  graphql,
  ApolloConsumer,
  compose,
  withApollo
} from 'react-apollo'
import Select from 'react-select'
import CreatableSelect from 'react-select/lib/Creatable'
import { withRouter } from 'react-router-dom'
import { Button } from '../../components/Buttons'

import Checkout from '../../components/Checkout'
import Loader from '../../components/loader'
import * as Grid from '../../components/Grid'
import { Input, Error } from '../../components/FormElements'
import { Spacer } from '../../components/Global/style'
import Address from '../../model/Address'
import Customer from '../../model/Customer'
import { Link } from 'react-router-dom'
//import formatNumber from '../../helpers/formatNumber'
import {
  SAVE_ADDRESS_MUTATION,
  GENERATE_ORDER_MUTATION,
  GET_ORDER_STATUS
} from './query'

import {
  ContainerForm,
  FlexCol,
  H3,
  //TextAreaElements,
  //Element,
  //CountContainer,
  StyledNewChoice,
  StyledAction,
  StyledNewData,
  StyledFlexCol,
  AmountsContainer,
  //TotalContainer,
  ButtonContainer,
  //SpanPayment,
  ProductListContainer,
  TitleContainer,
  MobileIconContainer,
  IconContainer,
  IconWrapper,
  Title,
  StyledRow,
  Disclaimer
} from '../Shipping/style'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
//import LoaderContext from '../../context/LoaderContext'

import { customStyle, errorStyle } from '../../helpers/selectProps'
import { Validate } from '../../helpers/inputValidations'

const GET_STATES = gql`
  query getStates($filters: StateFilters, $options: StateOptions) {
    states(filters: $filters, options: $options) {
      nodes {
        id
        name
        postalcodeUrbano
      }
    }
  }
`

const GET_CITIES = gql`
  query getCitiesByStateId(
    $stateId: String!
    $filters: CityFilters
    $options: CityOptions
  ) {
    citiesByState(stateId: $stateId, filters: $filters, options: $options) {
      nodes {
        id
        name
        postalcodeUrbano
      }
    }
  }
`

const GET_ZONES = gql`
  query getZonesByCityId(
    $cityId: ID!
    $filters: ZoneFilters
    $options: ZoneOptions
  ) {
    zonesByCity(cityId: $cityId, filters: $filters, options: $options) {
      nodes {
        id
        name
        postalcodeUrbano
      }
    }
  }
`

type Locations = {
  id: string
  name: string
  postalcodeUrbano?: string
  value?: string
  label?: string
}

let timer: number = 0
function Shipping({ history, mutate, client }: any) {
  const itemsCart = JSON.parse(window.localStorage.getItem('items') || '[]')
  const {
    forceShowNotification,
    loaded,
    account,
    onDelAllItems,
    onSetLoaded,
    onSetAccountData
  } = useContext(CartContext)

  //const { setLoading } = useContext(LoaderContext)

  /* let total: number = 0
  const calculatePayment = () => {
    items.forEach((item) => {
      total += item.price * item.quantity
    })
  }

  calculatePayment() */

  //Loading initial values
  let citiesSelected = JSON.parse(window.localStorage.getItem('cities') || '[]')
  let zonesSelected = JSON.parse(window.localStorage.getItem('zones') || '[]')
  const copaymentCurrency = JSON.parse(
    window.localStorage.getItem('currency') || '[]'
  )

  const [citiesState, setCitiesState] = useState(
    citiesSelected ? citiesSelected : [{ value: '', label: '' }]
  )
  const [zonesState, setZonesState] = useState(
    zonesSelected ? zonesSelected : [{ value: '', label: '' }]
  )

  const { primary, secondary } = useContext(ThemeContext)

  // Get initial values from localstorage (Customer Type)
  const {
    firstName: initialFirstNameCustomer,
    lastName: initialLastNameCustomer,
    email: initialEmailCustomer,
    identificationNumber: initialIdentificationCustomer
  }: Customer = JSON.parse(window.localStorage.getItem('customer') || '{}')

  const {
    province: initialState,
    provinceName,
    city: initialCity,
    cityName,
    street1: initialStreet1,
    street2: initialStreet2,
    number: initialNumber,
    zone: initialZone,
    zoneName,
    reference: initialReference,
    firstName: initialFirstName,
    lastName: initialLastName,
    phone: initialPhone,
    email: initialEmail,
    mobile: initialMobile
  }: Address = JSON.parse(window.localStorage.getItem('address') || '{}')
  // Province, City, Zone selected Hooks
  const [provinceState, setProvinceState] = useState({
    value: initialState ? initialState : '',
    label: initialState ? provinceName : 'Seleccione una provincia',
    error: false,
    style: customStyle
  })
  const [cityState, setCityState] = useState({
    value: initialCity ? initialCity : '',
    label: initialCity ? cityName : 'Seleccione una ciudad',
    error: false,
    style: customStyle
  })
  const [zoneState, setZoneState] = useState({
    value: initialZone ? initialZone : '',
    label: initialZone ? zoneName : 'Ingrese un sector',
    error: false,
    style: customStyle
  })
  // define hooks for every input
  const [identificationState, setIdentification] = useState({
    error: false,
    message: '',
    value: initialIdentificationCustomer ? initialIdentificationCustomer : ''
  })
  const [street1State, setStreet1] = useState({
    error: false,
    message: '',
    value: initialStreet1 ? initialStreet1 : ''
  })
  const [street2State, setStreet2] = useState({
    error: false,
    message: '',
    value: initialStreet2 ? initialStreet2 : ''
  })
  const [numberState, setNumber] = useState({
    error: false,
    message: '',
    value: initialNumber ? initialNumber : ''
  })

  const [referenceState, setReference] = useState({
    error: false,
    message: '',
    value: initialReference ? initialReference : ''
  })
  const [firstNameState, setFirstName] = useState({
    error: false,
    message: '',
    value: initialFirstName
      ? initialFirstName
      : initialFirstNameCustomer
      ? initialFirstNameCustomer
      : ''
  })
  const [lastNameState, setLastName] = useState({
    error: false,
    message: '',
    value: initialLastName
      ? initialLastName
      : initialLastNameCustomer
      ? initialLastNameCustomer
      : ''
  })
  const [phoneState, setPhone] = useState({
    error: false,
    message: '',
    value: initialPhone ? initialPhone : ''
  })
  const [mobileState, setMobile] = useState({
    error: false,
    message: '',
    value: initialMobile ? initialMobile : ''
  })
  const [emailState, setEmail] = useState({
    error: false,
    message: '',
    value: initialEmail
      ? initialEmail
      : initialEmailCustomer
      ? initialEmailCustomer
      : ''
  })

  // onchange function on state select
  const selectState = async (optionSelected: any) => {
    setProvinceState({
      value: optionSelected.value,
      label: optionSelected.label,
      error: false,
      style: customStyle
    })

    await setCityState({
      value: '',
      label: 'Seleccione una ciudad',
      error: false,
      style: customStyle
    })
    await setZoneState({
      value: '',
      label: 'Ingrese un sector',
      error: false,
      style: customStyle
    })
    persistData(optionSelected.value, 'province')
    persistData(optionSelected.label, 'provinceName')
  }
  // onchange function on city select
  const selectCity = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != 'clear') {
      setCityState({
        value: optionSelected.value,
        label: optionSelected.label,
        error: false,
        style: customStyle
      })
      setZoneState({
        error: false,
        label: 'Ingrese un sector',
        value: '',
        style: customStyle
      })
      persistData(optionSelected.value, 'city')
      persistData(optionSelected.label, 'cityName')
    }
  }
  // onchange function on zone select
  const selectZone = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != 'clear') {
      setZoneState({
        ...zoneState,
        error: false,
        label: optionSelected.label,
        value: optionSelected.value,
        style: customStyle
      })
      persistData(optionSelected.value, 'zone')
      persistData(optionSelected.label, 'zoneName')
    } else {
      setZoneState({
        error: true,
        label: '',
        value: '',
        style: errorStyle
      })
    }
  }

  const validateSelects = () => {
    if (provinceState.value.length <= 0) {
      setProvinceState({
        ...provinceState,
        error: true,
        style: errorStyle
      })
    }
    if (cityState.value.length <= 0) {
      setCityState({
        ...cityState,
        error: true,
        style: errorStyle
      })
    }
    if (zoneState.value.length <= 0) {
      setZoneState({
        ...zoneState,
        error: true,
        style: errorStyle
      })
    }
  }

  // validate every input and select data
  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value)
    switch (id) {
      case 'identification':
        dataValidated = Validate(value, 'CI', 10)
        setIdentification(dataValidated)
        break
      case 'street1':
        setStreet1(dataValidated)
        break
      case 'street2':
        setStreet2(dataValidated)
        break
      case 'number':
        setNumber(dataValidated)
        break
      case 'reference':
        dataValidated = Validate(value, '', 50)
        setReference(dataValidated)
        break
      case 'firstName':
        dataValidated = Validate(value, 'characters')
        setFirstName(dataValidated)
        break
      case 'lastName':
        dataValidated = Validate(value, 'characters')
        setLastName(dataValidated)
        break
      case 'phone':
        dataValidated = Validate(value, 'numeric', 10)
        setPhone(dataValidated)
        break
      case 'mobile':
        dataValidated = Validate(value, 'numeric', 10)
        setMobile(dataValidated)
        break
      case 'email':
        dataValidated = Validate(value, 'email', 60)
        setEmail(dataValidated)
        break
    }
    if (dataValidated.error) return true
    else return false
  }

  const validateAllInputs = () => {
    validateSelects()
    validateData(identificationState.value, 'identification')
    validateData(street1State.value, 'street1')
    validateData(street2State.value, 'street2')
    validateData(numberState.value, 'number')
    //validateData(referenceState.value, 'reference')
    validateData(firstNameState.value, 'firstName')
    validateData(lastNameState.value, 'lastName')
    validateData(phoneState.value, 'phone')
    validateData(mobileState.value, 'mobile')
    validateData(emailState.value, 'email')
    return true
  }

  // Perssit data in local storage
  const persistData = (value: string, id: string) => {
    if (validateData(value, id) == false) {
      const address = JSON.parse(window.localStorage.getItem('address') || '{}')
      address[id] = value
      window.localStorage.setItem('address', JSON.stringify(address))
    }
  }
  const updatePoints = () => {
    const currentBalances =
      account.balances &&
      account.balances.map((balance: any) => {
        copaymentCurrency.forEach((element: any) => {
          if (balance.currency.id === element.code) {
            balance.total = balance.total - element.amount
          }
        })
        return balance
      })
    let currentAccount = { ...account }
    currentAccount.balances = currentBalances
    onSetAccountData && onSetAccountData(currentAccount)
  }
  const getOrderStatus = async (order_id: string) => {
    await client
      .query({
        query: GET_ORDER_STATUS,
        variables: {
          orderId: order_id
        },
        fetchPolicy: 'network-only'
      })
      .then((response: any) => {
        if (
          response.data.OrderStatus.status === 'approved' ||
          response.data.OrderStatus.status === 'paid'
        ) {
          const isloading = window.localStorage.getItem('loading') || ''
          window.clearInterval(timer)
          if (isloading !== '') {
            let notificated =
              forceShowNotification &&
              forceShowNotification({
                type: 'info',
                message: 'Tú pedido fue enviado.'
              })
            if (notificated) {
              updatePoints()
              window.localStorage.removeItem('loading')
              onDelAllItems && onDelAllItems()
              history.push('/home')
            }
          }
        }
        if (response.data.OrderStatus.status == 'rejected') {
          const isloading = window.localStorage.getItem('loading') || ''
          window.clearInterval(timer)
          if (isloading !== '') {
            let notificated =
              forceShowNotification &&
              forceShowNotification({
                type: 'fail',
                message: 'Tú pedido fue rechazado.'
              })
            if (notificated) {
              window.localStorage.removeItem('loading')
              onDelAllItems && onDelAllItems()
              history.push('/home')
            }
          }
        }
      })
      .catch(() => {
        const isloading = window.localStorage.getItem('loading') || ''
        window.clearInterval(timer)
        if (isloading !== '') {
          onSetLoaded && onSetLoaded(true)
          window.localStorage.removeItem('loading')
          //history.go(0)
          forceShowNotification &&
            forceShowNotification({
              type: 'fail',
              message: 'Algo salió mal, Inténtalo más tarde'
            })
        }
      })
  }

  const handleClickOnNext = () => {
    onSetLoaded && onSetLoaded(false)
    window.localStorage.setItem('loading', 'loading')

    const items: any[] = []
    itemsCart.forEach((item: any) => {
      for (let i = 0; i < item.quantity; i++) {
        items.push({ variationId: item.id })
      }
    })

    const total = copaymentCurrency.reduce((accumulator: number, item: any) => {
      return accumulator + (item.amount ? item.amount : 0)
    }, 0)
    const addresses = [
      {
        addressName: street1State.value,
        stateId: provinceState.value,
        cityId: cityState.value,
        zoneId: zoneState.value === zoneState.label ? '' : zoneState.value,
        street1: street1State.value,
        street2: street2State.value,
        number: numberState.value,
        email: emailState.value,
        customerName: `${firstNameState.value} ${lastNameState.value}`,
        phone: phoneState.value,
        reference:
          zoneState.value === zoneState.label
            ? zoneState.value
            : `${street1State.value}, ${numberState.value} ${street2State.value}`
      }
    ]
    const address = {
      memberId: account.id,
      addresses: addresses
    }

    mutate({
      mutation: SAVE_ADDRESS_MUTATION,
      variables: {
        input: address
      }
    })
      .then(async (addressResult: any) => {
        if (
          addressResult.data &&
          addressResult.data.editMemberAddress &&
          addressResult.data.editMemberAddress.nodes &&
          addressResult.data.editMemberAddress.nodes.length > 0
        ) {
          const order = {
            addressCode: addressResult.data.editMemberAddress.nodes[0].id,
            customer: {
              firstName: firstNameState.value,
              lastName: lastNameState.value,
              identificationNumber: identificationState.value,
              identificationType: 'CI',
              mobile: mobileState.value,
              email: emailState.value,
              address: `${street1State.value}, ${numberState.value} ${street2State.value}`,
              businessName: ''
            },
            lineItems: items,
            programCode: account.id,
            copaymentCurrency: copaymentCurrency,
            points: total
          }

          mutate({
            mutation: GENERATE_ORDER_MUTATION,
            variables: {
              input: order
            }
          })
            .then(async (orderResult: any) => {
              timer = window.setInterval(
                () => getOrderStatus(orderResult.data.generateOrder.id),
                2000
              )
            })
            .catch((errors: any) => {
              onSetLoaded && onSetLoaded(true)
              window.localStorage.removeItem('loading')
              if (
                errors &&
                errors.message &&
                errors.message === 'GraphQL error: Out of Stock'
              ) {
                forceShowNotification &&
                  forceShowNotification({
                    type: 'fail',
                    message: 'Uno o varios productos se han quedado sin stock'
                  })
                history.push('/home')
              } else {
                forceShowNotification &&
                  forceShowNotification({
                    type: 'fail',
                    message: 'Algo salió mal, Inténtalo más tarde'
                  })
              }
            })
        }
      })
      .catch(() => {
        onSetLoaded && onSetLoaded(true)
        window.localStorage.removeItem('loading')
        //history.go(0)
        forceShowNotification &&
          forceShowNotification({
            type: 'fail',
            message: 'Algo salió mal, Inténtalo más tarde'
          })
      })
  }
  // Filters an Options to
  const filters = { s: '' }
  const options = { limit: 100 }
  // Onclick event in next button
  const goToBilling = async () => {
    validateAllInputs()
    if (
      !provinceState.value ||
      !cityState.value ||
      !street1State.value ||
      !street2State.value ||
      !numberState.value ||
      !zoneState.value ||
      //!referenceState.value ||
      !firstNameState.value ||
      !lastNameState.value ||
      !phoneState.value ||
      !emailState.value ||
      !mobileState.value ||
      !identificationState.value ||
      provinceState.error ||
      cityState.error ||
      street1State.error ||
      street2State.error ||
      numberState.error ||
      zoneState.error ||
      //referenceState.error ||
      firstNameState.error ||
      lastNameState.error ||
      phoneState.error ||
      emailState.error ||
      mobileState.error ||
      identificationState.error
    ) {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message:
            'Para avanzar, verifique que todos los campos sean los correctos.'
        })
      return
    }

    const address: Address = {
      city: cityState.value,
      cityName: cityState.label,
      number: numberState.value,
      reference: referenceState.value,
      province: provinceState.value,
      provinceName: provinceState.label,
      street1: street1State.value,
      street2: street2State.value,
      zone: zoneState.value,
      zoneName: zoneState.label,
      firstName: firstNameState.value,
      lastName: lastNameState.value,
      email: emailState.value,
      phone: phoneState.value,
      mobile: mobileState.value
    }

    window.localStorage.setItem('address', JSON.stringify(address))
    await handleClickOnNext()
    //history.push('/billing')
  }
  const validateCurrency = () => {
    const validatedCurrencies = JSON.parse(
      window.localStorage.getItem('currency') || '[]'
    )
    const validatedItems = JSON.parse(
      window.localStorage.getItem('items') || '[]'
    )
    const total = validatedCurrencies.reduce(
      (accumulator: number, item: any) => {
        return accumulator + (item.amount ? item.amount : 0)
      },
      0
    )
    const totalCost = validatedItems.reduce(
      (accumulator: number, item: any) => {
        return accumulator + item.pointCost * item.quantity
      },
      0
    )
    if (total === 0 || total !== totalCost) {
      history.push('./billing')
    }
  }
  useEffect(() => {
    const isloading = window.localStorage.getItem('loading') || ''
    onSetLoaded && onSetLoaded(isloading === '' ? true : false)
    validateCurrency()
  }, [loaded])

  return loaded ? (
    <Query
      variables={{ filters, options }}
      query={GET_STATES}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<any>) => {
        if (loading) {
          return (
            <Checkout>
              <Grid.Container>
                <Loader />
              </Grid.Container>
            </Checkout>
          )
        }

        if (error) {
          return <div>error</div>
        }

        if (!data) {
          return <div>No hay data</div>
        }

        const { nodes } = data.states
        const states = nodes.map((state: Locations) => {
          state.value = state.id
          state.label = state.name
          return state
        })
        // setProvincesState(states);
        return (
          <Checkout>
            <ProductListContainer>
              <TitleContainer>
                <MobileIconContainer className={'back'}>
                  <Link to="/billing">
                    <IconWrapper>
                      <i className={'flex-icon icon-atras'}></i>
                    </IconWrapper>
                    <p>Volver</p>
                  </Link>
                </MobileIconContainer>
                <MobileIconContainer>
                  <Link to="/catalog">
                    <IconWrapper>
                      <i className={'flex-icon icon-catalogo'}></i>
                    </IconWrapper>
                    <p>Catálogo</p>
                  </Link>
                </MobileIconContainer>
                <Grid.Column md={2} className="desktop-wrapper">
                  <Link to="/billing">
                    <IconContainer className={'back'}>
                      <IconWrapper>
                        <i className={'flex-icon icon-atras'}></i>
                      </IconWrapper>
                      <p>Volver</p>
                    </IconContainer>
                  </Link>
                </Grid.Column>
                <Grid.Column md={8}>
                  <Title>
                    <h2>
                      Carrito <br />
                      de Compras
                    </h2>
                  </Title>
                </Grid.Column>
                <Grid.Column md={2} className="desktop-wrapper">
                  <Link to="/catalog">
                    <IconContainer>
                      <IconWrapper>
                        <i className={'flex-icon icon-catalogo'}></i>
                      </IconWrapper>
                      <p>Ir al catálogo de premios</p>
                    </IconContainer>
                  </Link>
                </Grid.Column>
              </TitleContainer>
              <Grid.Row>
                <Grid.Column md={12}>
                  <div className="animated fadeInLeft">
                    <ContainerForm backgroundcolor={secondary}>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'firstName')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'firstName')
                              }}
                              id="firstName"
                              type="text"
                              label="Nombres *"
                              borderColor="#2684FF"
                              required
                              error={firstNameState.error}
                              value={firstNameState.value}
                            />
                            {firstNameState.error ? (
                              <Error>{firstNameState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'lastName')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'lastName')
                              }}
                              id="lastName"
                              type="text"
                              label="Apellidos *"
                              borderColor="#2684FF"
                              required
                              error={lastNameState.error}
                              value={lastNameState.value}
                            />
                            {lastNameState.error ? (
                              <Error>{lastNameState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'identification')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'identification')
                              }}
                              id="identification"
                              type="text"
                              label="Cédula *"
                              borderColor="#2684FF"
                              required
                              error={identificationState.error}
                              value={identificationState.value}
                            />
                            {identificationState.error ? (
                              <Error>{identificationState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'email')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'email')
                              }}
                              id="email"
                              type="email"
                              label="Correo Electrónico *"
                              borderColor="#2684FF"
                              maxLength={60}
                              required
                              error={emailState.error}
                              value={emailState.value}
                            />
                            {emailState.error ? (
                              <Error>{emailState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <H3>DIRECCIÓN DE ENVÍO</H3>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol id="flexcol">
                            <ApolloConsumer>
                              {(client) => (
                                <>
                                  <span>Provincia *</span>
                                  <Select
                                    id="province"
                                    options={states}
                                    onChange={async (optionSelected: any) => {
                                      const stateId = optionSelected.value
                                      const { data } = await client.query({
                                        query: GET_CITIES,
                                        variables: { stateId, filters, options }
                                      })
                                      const cities = data.citiesByState.nodes.map(
                                        (city: Locations) => {
                                          return {
                                            value: city.id,
                                            label: city.name
                                          }
                                        }
                                      )
                                      window.localStorage.setItem(
                                        'cities',
                                        JSON.stringify(cities)
                                      )
                                      setCitiesState(cities)
                                      selectState(optionSelected)
                                    }}
                                    value={provinceState}
                                    styles={provinceState.style}
                                    noOptionsMessage={() =>
                                      'No existe la provincia.'
                                    }
                                    autoFocus={false}
                                  />
                                  <>
                                    {provinceState.error ? (
                                      <Error>
                                        Campo obligatorio, no puede estar vacio
                                      </Error>
                                    ) : (
                                      <Spacer />
                                    )}
                                  </>
                                </>
                              )}
                            </ApolloConsumer>
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <ApolloConsumer>
                              {(client) => (
                                <>
                                  <span>Ciudad *</span>
                                  <Select
                                    id="city"
                                    options={citiesState}
                                    onChange={async (
                                      optionSelected: any,
                                      actionMeta: any
                                    ) => {
                                      const cityId = optionSelected.value
                                      const { data } = await client.query({
                                        query: GET_ZONES,
                                        variables: { cityId, filters, options }
                                      })
                                      const zones = data.zonesByCity.nodes.map(
                                        (zone: Locations) => {
                                          return {
                                            value: zone.id,
                                            label: zone.name
                                          }
                                        }
                                      )
                                      window.localStorage.setItem(
                                        'zones',
                                        JSON.stringify(zones)
                                      )
                                      selectCity(optionSelected, actionMeta)
                                      setZonesState(zones)
                                    }}
                                    value={cityState}
                                    styles={cityState.style}
                                    noOptionsMessage={() =>
                                      'No existe la ciudad.'
                                    }
                                    autoFocus={false}
                                  />
                                  <>
                                    {cityState.error ? (
                                      <Error>
                                        Campo obligatorio, no puede estar vacio
                                      </Error>
                                    ) : (
                                      <Spacer />
                                    )}
                                  </>
                                </>
                              )}
                            </ApolloConsumer>
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <StyledFlexCol empty={zonesState.length === 1}>
                            <span>Sector *</span>
                            <CreatableSelect
                              isClearable
                              styles={zoneState.style}
                              options={zonesState}
                              onChange={selectZone}
                              value={zoneState}
                              noOptionsMessage={() => 'Digite un sector.'}
                              formatCreateLabel={(value: string) => (
                                <StyledNewChoice>
                                  <StyledNewData>{value}</StyledNewData>{' '}
                                  <StyledAction> + Agregar</StyledAction>
                                </StyledNewChoice>
                              )}
                              autoFocus={false}
                            />
                            {zoneState.error ? (
                              <Error>
                                {'Campo obligatorio, no puede estar vacio'}
                              </Error>
                            ) : (
                              <Spacer />
                            )}
                          </StyledFlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'street1')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'street1')
                              }}
                              id="street1"
                              type="text"
                              label="Calle *"
                              borderColor="#2684FF"
                              required
                              value={street1State.value}
                              error={street1State.error}
                            />
                            {street1State.error ? (
                              <Error>{street1State.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'number')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'number')
                              }}
                              id="number"
                              type="text"
                              label="Número *"
                              borderColor="#2684FF"
                              required
                              error={numberState.error}
                              value={numberState.value}
                            />
                            {numberState.error ? (
                              <Error>{numberState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'street2')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'street2')
                              }}
                              id="street2"
                              type="text"
                              label="Calle Secundaria *"
                              borderColor="#2684FF"
                              required
                              error={street2State.error}
                              value={street2State.value}
                            />
                            {street2State.error ? (
                              <Error>{street2State.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'mobile')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'mobile')
                              }}
                              id="mobile"
                              type="text"
                              label="Celular *"
                              borderColor="#2684FF"
                              maxLength={60}
                              required
                              error={mobileState.error}
                              value={mobileState.value}
                            />
                            {mobileState.error ? (
                              <Error>{mobileState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                        <Grid.Column lg={6} md={6} sm={12}>
                          <FlexCol>
                            <Input
                              onBlur={(e: any) => {
                                persistData(e.target.value, 'phone')
                              }}
                              onChange={(e: any) => {
                                validateData(e.target.value, 'phone')
                              }}
                              id="phone"
                              type="text"
                              label="Teléfono Convencional *"
                              borderColor="#2684FF"
                              required
                              error={phoneState.error}
                              value={phoneState.value}
                            />
                            {phoneState.error ? (
                              <Error>{phoneState.message}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <FlexCol>
                            <Disclaimer>
                              <p>* Campos Obligatorios</p>
                              <p>
                                * Si tu canje es de productos dentro de los
                                próximos 15 días hábiles se realizará la
                                entrega. Si es una experiencia de viaje nacional
                                o internacional nos contactaremos contigo.
                              </p>
                              <p>
                                * Si tu canje es de kms Latampass envíanos tu
                                código latam al correo
                                servicioalcliente@pyp.com.ec
                              </p>
                            </Disclaimer>
                          </FlexCol>
                        </Grid.Column>
                      </Grid.Row>
                    </ContainerForm>
                  </div>
                </Grid.Column>
              </Grid.Row>

              <StyledRow>
                <AmountsContainer>
                  {/* <TotalContainer>
                    <SpanPayment>{formatNumber(total)} Puntos</SpanPayment>
                    <SpanPayment className="total">TOTAL</SpanPayment>
                  </TotalContainer> */}
                  <ButtonContainer>
                    <Button
                      backgroundColor={'#ffd000'}
                      color={primary}
                      onClick={() => {
                        goToBilling()
                      }}
                      id={'MKTPL_SHIP_NEXT'}
                    >
                      Confirmar Canje
                    </Button>
                  </ButtonContainer>
                </AmountsContainer>
              </StyledRow>
            </ProductListContainer>
          </Checkout>
        )
      }}
    </Query>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
    </Checkout>
  )
}

const AddTracklWithMutation = compose(graphql(SAVE_ADDRESS_MUTATION))(
  withRouter(withApollo(Shipping))
)
export default AddTracklWithMutation
