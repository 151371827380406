import gql from 'graphql-tag'

const GET_MEMBER_BY_TOKEN = gql`
  query MemberByToken($token: String!) {
    memberByToken(token: $token) {
      id
    }
  }
`
const REGISTER_MEMBER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input)
  }
`

export { GET_MEMBER_BY_TOKEN, REGISTER_MEMBER }
