import { createContext } from 'react'

export type Theme = {
  primary: string
  secondary: string
  third?: string
  btnPrimary?: string
  btnSecondary?: string
}

const ThemeContext = createContext<Theme>({
  primary: '#014178',
  secondary: '#f4f8f9',
  third: '#ffffff',
  btnPrimary: '#0066cc',
  btnSecondary: '#f1bf06'
})

export default ThemeContext
